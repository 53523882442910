import { Button } from "components/common/Button";
import { ConfirmModal } from "components/common/ConfirmModal";
import { IconButton } from "components/common/IconButton";
import { Loader } from "components/common/Loader";
import { Modal } from "components/common/Modal";
import { PageContainer } from "components/common/PageContainer";
import { PageHeader } from "components/common/PageHeader";
import { WidgetFrame } from "components/common/WidgetFrame";
import { DataTable } from "components/common/table/DataTable";
import { useSortConfig } from "components/common/table/DataTable/hooks";
import { Column, ColumnActionComponent } from "components/common/table/types";
import { DeviceForm, baseInitialValues, FormSubmitData as DeviceFormData } from "components/form/forms/DeviceForm";
import { AddIcon, DetailIcon, DeleteIcon, MachineIcon } from "components/icons/Icons";
import { LoaderOverlap } from "components/styled/LoaderOverlap";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { routes } from "routing/routes";
import { useCurrentUserRole } from "store";
import {
    BasicDevicePayload,
    useAddDeviceMutation,
    useDeleteDeviceMutation,
    useGetDeviceTypesQuery,
    useGetDevicesQuery,
    DeviceTypePayload,
} from "store/services/device-service";
import { ADMIN_ROLES } from "utils/auth-utils";
import { useInputValue } from "utils/hooks/useInput";

export const MachinesPage: React.VFC = () => {
    const navigate = useNavigate();
    const role = useCurrentUserRole();

    // Open modal for machine creation and prefill deviceId after provisioning request gets accepted and redirects here
    const [searchParams, setSearchParams] = useSearchParams();
    const deviceId = searchParams.get("createDeviceId");
    const formData = deviceId
        ? { ...baseInitialValues, id: deviceId, type: { id: NaN, name: "", active: false } }
        : null;

    const [showForm, setShowForm] = useState(deviceId ? true : false);
    const [formError, setFormError] = useState<string | undefined>();
    const [deleteId, setDeleteId] = useState<string | null>(null);

    const [createDevice, { isLoading: isCreateLoading }] = useAddDeviceMutation();
    const [deleteDevice, { isLoading: isDeleteLoading }] = useDeleteDeviceMutation();

    const { data: devices, isLoading: isDeviceQueryLoading, isFetching: isDeviceQueryFetching } = useGetDevicesQuery();
    const { data: deviceTypes, isLoading: isDeviceTypesQueryLoading } = useGetDeviceTypesQuery();

    const isLoading = isDeviceQueryLoading || isDeviceQueryFetching || isDeleteLoading;

    const toggleModalForm = (show: boolean) => {
        if (!show) {
            // Remove deviceId when you close the form
            setSearchParams({});
        }
        setFormError(undefined);
        setShowForm(show);
    };

    const handleCreate = async (values: DeviceFormData, actions: { setSubmitting: (value: boolean) => void }) => {
        try {
            await createDevice(values).unwrap();
            toggleModalForm(false);
        } catch (err: any) {
            setFormError(err?.data?.error);
        }
        actions.setSubmitting(false);
    };

    const handleDelete = async (id: string) => {
        deleteDevice(id);
        setDeleteId(null);
    };

    const handleOpenDetailPage = (machine: BasicDevicePayload) => {
        navigate(`${routes.machineDetail.getPath({ machineId: machine.id.toString() })}`);
    };

    const dataTableSearchInput = useInputValue();
    const dataTableSortConfig = useSortConfig<BasicDevicePayload>({ dataIndex: "id", descending: false });

    const OpenDetailPageButton: ColumnActionComponent<BasicDevicePayload> = ({ row }) => (
        <IconButton onClick={() => handleOpenDetailPage(row)} title={"Details"} Icon={DetailIcon} />
    );
    const OpenDeleteModalButton: ColumnActionComponent<BasicDevicePayload> = ({ row }) => (
        <IconButton onClick={() => setDeleteId(row.id)} title={"Delete"} Icon={DeleteIcon} />
    );

    const columns: Column<BasicDevicePayload>[] = [
        { type: "data", dataIndex: "id", dataType: "string", label: "Device Id", sortable: true },
        {
            type: "data",
            dataIndex: "type",
            dataType: "string",
            valueTransform: (deviceType: DeviceTypePayload) => deviceType?.name,
            sortable: true,
        },
        ...(role && ADMIN_ROLES.includes(role)
            ? ([
                  {
                      type: "actions",
                      width: 400,
                      components: [OpenDetailPageButton, OpenDeleteModalButton],
                  },
              ] as Column<BasicDevicePayload>[])
            : []),
    ];

    return (
        <PageContainer>
            <PageHeader title={"Machine Administration"}>
                <Button onClick={() => toggleModalForm(true)} Icon={AddIcon}>
                    Add Machine
                </Button>
            </PageHeader>
            <WidgetFrame isLoading={isLoading} height={"100%"} Icon={MachineIcon} title={"Machines"}>
                <DataTable<BasicDevicePayload>
                    data={devices}
                    columns={columns}
                    configuration={{ disableDownload: true }}
                    sortConfig={dataTableSortConfig}
                    searchInput={dataTableSearchInput}
                />
            </WidgetFrame>

            <Modal isOpen={showForm} setOpen={toggleModalForm}>
                <DeviceForm
                    isCreate={true}
                    initialData={formData}
                    handleSubmit={handleCreate}
                    error={formError}
                    deviceTypes={deviceTypes}
                />
                {(isCreateLoading || isDeviceTypesQueryLoading) && (
                    <LoaderOverlap>
                        <Loader size={"4rem"} />
                    </LoaderOverlap>
                )}
            </Modal>
            {deleteId && (
                <ConfirmModal
                    open={true}
                    setOpen={() => setDeleteId(null)}
                    onConfirm={() => handleDelete(deleteId)}
                    title={"Delete machine?"}
                    description={`Machine ${deleteId} will be deleted.`}
                    confirmText={"Yes, delete"}
                />
            )}
        </PageContainer>
    );
};
