import { ConfirmModal } from "components/common/ConfirmModal";
import { useNavigate } from "react-router";
import { routes } from "routing/routes";
import { useApproveProvisioningOperationMutation, useCancelProvisioningOperationMutation } from "store";
import { QueryError } from "store/services/types";

interface Props {
    deviceId: string | null;
    requestIdForApproval: string | null;
    requestIdForRejection: string | null;
    setRequestIdForApproval: (arg0: string | null) => void;
    setRequestIdForRejection: (arg0: string | null) => void;
}

export const PendingProvisioningRequestModals = (props: Props) => {
    const { deviceId, requestIdForApproval, requestIdForRejection, setRequestIdForApproval, setRequestIdForRejection } =
        props;

    const navigate = useNavigate();

    const [
        approveProvisioningRequest,
        { isLoading: isLoadingApproveProvisioningRequest, error: errorApproveProvisioningRequest },
    ] = useApproveProvisioningOperationMutation();
    const approvalError = errorApproveProvisioningRequest as QueryError;

    const [
        cancelProvisioningRequest,
        { isLoading: isLoadingCancelProvisioningRequest, error: errorCancelProvisioningRequest },
    ] = useCancelProvisioningOperationMutation();
    const rejectionError = errorCancelProvisioningRequest as QueryError;

    const handleApproval = async (requestId: string) => {
        await approveProvisioningRequest(requestId);
        if (!errorApproveProvisioningRequest) {
            setRequestIdForApproval(null);
            navigate(`${routes.machines.path}?createDeviceId=${deviceId}`);
        }
    };

    const handleRejection = async (requestId: string) => {
        await cancelProvisioningRequest(requestId);
        if (!errorCancelProvisioningRequest) {
            setRequestIdForRejection(null);
        }
    };

    return (
        <>
            {requestIdForApproval && (
                <ConfirmModal
                    loading={isLoadingApproveProvisioningRequest}
                    open={true}
                    setOpen={() => setRequestIdForApproval(null)}
                    onConfirm={() => handleApproval(requestIdForApproval)}
                    title={"Approve provisioning request?"}
                    description={`The provisioning request of device with id ${deviceId} will be approved.`}
                    confirmText={"Yes, approve"}
                    error={approvalError && `Error ${approvalError?.data.statusCode}: ${approvalError?.data.message}`}
                />
            )}
            {requestIdForRejection && (
                <ConfirmModal
                    loading={isLoadingCancelProvisioningRequest}
                    open={true}
                    setOpen={() => setRequestIdForRejection(null)}
                    onConfirm={() => handleRejection(requestIdForRejection)}
                    title={"Reject provisioning request?"}
                    description={`The provisioning request of device with id ${deviceId} will be rejected.`}
                    confirmText={"Yes, reject"}
                    error={
                        rejectionError && `Error ${rejectionError?.data.statusCode}: ${rejectionError?.data.message}`
                    }
                />
            )}
        </>
    );
};
