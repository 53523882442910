import { PublicClientApplication } from "@azure/msal-browser";
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { Mutex } from "async-mutex";
import { config } from "config";
import { setNewAccessToken } from "store/slices/auth-slice";
import type { AppDispatch, RootState } from "store/store";
import { getMsalInstance } from "utils/msal";

const mutex = new Mutex();
const msalInstance = getMsalInstance();

const baseQuery = fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: (headers, api) => {
        const state = api.getState() as RootState;
        headers.set("authorization", `Bearer ${state.auth.accessToken}`);
        return headers;
    },
});

/**
 * Extend fetchBaseQuery which automatically tries to acquire a new accessToken when 401 error is received.
 * with using async-mutex to prevent multiple acquiringTokens attempt when multiple calls fail with 401 Unauthorized errors.
 * source: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
 * @param args
 * @param api
 * @param extraOptions
 */
const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (!result.error || result.error.status !== 401) {
        return result;
    }

    if (!mutex.isLocked()) {
        await lockMutexAndRevalidate(api.dispatch);
    } else {
        await mutex.waitForUnlock();
    }
    result = await baseQuery(args, api, extraOptions);
    return result;
};

const lockMutexAndRevalidate = async (dispatch: AppDispatch) => {
    const release = await mutex.acquire();
    try {
        const accessToken = await acquireAccessToken(msalInstance);
        if (accessToken) {
            dispatch(setNewAccessToken(accessToken));
        } else {
            // Do not attempt to log a user in outside the context of MsalProvider
            // see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#acquiring-an-access-token-outside-of-a-react-component
            throw new Error("Authentication failed");
        }
    } finally {
        release();
    }
};

const acquireAccessToken = async (msalInstance: PublicClientApplication) => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        throw new Error("No AD account available");
    }
    const request = {
        scopes: [config.AD_AUTH_SCOPE],
        account: activeAccount ?? accounts[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
};

export { baseQueryWithReAuth };
