import { Variant } from "./types";

import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

export const getVariantColor = (variant?: Variant) => {
    switch (variant) {
        case "info":
            return theme.color.info[500];
        case "success":
            return theme.color.success[500];
        case "warning":
            return theme.color.warning[500];
        case "error":
            return theme.color.error[500];
        default:
            return theme.color.white;
    }
};

const CaretIndicatorStyled = styled.div<Props>`
    position: absolute;
    left: 0;
    width: 0.75rem;
    min-height: 100%;
    background-color: ${(props) => getVariantColor(props.variant)};
`;

const CaretIndicatorInnerStyled = styled.div<Props>`
    position: absolute;
    left: 0.25rem;
    width: 1rem;
    min-height: 100%;
    background-color: ${theme.color.white};
    border-radius: 0.5rem;
`;

interface Props {
    variant?: Variant;
}

const CaretIndicator: React.FC<Props> = ({ variant }) => {
    return (
        <CaretIndicatorStyled variant={variant}>
            <CaretIndicatorInnerStyled />
        </CaretIndicatorStyled>
    );
};

export { CaretIndicator };
