import * as Sentry from "@sentry/react";
import App from "App";
import { config } from "config";
import { createRoot } from "react-dom/client";

if (config.SENTRY_DSN) {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.MODE,
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay({
                maskAllText: false,
                maskAllInputs: false,
            }),
        ],
        tracesSampleRate: config.MODE === "production" ? 0.1 : 1.0,
        replaysSessionSampleRate: 1,
        replaysOnErrorSampleRate: 1.0,
        release: `cupcino-frontend-${APP_VERSION}`,
    });
}

// eslint-disable-next-line no-console
console.log(`Cup&Cino Web Portal v${APP_VERSION} on "${config.MODE}" environment`);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(<App />);
