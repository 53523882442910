import { TableCell } from "components/common/table/Table/TableCell";
import { TableCellHeader } from "components/common/table/Table/TableCellHeader";
import { SortConfig, Column, Row, isColumnData } from "components/common/table/types";
import { TableVirtuoso } from "react-virtuoso";
import styled, { css } from "styled-components";

type OnHeaderCellClickFn<T extends Row> = (column: Column<T>) => void;
export type OnRowClickFn<T extends Row> = (row: T) => void;
interface Props<T extends Row> {
    data: T[];
    columns: Column<T>[];
    sortConfig?: SortConfig<T> | null;
    onHeaderCellClick?: OnHeaderCellClickFn<T>;
    onRowClick?: OnRowClickFn<T>;
}

const TableStyled = styled.table`
    width: 100%;
    table-layout: fixed;

    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.cupcinoBlack};
`;

const clickableRowCSS = css`
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
    }
`;
const TableRowStyled = styled.tr<{ isClickable: boolean }>`
    ${({ isClickable }) => (isClickable ? clickableRowCSS : "")};
`;

const Table = <T extends Row>(props: Props<T>) => {
    const { columns, onRowClick } = props;

    return (
        <TableVirtuoso
            data={props.data}
            components={{
                Table: ({ ...props }) => <TableStyled {...props} />,
                TableRow: ({ ...props }) => <TableRowStyled {...props} isClickable={!!onRowClick} />,
            }}
            fixedHeaderContent={() => (
                <tr>
                    {columns.map((column, colIndex) => (
                        <TableCellHeader
                            key={colIndex}
                            column={column}
                            sortConfig={props.sortConfig}
                            onClick={() => props.onHeaderCellClick?.(column)}
                        >
                            {isColumnData(column) ? column.label : undefined}
                        </TableCellHeader>
                    ))}
                </tr>
            )}
            itemContent={(rowIndex, row) =>
                columns.map((column, columnIndex) => (
                    <TableCell
                        key={`cell-${rowIndex}-${
                            isColumnData(column) ? String(column.dataIndex) : `action-${columnIndex}`
                        }`}
                        row={row}
                        column={column}
                        onClick={props.onRowClick ? () => props.onRowClick?.(row) : undefined}
                    />
                ))
            }
        />
    );
};

export { Table };
export type { Props as TableProps };
