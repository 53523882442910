import { WithRequiredProp } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { Loader } from "components/common/Loader";
import { DataTableToolbar } from "components/common/table/DataTable/DataTableToolbar";
import {
    useDownload,
    useSearch,
    useSort,
    useSortConfig,
    UseSortConfigReturnType,
} from "components/common/table/DataTable/hooks";
import { OnRowClickFn, Table } from "components/common/table/Table";
import { Column, isColumnData, Row, RowIndex, SortConfig } from "components/common/table/types";
import styled from "styled-components";
import { useInputValue, UseInputValueReturn } from "utils/hooks/useInput";

const DataTableStyled = styled.div`
    display: grid;
    grid-template-rows: max-content 1fr;
    height: 100%;
`;

const TableNoDataStyled = styled.div`
    margin: auto;
    color: ${({ theme }) => theme.color.gray[500]};
`;

type Props<T extends Row> = {
    data?: T[];
    columns: Column<T>[];
    onRowClick?: OnRowClickFn<T>;
    exportFileName?: string;
    isLoading?: boolean;
    fallbackText?: {
        noData?: string;
        noSearchedData?: (searchValue: string) => string;
        search?: string;
    };
    configuration?: {
        disableDownload?: boolean;
    };
    searchInput?: UseInputValueReturn;
} & (
    | { initialSortConfig?: SortConfig<T>; sortConfig?: never }
    | { initialSortConfig?: never; sortConfig?: UseSortConfigReturnType<T> }
);

const DataTable = <T extends Row>({
    searchInput: customSearchInput,
    sortConfig: customSortConfig,
    ...props
}: Props<T>) => {
    const fallbackSearchInput = useInputValue();
    const fallbackSortConfig = useSortConfig(props.initialSortConfig);

    const searchInput = customSearchInput ?? fallbackSearchInput;
    const sortConfig = customSortConfig ?? fallbackSortConfig;

    return <DataTableBase {...props} searchInput={searchInput} sortConfig={sortConfig} />;
};

const DataTableBase = <T extends Row>(props: WithRequiredProp<Props<T>, "searchInput" | "sortConfig">) => {
    const { data, columns, fallbackText, searchInput: controlledSearchInput } = props;
    const searchInput = controlledSearchInput;

    const searchedData = useSearch({ searchValue: searchInput.value, ...props });
    const { sortedData, sortConfig, handleApplySort } = useSort({ ...props, data: searchedData });

    const { handleDownload } = useDownload({ ...props, data: sortedData });

    return (
        <DataTableStyled>
            <DataTableToolbar
                searchInput={searchInput}
                fallbackTextSearch={fallbackText?.search}
                onDownloadClick={props?.configuration?.disableDownload ? undefined : handleDownload}
            />
            {props.isLoading ? (
                <Loader size={"3rem"} />
            ) : data === undefined || data.length === 0 ? (
                <TableNoDataStyled>{fallbackText?.noData ?? "No data..."}</TableNoDataStyled>
            ) : searchedData?.length === 0 ? (
                <TableNoDataStyled>
                    {fallbackText?.noSearchedData?.(searchInput.value) ??
                        `No rows including '${searchInput.value}' found.`}
                </TableNoDataStyled>
            ) : (
                <Table
                    data={sortedData ?? []}
                    columns={columns}
                    sortConfig={sortConfig}
                    onHeaderCellClick={(column) => {
                        if (isColumnData(column) && column.sortable) {
                            handleApplySort(column.dataIndex as RowIndex);
                        }
                    }}
                    onRowClick={props.onRowClick}
                />
            )}
        </DataTableStyled>
    );
};

export { DataTable };
