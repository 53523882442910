import { MsalProvider } from "@azure/msal-react";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown-tree-select/dist/styles.css";
import { AppRouter } from "routing/AppRouter";
import { persistor, store } from "store";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "styles/GlobalStyles";
import { theme } from "styles/theme";
import { getMsalInstance } from "utils/msal";

const App: React.VFC = () => {
    const msalInstance = getMsalInstance();

    return (
        <React.StrictMode>
            <BrowserRouter>
                <Provider store={store}>
                    <MsalProvider instance={msalInstance}>
                        <ThemeProvider theme={theme}>
                            <PersistGate loading={null} persistor={persistor}>
                                <GlobalStyles />
                                <AppRouter />
                            </PersistGate>
                        </ThemeProvider>
                    </MsalProvider>
                </Provider>
            </BrowserRouter>
        </React.StrictMode>
    );
};

export default App;
