import { CalendarIcon } from "components/icons/Icons";
import { endOfDay, startOfDay } from "date-fns";
import { DashboardPickerLabel } from "pages/DashboardPage/DashboardPickerLabel";
import { TimePickerRangeSeparator, TimePickerRangeStyled } from "pages/DashboardPage/TimePicker/TimePickerRange";
import { TimePickerSelect } from "pages/DashboardPage/TimePicker/TimePickerSelect";
import React from "react";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { useDashboardTimeRange } from "store/hooks/useDashboardTimeRange";
import { useDashboardTimeRangeType } from "store/hooks/useDashboardTimeRangeType";
import {
    setTimeRange,
    setTimeRangeDateTimeFrom,
    setTimeRangeDateTimeTo,
    TimeRangePresetType,
} from "store/slices/dashboard-slice";
import styled from "styled-components";
import { dateToISOStringUTC } from "utils/date";

export const TimePickerStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
`;

export const TimePickerContainer = styled.div`
    display: flex;
    gap: 1rem;

    & > div {
        flex-shrink: 0;
    }
`;

const predefinedRanges: TimeRangePresetType[] = ["allTime", "prevYear", "months12", "prevMonth", "days30"];
const predefinedRangeLabels: Record<TimeRangePresetType, string> = {
    allTime: "All Time",
    prevYear: "Previous Year",
    months12: "Last 12 Months",
    prevMonth: "Previous Month",
    days30: "Last 30 Days",
};
type CustomTimeRangeType = "custom";

const TimePicker: React.FC = () => {
    const timeRangeType = useDashboardTimeRangeType();
    const timeRange = useDashboardTimeRange();

    const dateTimeFrom = new Date(timeRange.dateTimeFrom);
    const dateTimeTo = new Date(timeRange.dateTimeTo);
    const dispatch = useDispatch();

    return (
        <TimePickerStyled>
            <DashboardPickerLabel Icon={CalendarIcon} value={"Date range"} />
            <TimePickerContainer>
                <TimePickerSelect
                    value={timeRangeType ?? "custom"}
                    onChange={(event) => {
                        const value = event.currentTarget.value as TimeRangePresetType | CustomTimeRangeType;
                        if (value === "custom") return;

                        dispatch(setTimeRange({ type: value }));
                    }}
                >
                    {predefinedRanges.map((predefinedRange) => (
                        <option key={predefinedRange} value={predefinedRange}>
                            {predefinedRangeLabels[predefinedRange]}
                        </option>
                    ))}
                    {timeRangeType === undefined && <option value={"custom"}>Custom range</option>}
                </TimePickerSelect>
                <TimePickerRangeStyled title={"Choose a custom date range"}>
                    <DatePicker
                        selected={dateTimeFrom}
                        onChange={(date) => {
                            if (!date) return;

                            dispatch(setTimeRangeDateTimeFrom(dateToISOStringUTC(startOfDay(date))));
                        }}
                        selectsStart
                        startDate={dateTimeFrom}
                        endDate={dateTimeTo}
                        portalId={"root-portal"}
                        dateFormat={"dd/MM/yyyy"}
                    />
                    <TimePickerRangeSeparator>-</TimePickerRangeSeparator>
                    <DatePicker
                        selected={dateTimeTo}
                        onChange={(date) => {
                            if (!date) return;

                            dispatch(setTimeRangeDateTimeTo(dateToISOStringUTC(endOfDay(date))));
                        }}
                        selectsEnd
                        startDate={dateTimeFrom}
                        endDate={dateTimeTo}
                        minDate={dateTimeFrom}
                        portalId={"root-portal"}
                        dateFormat={"dd/MM/yyyy"}
                    />
                </TimePickerRangeStyled>
            </TimePickerContainer>
        </TimePickerStyled>
    );
};

export { TimePicker };
