import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

interface TableChipProps {
    children?: React.ReactNode;
    customColor?: { 500: string; 900: string };
}

const TableChipStyled = styled.div<TableChipProps>`
    display: inline-flex;
    align-items: center;
    background-color: ${theme.color.gray[200]};
    color: ${({ customColor: color }) => (color ?? theme.color.gray)[900]};
    border-radius: 2rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
`;

const TableChipIndicatorStyled = styled.div<TableChipProps>`
    flex-shrink: 0;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;
    border-radius: 0.75rem;
    background-color: ${({ customColor: color }) => (color ?? theme.color.gray)[500]};
`;

const TableChipContentStyled = styled.div<TableChipProps>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const TableChip: React.FC<TableChipProps> = ({ customColor: color, children }) => {
    return (
        <TableChipStyled customColor={color}>
            <TableChipIndicatorStyled customColor={color} />
            <TableChipContentStyled>{children}</TableChipContentStyled>
        </TableChipStyled>
    );
};

export { TableChip };
