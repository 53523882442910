import { LogoutButton } from "components/layout/LogoutButton";
import React from "react";
import styled from "styled-components";

const NotAllowedPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    gap: 1rem;
`;

export const NotAllowedPage: React.VFC = () => {
    return (
        <>
            <NotAllowedPageContainer>
                <h1>You do not have access to the portal</h1>
                <LogoutButton variant="dark" />
            </NotAllowedPageContainer>
        </>
    );
};
