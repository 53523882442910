import { Download } from "@styled-icons/fa-solid";
import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

const TableDownloadStyled = styled.button`
    flex-shrink: 0;
    /* background-color: ${theme.color.gray[100]}; */
    border: 1px solid ${theme.color.gray[200]};
    color: ${theme.color.gray[700]};
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    box-shadow: ${theme.boxShadow.sm};

    &:hover {
        background-color: ${theme.color.gray[200]};
        color: ${theme.color.gray[900]};
    }

    &:active {
        border-color: ${theme.color.gray[300]};
    }
`;

const IconStyled = styled(Download)`
    margin-right: 0.5rem;
`;

interface Props {
    onClick: () => void;
}

const TableDownload: React.FC<Props> = ({ onClick }) => {
    return (
        <TableDownloadStyled title={"Download this table in a CSV file"} onClick={onClick}>
            <IconStyled size={"14px"} />
            CSV
        </TableDownloadStyled>
    );
};

export { TableDownload };
