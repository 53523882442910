import { FormInputWrapper } from "components/form/FormInputWrapper";
import { FieldValidator, useField } from "formik";
import React from "react";
import styled from "styled-components";
import { inputCSS } from "styles/input";
import { splitAndCapitalize } from "utils/string";

const InputStyled = styled.input`
    ${inputCSS}
`;

interface TextInputProps {
    name: string;
    label?: string;
    required?: boolean;
    placeholder?: string;
    type?: string;
    disabled?: boolean;
    validate?: FieldValidator;
}

export const TextInput: React.VFC<TextInputProps> = (props) => {
    const { label: _label, validate, required, ...inputProps } = props;
    const [field, meta] = useField({ name: props.name, validate });
    const label = _label ?? splitAndCapitalize(props.name);

    return (
        <FormInputWrapper label={label} required={required} disabled={inputProps.disabled} meta={meta}>
            <InputStyled type={"text"} {...field} {...inputProps} />
        </FormInputWrapper>
    );
};
