import { useAppSelector } from "store";

export const useCurrentUserRole = () => {
    const user = useAppSelector((state) => state.auth.user);

    if (!user) {
        throw new Error("User is not authenticated!");
    }

    if (!user.role) {
        throw new Error("User role is missing!");
    }

    return user.role;
};
