import { Button } from "components/common/Button";
import { ConfirmModal } from "components/common/ConfirmModal";
import { IconButton } from "components/common/IconButton";
import { Modal } from "components/common/Modal";
import { PageContainer } from "components/common/PageContainer";
import { PageHeader } from "components/common/PageHeader";
import { WidgetFrame } from "components/common/WidgetFrame";
import { DataTable } from "components/common/table/DataTable";
import { useSortConfig } from "components/common/table/DataTable/hooks";
import { Column, ColumnActionComponent } from "components/common/table/types";
import { AddCustomerForm } from "components/form/forms/AddCustomerForm";
import { DeleteIcon, DetailIcon, AddIcon, CustomersIcon } from "components/icons/Icons";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { routes } from "routing/routes";
import { KeyAccountPayload, useCurrentUserRole } from "store";
import {
    CustomerPayload,
    CustomerRequest,
    useAddCustomerMutation,
    useDeleteCustomerMutation,
    useGetCustomersQuery,
} from "store/services/customer-service";
import { Role } from "types/authTypes";
import { useInputValue } from "utils/hooks/useInput";

export const CustomersPage: React.VFC = () => {
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [modalError, setModalError] = useState<string | undefined>();

    const role = useCurrentUserRole();
    const [createCustomer, { isLoading: isCreateLoading }] = useAddCustomerMutation();
    const [deleteCustomer, { isLoading: isDeleteLoading }] = useDeleteCustomerMutation();
    const {
        data: customers,
        isLoading: isCustomerQueryLoading,
        isFetching: isCustomerQueryFetching,
    } = useGetCustomersQuery();

    const isLoading = isCustomerQueryLoading || isDeleteLoading || isCustomerQueryFetching;

    const handleCreate = async (values: CustomerRequest) => {
        try {
            await createCustomer(values).unwrap();
            setShowForm(false);
        } catch (err: any) {
            setModalError(err?.data?.error);
        }
    };

    const handleDelete = async (id: number) => {
        try {
            await deleteCustomer(id).unwrap();
            setDeleteId(null);
        } catch (err: any) {
            setModalError(err?.data?.error);
        }
    };

    const handleOpenDetailPage = (customer: CustomerPayload) => {
        navigate(`${routes.customerDetail.getPath({ customerId: customer.id.toString() })}`);
    };

    const openEditModal = (isOpen: boolean) => {
        setShowForm(isOpen);
        setModalError(undefined);
    };

    const openDeleteModal = (id: number) => {
        setDeleteId(id);
        setModalError(undefined);
    };

    const dataTableSearchInput = useInputValue();
    const dataTableSortConfig = useSortConfig<CustomerPayload>({ dataIndex: "name", descending: false });

    const OpenDetailPageButton: ColumnActionComponent<CustomerPayload> = ({ row }) => (
        <IconButton onClick={() => handleOpenDetailPage(row)} title={"Details"} Icon={DetailIcon} />
    );
    const OpenDeleteModalButton: ColumnActionComponent<CustomerPayload> = ({ row }) => (
        <IconButton onClick={() => openDeleteModal(row.id)} title={"Delete"} Icon={DeleteIcon} />
    );

    const columns: Column<CustomerPayload>[] = [
        { type: "data", dataIndex: "name", dataType: "string", sortable: true },
        { type: "data", dataIndex: "customerNumber", dataType: "string", sortable: true },
        { type: "data", dataIndex: "city", dataType: "string", sortable: true },
        {
            type: "data",
            dataIndex: "keyAccount",
            dataType: "string",
            valueTransform: (keyAccount: KeyAccountPayload | null) => {
                return keyAccount?.name;
            },
            sortable: true,
        },
        {
            type: "actions",
            width: 135,
            components: [Role.Superadmin, Role.KeyAccount].includes(role as Role)
                ? [OpenDetailPageButton, OpenDeleteModalButton]
                : [OpenDetailPageButton],
        },
    ];

    return (
        <PageContainer>
            <PageHeader title={"Customer Administration"}>
                {[Role.Superadmin, Role.KeyAccount].includes(role as Role) && (
                    <Button onClick={() => openEditModal(true)} Icon={AddIcon}>
                        Add Customer
                    </Button>
                )}
            </PageHeader>
            <WidgetFrame isLoading={isLoading} height={"100%"} title={"Customers"} Icon={CustomersIcon}>
                <DataTable<CustomerPayload>
                    data={customers}
                    columns={columns}
                    configuration={{ disableDownload: true }}
                    sortConfig={dataTableSortConfig}
                    searchInput={dataTableSearchInput}
                />
            </WidgetFrame>

            <Modal isOpen={showForm} setOpen={openEditModal}>
                <AddCustomerForm
                    editData={null}
                    isSubmitQueryLoading={isCreateLoading}
                    handleSubmit={handleCreate}
                    error={modalError}
                />
            </Modal>
            {deleteId && (
                <ConfirmModal
                    open={true}
                    setOpen={() => setDeleteId(null)}
                    onConfirm={() => handleDelete(deleteId)}
                    title={"Delete customer?"}
                    description={`Customer ${customers?.find((x) => x.id === deleteId)?.name} will be deleted.`}
                    confirmText={"Yes, delete"}
                    error={modalError}
                />
            )}
        </PageContainer>
    );
};
