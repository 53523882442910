import { ErrorIcon } from "components/icons/Icons";
import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

interface Props {
    children?: React.ReactNode;
}

export const ErrorPanelStyled = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    margin-top: 1rem;

    color: ${theme.color.error[900]};
    border: 2px solid ${theme.color.error[500]};
    border-radius: ${theme.borderRadius.md};

    font-weight: 500;
`;

export const ErrorIconStyled = styled(ErrorIcon)`
    margin-right: 0.75rem;

    color: ${theme.color.error[500]};
`;

export const ErrorPanel: React.FC<Props> = ({ children }) => {
    if (children === undefined || children === null) return null;

    return (
        <ErrorPanelStyled>
            <ErrorIconStyled size={"20px"} />
            Error: {children}
        </ErrorPanelStyled>
    );
};
