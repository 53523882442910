import {
    ArrowRight,
    BuildingColumns,
    ChartColumn,
    Calendar,
    ChartPie,
    ClockRotateLeft,
    Check,
    Cubes,
    Download,
    DownLong,
    Droplet,
    Filter,
    Gauge,
    Info,
    Key,
    LeftRight,
    MugHot,
    Pencil,
    Plus,
    RightFromBracket,
    ScrewdriverWrench,
    Signal,
    Spinner,
    Table,
    Trash,
    TriangleExclamation,
    UpLong,
    Xmark,
    CircleExclamation,
    RightToBracket,
    ArrowUp,
    ArrowDown,
    ArrowsUpDown,
    RotateRight,
    PersonCirclePlus,
} from "@styled-icons/fa-solid";
import { Approval, ChevronLeft, CoffeeMaker, Groups, Search, SupervisorAccount } from "@styled-icons/material";
import { StyledIconProps } from "@styled-icons/styled-icon";
import React from "react";

export type IconProps = StyledIconProps & {
    size?: number | `${string}px` | `${string}%`;
};
export type IconComponent = React.VFC<IconProps>;

export const AddIcon: IconComponent = (props) => {
    return <Plus {...props} />;
};

export const ApprovalIcon: IconComponent = (props) => {
    return <Approval {...props} />;
};

export const ArrowDownLongIcon: IconComponent = (props) => {
    return <DownLong {...props} />;
};

export const ArrowRightIcon: IconComponent = (props) => {
    return <ArrowRight {...props} />;
};

export const ArrowUpLongIcon: IconComponent = (props) => {
    return <UpLong {...props} />;
};

export const BrewIcon: IconComponent = (props) => {
    return <LeftRight {...props} />;
};

export const CalendarIcon: IconComponent = (props) => {
    return <Calendar {...props} />;
};

export const CheckIcon: IconComponent = (props) => {
    return <Check {...props} />;
};

export const ChevronLeftIcon: IconComponent = (props) => {
    return <ChevronLeft {...props} />;
};

export const CloseIcon: IconComponent = (props) => {
    return <Xmark {...props} />;
};

export const CoffeeIcon: IconComponent = (props) => {
    return <MugHot {...props} />;
};

export const CustomersIcon: IconComponent = (props) => {
    return <SupervisorAccount {...props} />;
};

export const DashboardIcon: IconComponent = (props) => {
    return <Gauge {...props} />;
};

export const DeleteIcon: IconComponent = (props) => {
    return <Trash {...props} />;
};

export const DetailIcon: IconComponent = (props) => {
    return <Info {...props} />;
};

export const DownloadIcon: IconComponent = (props) => {
    return <Download {...props} />;
};

export const EditIcon: IconComponent = (props) => {
    return <Pencil {...props} />;
};

export const ErrorIcon: IconComponent = (props) => {
    return <CircleExclamation {...props} />;
};

export const FilterIcon: IconComponent = (props) => {
    return <Filter {...props} />;
};

export const GraphBarIcon: IconComponent = (props) => {
    return <ChartColumn {...props} />;
};

export const GraphPieIcon: IconComponent = (props) => {
    return <ChartPie {...props} />;
};

export const HistoryIcon: IconComponent = (props) => {
    return <ClockRotateLeft {...props} />;
};

export const InstallationIcon: IconComponent = (props) => {
    return <ScrewdriverWrench {...props} />;
};

export const KeyIcon: IconComponent = (props) => {
    return <Key {...props} />;
};

export const KeyAccountIcon: IconComponent = (props) => {
    return <BuildingColumns {...props} />;
};

export const LoginIcon: IconComponent = (props) => {
    return <RightToBracket {...props} />;
};

export const LogoutIcon: IconComponent = (props) => {
    return <RightFromBracket {...props} />;
};

export const MachineIcon: IconComponent = (props) => {
    return <CoffeeMaker {...props} />;
};

export const MilkIcon: IconComponent = (props) => {
    return (
        <svg
            width={props.size}
            height={props.size}
            fill={"currentColor"}
            focusable={"false"}
            aria-hidden={"true"}
            xmlns={"http://www.w3.org/2000/svg"}
            viewBox={"0 0 448 512"}
        >
            <path
                d={
                    "M64 0C55.1 0 46.6 3.7 40.6 10.2s-9.1 15.2-8.5 24.1L60.9 437.7c3 41.9 37.8 74.3 79.8 74.3H307.3c42 0 76.8-32.4 79.8-74.3L415.9 34.3c.6-8.9-2.4-17.6-8.5-24.1S392.9 0 384 0H64zm41 156.5L98.4 64H349.6L343 156.5l-24.2 12.1c-19.4 9.7-42.2 9.7-61.6 0c-20.9-10.4-45.5-10.4-66.4 0c-19.4 9.7-42.2 9.7-61.6 0L105 156.5z"
                }
            />
        </svg>
    );
};

export const ModuleIcon: IconComponent = (props) => {
    return <Cubes {...props} />;
};

export const ResetIcon: IconComponent = (props) => {
    return <RotateRight {...props} />;
};

export const SearchIcon: IconComponent = (props) => {
    return <Search {...props} />;
};

export const SortAscendingIcon: IconComponent = (props) => {
    return <ArrowUp {...props} />;
};

export const SortDescendingIcon: IconComponent = (props) => {
    return <ArrowDown {...props} />;
};

export const SortUndefinedIcon: IconComponent = (props) => {
    return <ArrowsUpDown {...props} />;
};

export const OnlineIcon: IconComponent = (props) => {
    return <Signal {...props} />;
};

export const PersonCirclePlusIcon: IconComponent = (props) => {
    return <PersonCirclePlus {...props} />;
};

export const SpinnerIcon: IconComponent = (props) => {
    return <Spinner {...props} />;
};

export const TableIcon: IconComponent = (props) => {
    return <Table {...props} />;
};

export const UninstallIcon: IconComponent = (props) => {
    return <RightFromBracket {...props} />;
};

export const UsersIcon: IconComponent = (props) => {
    return <Groups {...props} />;
};

export const WarningIcon: IconComponent = (props) => {
    return <TriangleExclamation {...props} />;
};

export const WaterIcon: IconComponent = (props) => {
    return <Droplet {...props} />;
};

export const getIcon = (name: string, props: IconProps) => {
    switch (name) {
        case "KeyAccountIcon":
            return <BuildingColumns {...props} />;
        case "UsersIcon":
            return <Groups {...props} />;
        case "CustomersIcon":
            return <SupervisorAccount {...props} />;
        case "MachineIcon":
            return <CoffeeMaker {...props} />;
        case "ModuleIcon":
            return <Cubes {...props} />;
    }
};
