import { createGlobalStyle } from "styled-components";
import { datepickerCSS } from "styles/datepicker";
import { theme } from "styles/theme";

export const GlobalStyles = createGlobalStyle`
  :root {
    --reach-dialog: 1;
    // Main font "Inter" with fallback system fonts
    font-family: 'Inter', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
  }

  @supports (font-variation-settings: normal) {
    :root {
      font-family: 'Inter var', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
    }
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    background: ${theme.color.gray[50]};
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  input, button, textarea, select {
    font: inherit;
  }

  button {
    border: none;
    background: unset;
    cursor: pointer;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
    color: ${theme.color.gray[900]};
  }

  #root {
    isolation: isolate;
  }

  ${datepickerCSS}
`;
