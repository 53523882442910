import { MachineDetail } from "components/common/MachineDetail/MachineDetail";
import { MachineInstallation } from "components/common/MachineDetail/MachineInstallation";
import { PageContainer } from "components/common/PageContainer";
import { PageHeader } from "components/common/PageHeader";
import React from "react";

export const MachineDetailPage: React.VFC = () => {
    return (
        <PageContainer>
            <PageHeader title={"Machine Administration"} />
            <MachineDetail />
            <MachineInstallation />
        </PageContainer>
    );
};
