import { Loader } from "components/common/Loader";
import { Modal } from "components/common/Modal";
import { RecordDetailBox } from "components/common/RecordDetailBox";
import { WidgetFrame } from "components/common/WidgetFrame";
import { DeviceForm, FormSubmitData as DeviceFormData } from "components/form/forms/DeviceForm";
import { MachineIcon } from "components/icons/Icons";
import { LoaderOverlap } from "components/styled/LoaderOverlap";
import React, { useState } from "react";
import { useParams } from "react-router";
import {
    DevicePayload,
    useGetDeviceTypesQuery,
    useGetOneDeviceQuery,
    useUpdateDeviceMutation,
} from "store/services/device-service";

export const MachineDetail: React.VFC = () => {
    const { machineId } = useParams() as { machineId: string };
    const [showForm, setShowForm] = useState(false);
    const [editData, setEditData] = useState<any | null>(null);

    const { data: machine, isLoading: isDeviceQueryLoading } = useGetOneDeviceQuery(machineId);
    const [updateDevice, { isLoading: isUpdateLoading }] = useUpdateDeviceMutation();

    const { data: deviceTypes, isLoading: isDeviceTypesLoading } = useGetDeviceTypesQuery();

    const isLoading = isDeviceQueryLoading || isUpdateLoading;

    const handleUpdate = async (values: DeviceFormData) => {
        if (!machine) return;

        await updateDevice({ device: values, id: machine.id });
        setShowForm(false);
        setEditData(null);
    };

    const openModal = (isOpen: boolean) => {
        setShowForm(isOpen);
        setEditData(machine);
    };

    let mappedMachineData = null;

    if (machine) {
        const { ipAddress, ...device } = machine;
        mappedMachineData = { ...device, ipAddress: ipAddress ?? "" };
    }

    return (
        <>
            <WidgetFrame isLoading={isLoading} title={"Machine Detail"} Icon={MachineIcon}>
                {mappedMachineData && (
                    <RecordDetailBox<DevicePayload & { ipAddress: string }>
                        item={mappedMachineData}
                        buttonTitle={"Edit Machine"}
                        clickHandler={() => openModal(true)}
                    />
                )}
            </WidgetFrame>
            <Modal isOpen={showForm} setOpen={openModal}>
                <DeviceForm initialData={editData} handleSubmit={handleUpdate} deviceTypes={deviceTypes} />

                {(isUpdateLoading || isDeviceTypesLoading) && (
                    <LoaderOverlap>
                        <Loader size={"4rem"} />
                    </LoaderOverlap>
                )}
            </Modal>
        </>
    );
};
