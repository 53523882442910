import type { CaretIconProps, Variant } from "./types";

import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

export const getVariantColor = (variant?: Variant) => {
    switch (variant) {
        case "info":
            return theme.color.info[200];
        case "success":
            return theme.color.success[200];
        case "warning":
            return theme.color.warning[200];
        case "error":
            return theme.color.error[200];
        default:
            return theme.color.gray[300];
    }
};

interface CaretIconStyledProps {
    variant?: Variant;
}

const CaretIconStyled = styled.div<CaretIconStyledProps>`
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    color: ${({ variant }) => getVariantColor(variant)};
    opacity: 50%;
`;

interface Props {
    Icon: React.ComponentType<CaretIconProps>;
    variant?: Variant;
}

const CaretIcon: React.FC<Props> = (props) => {
    const { Icon } = props;

    return (
        <CaretIconStyled variant={props.variant}>
            <Icon size={"80px"} />
        </CaretIconStyled>
    );
};

export { CaretIcon };
