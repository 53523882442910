import { IconButton } from "components/common/IconButton";
import { Loader } from "components/common/Loader";
import { Modal } from "components/common/Modal";
import { NavigateBadge } from "components/common/NavigateBadge";
import { WidgetFrame } from "components/common/WidgetFrame";
import { DataTable } from "components/common/table/DataTable";
import { useSortConfig } from "components/common/table/DataTable/hooks";
import { Column, ColumnActionComponent } from "components/common/table/types";
import {
    AddDeviceInstallationForm,
    FormData as DeviceInstallationFormData,
} from "components/form/forms/AddDeviceInstallationForm";
import { EditIcon, HistoryIcon } from "components/icons/Icons";
import { LoaderOverlap } from "components/styled/LoaderOverlap";
import React, { useState } from "react";
import { useParams } from "react-router";
import { routes } from "routing/routes";
import { CustomerPayload } from "store/services/customer-service";
import {
    DeviceInstallationPayload,
    useGetInstallationsForDeviceQuery,
    useUpdateInstallationDatesMutation,
} from "store/services/device-installation-service";
import { useInputValue } from "utils/hooks/useInput";

export const MachineInstallation: React.VFC = () => {
    const { machineId } = useParams() as { machineId: string };
    const [showDeviceInstallationForm, setShowDeviceInstallationForm] = useState(false);
    const [editData, setEditData] = useState<DeviceInstallationPayload | null>(null);
    const [modalError, setModalError] = useState<string | undefined>();

    const { data: deviceInstallations, isLoading: isDeviceInstallationsQueryLoading } =
        useGetInstallationsForDeviceQuery(machineId);

    const [updateDeviceInstallation, { isLoading: isDeviceInstallationUpdateLoading }] =
        useUpdateInstallationDatesMutation();

    const handleUpdate = async (values: DeviceInstallationFormData) => {
        if (!editData) return;

        try {
            await updateDeviceInstallation({
                installationRequest: { ...values, deviceId: machineId },
                id: editData.id,
            }).unwrap();
            setEditData(null);
            setShowDeviceInstallationForm(false);
        } catch (err: any) {
            setModalError(err?.data?.error);
        }
    };

    const openModal = (isOpen: boolean) => {
        setModalError(undefined);
        setShowDeviceInstallationForm(isOpen);
    };

    const openEdit = (isOpen: boolean, deviceInstallation: DeviceInstallationPayload) => {
        openModal(isOpen);
        setEditData(deviceInstallation);
    };

    const dataTableSearchInput = useInputValue();
    const dataTableSortConfig = useSortConfig<DeviceInstallationPayload>({
        dataIndex: "from",
        descending: false,
    });

    const OpenEditModalButton: ColumnActionComponent<DeviceInstallationPayload> = ({ row }) => (
        <IconButton onClick={() => openEdit(true, row)} title={"Edit"} Icon={EditIcon} />
    );

    const columns: Column<DeviceInstallationPayload>[] = [
        { type: "data", dataIndex: "from", dataType: "date", sortable: true },
        { type: "data", dataIndex: "to", dataType: "date", sortable: true },
        {
            type: "data",
            dataIndex: "customer",
            dataType: "string",
            sortable: true,
            valueTransform: (value: CustomerPayload) => value?.name,
            CellWrapper: ({ row, value }) => {
                if (value === undefined || value === "") return null;

                return (
                    <NavigateBadge path={routes.customerDetail.getPath({ customerId: String(row.customer.id) })}>
                        {value}
                    </NavigateBadge>
                );
            },
        },
        {
            type: "actions",
            width: 300,
            components: [OpenEditModalButton],
        },
    ];

    return (
        <>
            <WidgetFrame
                isLoading={isDeviceInstallationsQueryLoading}
                minHeight={"25rem"}
                title={"Installation history"}
                Icon={HistoryIcon}
            >
                <DataTable<DeviceInstallationPayload>
                    data={deviceInstallations}
                    columns={columns}
                    configuration={{ disableDownload: true }}
                    sortConfig={dataTableSortConfig}
                    searchInput={dataTableSearchInput}
                />
            </WidgetFrame>

            <Modal isOpen={showDeviceInstallationForm} setOpen={openModal}>
                <AddDeviceInstallationForm
                    editData={editData}
                    handleSubmit={handleUpdate}
                    error={modalError}
                ></AddDeviceInstallationForm>

                {isDeviceInstallationUpdateLoading && (
                    <LoaderOverlap>
                        <Loader size={"4rem"} />
                    </LoaderOverlap>
                )}
            </Modal>
        </>
    );
};
