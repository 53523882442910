import { NavigateBadge } from "components/common/NavigateBadge";
import { WidgetFrame } from "components/common/WidgetFrame";
import { DataTable } from "components/common/table/DataTable";
import { useSortConfig } from "components/common/table/DataTable/hooks";
import { Column } from "components/common/table/types";
import { HistoryIcon } from "components/icons/Icons";
import React from "react";
import { useParams } from "react-router";
import { routes } from "routing/routes";
import {
    DeviceInstallationForCustomerPayload,
    useGetInstallationsForCustomerQuery,
} from "store/services/device-installation-service";
import { useInputValue } from "utils/hooks/useInput";

const columns: Column<DeviceInstallationForCustomerPayload>[] = [
    { type: "data", dataIndex: "from", dataType: "date", sortable: true },
    { type: "data", dataIndex: "to", dataType: "date", sortable: true },
    {
        type: "data",
        dataIndex: "deviceId",
        dataType: "string",
        sortable: true,
        CellWrapper: ({ value }) => {
            if (value === undefined) return null;

            return <NavigateBadge path={routes.machineDetail.getPath({ machineId: value })}>{value}</NavigateBadge>;
        },
    },
];

export const MachineInstallationHistory: React.VFC = () => {
    const { customerId } = useParams() as { customerId: string };

    const {
        data,
        isLoading: isQueryLoading,
        isFetching: isQueryFetching,
    } = useGetInstallationsForCustomerQuery({ customerId: parseInt(customerId), onlyInactive: true });

    const isLoading = isQueryLoading || isQueryFetching;

    const dataTableSearchInput = useInputValue();
    const dataTableSortConfig = useSortConfig<DeviceInstallationForCustomerPayload>({
        dataIndex: "from",
        descending: false,
    });

    return (
        <WidgetFrame
            isLoading={isLoading}
            title={"Machine installation history"}
            Icon={HistoryIcon}
            minHeight={"30rem"}
        >
            <DataTable<DeviceInstallationForCustomerPayload>
                data={data}
                columns={columns}
                configuration={{ disableDownload: true }}
                sortConfig={dataTableSortConfig}
                searchInput={dataTableSearchInput}
            />
        </WidgetFrame>
    );
};
