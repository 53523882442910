import { css } from "styled-components";
import { theme } from "styles/theme";

export const inputCSS = css`
    width: 100%;
    padding: 0.5rem 0.75rem;

    background-color: ${theme.color.white};
    border: 1px solid ${theme.color.gray[300]};
    border-radius: ${theme.borderRadius.md};
    box-shadow: ${theme.boxShadow.sm};

    /* line-height: 1; */

    :focus {
        border-color: transparent;
        outline: 2px solid ${theme.color.primary[500]};
    }

    :disabled {
        background-color: ${theme.color.gray[100]};
        cursor: not-allowed;
    }
`;
