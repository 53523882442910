import { Loader } from "components/common/Loader";
import { IconComponent } from "components/icons/Icons";
import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

interface WidgetFrameProps {
    children: React.ReactNode;
    isLoading: boolean;
    Icon?: IconComponent;
    title?: string;
    titleToolbar?: JSX.Element;
}

const WidgetFrameHeader = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
        min-width: fit-content;

        color: ${theme.color.gray[900]};

        font-size: 1.25rem;
        font-weight: 600;
        letter-spacing: 0.0125rem;
    }

    svg {
        color: ${theme.color.gray[500]};
    }
`;

interface WidgetFrameStyledProps {
    height?: string;
    minHeight?: string;
}

export const WidgetFrameStyled = styled.div<WidgetFrameStyledProps>`
    width: 100%;
    padding: 1rem 1.5rem;

    background-color: ${theme.color.white};
    border: 1px solid ${theme.color.gray[300]};
    border-radius: ${theme.borderRadius.md};
    box-shadow: ${theme.boxShadow.xl};

    height: ${(props) => props.height ?? "auto"};
    min-height: ${(props) => props.minHeight};
    display: flex;
    flex-direction: column;
`;

export const WidgetFrame: React.FC<WidgetFrameProps & WidgetFrameStyledProps> = ({
    isLoading,
    Icon,
    title,
    titleToolbar,
    children,
    ...rest
}) => {
    return (
        <WidgetFrameStyled {...rest}>
            {title && (
                <WidgetFrameHeader>
                    {Icon && <Icon size={"24px"} />}
                    <h2>{title}</h2>
                    {titleToolbar}
                </WidgetFrameHeader>
            )}
            {isLoading ? <Loader size={"48px"} /> : children}
        </WidgetFrameStyled>
    );
};
