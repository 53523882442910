import { inputCSS } from "./input";

import { css } from "styled-components";
import { theme } from "styles/theme";

export const datepickerCSS = css`
    &.react-datepicker-wrapper {
        width: auto;

        .react-datepicker__input-container {
            height: 100%;
        }

        input {
            ${inputCSS}
        }
    }

    &.react-datepicker {
        border-radius: ${theme.borderRadius.sm};

        border: 0;
        border-color: ${theme.color.gray[200]};
        box-shadow: ${theme.boxShadow.md};

        font-size: 1rem;
    }

    &.react-datepicker__navigation {
        height: 2.25rem;
    }

    /* &.react-datepicker__navigation::before {
		border-color: ${theme.color.gray[500]} !important;
	}

	&.react-datepicker__navigation:hover::before {
		border-color: ${theme.color.gray[600]};
	} */

    &.react-datepicker__navigation--previous {
        top: 0;
        left: 0;
    }

    &.react-datepicker__navigation--next {
        top: 0;
        right: 0;
    }

    &.react-datepicker__navigation-icon {
        position: relative;
        top: 0;
        width: 1.25rem;
        height: 1.25rem;
        font-size: 1.25rem;
    }

    &.react-datepicker__month-container {
        border: 1px solid ${theme.color.gray[200]};
        border-radius: ${theme.borderRadius.sm};
        box-shadow: ${theme.boxShadow.xl};
    }

    &.react-datepicker__header {
        padding-top: 0.5rem;

        background-color: ${theme.color.gray[50]};
        border-color: ${theme.color.gray[200]};
    }

    &.react-datepicker__current-month {
        margin-bottom: 0.5rem;

        font-weight: 500;
    }

    &.react-datepicker__day-name {
        width: 2rem;
        height: 2rem;
        margin: 2px;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        color: ${theme.color.gray[700]};
        font-weight: 500;

        line-height: 2rem;
    }

    &.react-datepicker__day-name:not(:last-child) {
        border: 0;
    }

    &.react-datepicker__month {
        margin: 0;
        padding: 0 0.5rem;

        background-color: ${theme.color.white};
        border-radius: ${theme.borderRadius.sm};
    }

    &.react-datepicker__day {
        width: 2rem;
        height: 2rem;
        margin: 2px;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        border-radius: ${theme.borderRadius.sm};
        line-height: 2rem;
    }

    &.react-datepicker__day--selected,
    &.react-datepicker__day--keyboard-selected {
        background-color: ${theme.color.gray[500]};

        &:hover {
            background-color: ${theme.color.gray[600]};
        }
    }

    &.react-datepicker__day--in-range,
    &.react-datepicker__day--in-selecting-range {
        color: ${theme.color.gray[900]};
        font-weight: 500;
    }

    &.react-datepicker__day--in-range {
        color: ${theme.color.white};
        background-color: ${theme.color.primary[500]};
    }

    &.react-datepicker__day--in-selecting-range {
        /* background-color: ${theme.color.gray[200]}; */
    }

    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
        background-color: ${theme.color.gray[100]};
    }

    &.react-datepicker__day--in-selecting-range:hover {
        color: ${theme.color.white};
        background-color: ${theme.color.primary[600]};
        font-weight: 600;
    }

    &.react-datepicker__triangle::before {
        border-bottom: 0 !important;
    }

    &.react-datepicker__triangle::after {
        border-color: ${theme.color.gray[50]};
    }
`;
