import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    PersistConfig,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootApi } from "store/services/root-api";
import { authReducer } from "store/slices/auth-slice";
import { dashboardReducer } from "store/slices/dashboard-slice";

const persistWhitelist = ["dashboard"];

const persistConfig: PersistConfig<any> = {
    key: "root",
    storage,
    whitelist: persistWhitelist,
    // Custom state reconciler (https://github.com/rt2zz/redux-persist#state-reconciler)
    // Tries to detect changes in the persisted state structure and reconciles them with a new one
    // Simple string equality check for each key of item in persist whitelist and use persisted if shape matches
    stateReconciler: (persistedState, _, reducedState) => {
        const newState = { ...reducedState };

        persistWhitelist.forEach((whitelistKey) => {
            const persistedKeys = Object.keys(persistedState[whitelistKey]);
            const reducedKeys = Object.keys(reducedState[whitelistKey]);

            if (
                persistedKeys.length === reducedKeys.length &&
                persistedKeys.every((key, idx) => key === reducedKeys[idx])
            ) {
                newState[whitelistKey] = persistedState[whitelistKey];
            }
        });

        return newState;
    },
};

const appReducer = combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    [rootApi.reducerPath]: rootApi.reducer,
});

const rootReducer = (state: any, action: any) => appReducer(state, action);

const persistedReducer = persistReducer(persistConfig, rootReducer) as typeof rootReducer;

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(rootApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
