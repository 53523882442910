import { useCallback, useState } from "react";

const SIDEBAR_COLLAPSED_KEY = "isCollapsed";

type SidebarCollapseHook = () => [boolean, (isCollapsed: boolean) => void];

export const useSidebarCollapse: SidebarCollapseHook = () => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
        const persistedValue = localStorage.getItem(SIDEBAR_COLLAPSED_KEY);
        if (persistedValue !== null) {
            const parsedValue = JSON.parse(persistedValue);
            if (typeof parsedValue === "boolean") {
                return parsedValue;
            }
            localStorage.removeItem(SIDEBAR_COLLAPSED_KEY);
            return false;
        }
        return false;
    });

    const setAndSaveIsCollapsed = useCallback((isCollapsed: boolean) => {
        setIsCollapsed(isCollapsed);
        localStorage.setItem(SIDEBAR_COLLAPSED_KEY, JSON.stringify(isCollapsed));
    }, []);

    return [isCollapsed, setAndSaveIsCollapsed];
};
