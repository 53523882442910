import { TreeSelect } from "components/common/TreeSelect";
import { Data } from "components/common/TreeSelect/types";
import { MachineIcon } from "components/icons/Icons";
import { DashboardPickerLabel } from "pages/DashboardPage/DashboardPickerLabel";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setDeviceTypes } from "store";
import { useDashboardDeviceTypes } from "store/hooks/useDashboardDeviceTypes";
import { useGetDeviceTypesQuery } from "store/services/device-service";

export function MachineTypePicker() {
    const selectedTypes = useDashboardDeviceTypes();
    const { data: machineTypes } = useGetDeviceTypesQuery();
    const dispatch = useDispatch();

    const mappedMachineTypes: Data[] | undefined = useMemo(() => {
        return machineTypes
            ?.map(({ id, name }) => ({ label: name, id: id.toString(), value: id.toString() }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [machineTypes]);

    return (
        <div>
            <DashboardPickerLabel Icon={MachineIcon} value={"Machine type"} />
            <TreeSelect
                data={mappedMachineTypes}
                selected={selectedTypes}
                onChange={(nodes) => {
                    dispatch(setDeviceTypes(nodes.map((n) => n.value)));
                }}
            />
        </div>
    );
}
