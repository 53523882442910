import { FormInputWrapper } from "components/form/FormInputWrapper";
import { useField, useFormikContext } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { inputCSS } from "styles/input";
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from "utils/date";
import { splitAndCapitalize } from "utils/string";

const DatePickerStyled = styled(DatePicker)`
    ${inputCSS}
`;

interface DateInputProps {
    name: string;
    label?: string;
    required?: boolean;
    showTime?: boolean;
    minDate?: Date | null;
}

export const DateInput: React.VFC<DateInputProps> = (props) => {
    const { label: _label, required } = props;
    const [field, meta] = useField({ name: props.name });
    const label = _label ?? splitAndCapitalize(props.name);

    const { setFieldValue } = useFormikContext<Date>();

    const onDateChange = (date: Date | null) => {
        setFieldValue(field.name, date);
    };

    return (
        <FormInputWrapper label={label} required={required} meta={meta}>
            <DatePickerStyled
                wrapperClassName={"date_picker"}
                selected={field.value}
                onChange={onDateChange}
                portalId={"root-portal"}
                dateFormat={props.showTime ? DATE_WITH_TIME_FORMAT : DATE_FORMAT}
                showTimeInput={props.showTime}
                minDate={props.minDate}
            />
        </FormInputWrapper>
    );
};
