import { css } from "styled-components";

export interface TableCellOptions {
    width?: number;
    minWidth?: number;
}

export const tableCellBaseCSS = css<TableCellOptions>`
    padding: 0.25rem 0.5rem;
    min-width: ${({ minWidth }) => (minWidth ? `${minWidth ?? 40}px` : undefined)};
    width: ${({ width }) => (width ? `${width}px` : undefined)};

    &:first-child {
        padding-left: 1rem;
    }

    &:last-child {
        padding-right: 1rem;
    }
`;

export const tableCellInnerBaseCSS = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
