import { rootApi } from "store/services/root-api";

/*
 * For more info about provisioning take a look at the **DDP Device provisioning documentation**
 * (https://docs.dataplatform.datamole.cloud/kernel/services/device-provisioning/index.html)
 */

export type ProvisioningOperationPayload = {
    provisioningOperationId: string;
    provisioningTokenId: string;
    createdTime: string;
    expirationTime: string;
    state: string;
    closeReason?: string;
    verificationCode: string;
    deviceId?: string;
    deviceIdFromDevice?: string;
    deviceIdFromApproval?: string;
    deviceIdFromProvisioningToken?: string;
    deviceAttributes?: {
        properties?: string;
    };
    lastCompleteAttemptTime?: string;
    closedTime?: string;
};

export type ProvisioningTokenPayload = {
    provisioningTokenId: string;
    provisioningToken: string;
    issuedTime: string;
    maxRegistrationTokenLifetime?: string;
    registrationTokenLifetimeOverrideMode: string;
    deviceIdOverrideMode: string;
    allowInfiniteRegistrationTokenLifetime: boolean;
};

export type ProvisioningTokenRequest =
    | {
          type: "limitedLifetime";
          lifetime: string;
      }
    | {
          type: "infiniteLifetime";
      };

export type ProvisioningOperationsState = "Pending" | "Completed" | "Closed" | "Approved";
export type ProvisioningOperationsQuery = {
    state?: ProvisioningOperationsState;
};

const BASE_PATH = "provisioning";
const CACHE_TYPE = "Provisioning";
const CACHE_ID = "LIST";

const apiWithTag = rootApi.enhanceEndpoints({ addTagTypes: [CACHE_TYPE] });
export const provisioningApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getProvisioningOperations: builder.query<ProvisioningOperationPayload[], ProvisioningOperationsQuery>({
            query: ({ state }) => `${BASE_PATH}/operations${state ? `?state=${state}` : ``}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ provisioningOperationId }) => ({ type: CACHE_TYPE, provisioningOperationId }) as const
                          ),
                          { type: CACHE_TYPE, id: CACHE_ID },
                      ]
                    : [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        approveProvisioningOperation: builder.mutation<ProvisioningOperationPayload, string>({
            query: (provisioningOperationId) => ({
                url: `${BASE_PATH}/${provisioningOperationId}/action/approve`,
                method: "PUT",
            }),
            invalidatesTags: (result) =>
                result
                    ? [
                          { type: CACHE_TYPE, id: CACHE_ID },
                          { type: CACHE_TYPE, provisioningOperationId: result.provisioningOperationId },
                      ]
                    : [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        cancelProvisioningOperation: builder.mutation<ProvisioningOperationPayload, string>({
            query: (provisioningOperationId) => ({
                url: `${BASE_PATH}/${provisioningOperationId}/action/cancel`,
                method: "PUT",
            }),
            invalidatesTags: (result) =>
                result
                    ? [
                          { type: CACHE_TYPE, id: CACHE_ID },
                          { type: CACHE_TYPE, provisioningOperationId: result.provisioningOperationId },
                      ]
                    : [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        createProvisioningToken: builder.mutation<ProvisioningTokenPayload, ProvisioningTokenRequest>({
            query: (body) => ({
                url: `${BASE_PATH}/provisioning-token`,
                body,
                method: "POST",
            }),
        }),
    }),
});

export const {
    useGetProvisioningOperationsQuery,
    useApproveProvisioningOperationMutation,
    useCancelProvisioningOperationMutation,
    useCreateProvisioningTokenMutation,
} = provisioningApi;
