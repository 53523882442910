import { Button } from "components/common/Button";
import { IconButton } from "components/common/IconButton";
import { Loader } from "components/common/Loader";
import { Modal } from "components/common/Modal";
import { PageContainer } from "components/common/PageContainer";
import { PageHeader } from "components/common/PageHeader";
import { WidgetFrame } from "components/common/WidgetFrame";
import { DataTable } from "components/common/table/DataTable";
import { useSortConfig } from "components/common/table/DataTable/hooks";
import { Column, ColumnActionComponent } from "components/common/table/types";
import { AddModuleForm } from "components/form/forms/AddModuleForm";
import { AddIcon, EditIcon, ModuleIcon } from "components/icons/Icons";
import { LoaderOverlap } from "components/styled/LoaderOverlap";
import React, { useState } from "react";
import {
    ModuleRequest,
    ModuleWithFramePayload,
    useAddModuleMutation,
    useGetModulesQuery,
    useUpdateModuleMutation,
} from "store/services/module-service";
import { QueryError } from "store/services/types";
import { useInputValue } from "utils/hooks/useInput";

export const ModulesPage: React.VFC = () => {
    const [showForm, setShowForm] = useState(false);
    const [editData, setEditData] = useState<ModuleWithFramePayload | null>(null);
    const [createModule, { isLoading: isCreateLoading, error: errorCreate, reset: resetCreate }] =
        useAddModuleMutation();
    const [updateModule, { isLoading: isUpdateLoading, error: errorUpdate, reset: resetUpdate }] =
        useUpdateModuleMutation();

    const { data: modules, isLoading: isModuleQueryLoading, isFetching: isModuleQueryFetching } = useGetModulesQuery();

    const isLoading = isModuleQueryLoading || isModuleQueryFetching;
    const mutationError = (errorCreate || errorUpdate) as QueryError | undefined;

    const handleCreate = async (values: ModuleRequest) => {
        await createModule(values).unwrap();
        setShowForm(false);
    };

    const handleUpdate = async (values: ModuleRequest) => {
        if (!editData) return null;

        await updateModule({ serialNumber: editData.serialNumber, module: values }).unwrap();

        setShowForm(false);
        setEditData(null);
    };

    const openModal = (isOpen: boolean, module?: ModuleWithFramePayload) => {
        resetCreate();
        resetUpdate();
        setShowForm(isOpen);
        setEditData(module ? module : null);
    };

    const dataTableSearchInput = useInputValue();
    const dataTableSortConfig = useSortConfig<ModuleWithFramePayload>({ dataIndex: "serialNumber", descending: false });

    const OpenModalButton: ColumnActionComponent<ModuleWithFramePayload> = ({ row }) => (
        <IconButton onClick={() => openModal(true, row)} title={"Edit"} Icon={EditIcon} />
    );

    const columns: Column<ModuleWithFramePayload>[] = [
        { type: "data", dataIndex: "serialNumber", dataType: "string", sortable: true },
        { type: "data", dataIndex: "type", dataType: "string", sortable: true },
        { type: "data", dataIndex: "currentState", dataType: "string", sortable: true },
        {
            type: "actions",
            width: 400,
            components: [OpenModalButton],
        },
    ];

    return (
        <PageContainer>
            <PageHeader title={"Modules Administration"}>
                <Button onClick={() => setShowForm(true)} Icon={AddIcon}>
                    Add Module
                </Button>
            </PageHeader>
            <WidgetFrame isLoading={isLoading} height={"100%"} Icon={ModuleIcon} title={"Modules"}>
                <DataTable<ModuleWithFramePayload>
                    data={modules}
                    columns={columns}
                    configuration={{ disableDownload: true }}
                    sortConfig={dataTableSortConfig}
                    searchInput={dataTableSearchInput}
                />
            </WidgetFrame>

            <Modal isOpen={showForm} setOpen={openModal}>
                <AddModuleForm
                    editData={editData}
                    handleSubmit={editData ? handleUpdate : handleCreate}
                    error={mutationError?.data?.message}
                />
                {(isCreateLoading || isUpdateLoading) && (
                    <LoaderOverlap>
                        <Loader size={"4rem"} />
                    </LoaderOverlap>
                )}
            </Modal>
        </PageContainer>
    );
};
