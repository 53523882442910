import { ComponentType } from "react";

export type RowIndex = string | number | symbol;
export type Row = Record<RowIndex, any>;

export type CellWrapperProps<T extends Row> = { row: T; value: string | undefined };
export type CellWrapperComponentType<T extends Row> = ComponentType<CellWrapperProps<T>>;

export type ColumnDataSortableTypes = "string" | "number" | "boolean" | "date";
export type ColumnDataAllTypes = ColumnDataSortableTypes | "object";
export type ColumnAlign = "right" | "left";
export type ColumnFontStyle = "default" | "mono";

export type ColumnType = "data" | "actions";

export type ColumnBase<T extends Row> = {
    type: ColumnType;
    align?: ColumnAlign;
    width?: number;
    minWidth?: number;
    /**
     * Allows wrapping the cell content in a custom component
     *
     */
    CellWrapper?: CellWrapperComponentType<T>;
};

export type ColumnDataBase<T extends Row, TKey extends keyof T = any> = ColumnBase<T> & {
    type: "data";
    dataIndex: keyof T;
    fontStyle?: ColumnFontStyle;
    label?: string;
    tooltip?: string;
    /**
     * Transforms the cell value
     *
     */
    valueTransform?: (value: T[TKey]) => any;
};

export type ColumnDataNotSortable<T extends Row> = ColumnDataBase<T> & {
    dataType: ColumnDataAllTypes;
    sortable: false;
};

export type ColumnDataSortable<T extends Row> = ColumnDataBase<T> & {
    dataType: ColumnDataSortableTypes;
    sortable: true;
};

export type ColumnData<T extends Row> = ColumnDataNotSortable<T> | ColumnDataSortable<T>;

export type ColumnActions<T extends Row> = ColumnBase<T> & {
    type: "actions";
    components?: ColumnActionComponent<T>[];
};

export type ColumnActionComponentProps<T extends Row> = {
    row: T;
    className?: string;
};
export type ColumnActionComponent<T extends Row> = React.ElementType<ColumnActionComponentProps<T>>;

export type Column<T extends Row> = ColumnData<T> | ColumnActions<T>;

export type AnyColumn = Column<any>;

export const isColumnData = <T extends Row>(column: Column<T>): column is ColumnData<T> => {
    return (column as ColumnData<T>).type === "data";
};

export const isColumnActions = <T extends Row>(column: Column<T>): column is ColumnActions<T> => {
    return (column as ColumnActions<T>).type === "actions";
};

export type CompareFn = <T extends Row, TKey extends keyof T>(a: T[TKey], b: T[TKey]) => number;

export interface SortConfig<T extends Row> {
    dataIndex: keyof T;
    descending: boolean;
}
