import { format } from "date-fns";

export const downloadBlob = (fileNameWithExtension: string, data: Blob) => {
    const downloadUrl = URL.createObjectURL(data);

    const tempEl = document.createElement("a");
    document.body.appendChild(tempEl);
    tempEl.setAttribute("style", "display: none");
    tempEl.href = downloadUrl;
    tempEl.download = fileNameWithExtension;
    tempEl.click();
};

const textToBlob = (data: string) => {
    return new Blob([data]);
};

export const downloadCSV = (fileName: string, data: any[][]) => {
    const content = data.map((row) => row.join(";")).join("\n");
    const blob = textToBlob(content);
    downloadBlob(`${fileName}.csv`, blob);
};

interface TimeRange {
    dateTimeFrom?: Date | string | null;
    dateTimeTo?: Date | string | null;
}

export const toFileNameWithDates = (baseName: string, timeRange: TimeRange) => {
    const { dateTimeFrom, dateTimeTo } = timeRange;

    const addDate = (fileName: string, dateTime?: Date | string | null) => {
        if (!dateTime) return fileName;
        const date = typeof dateTime === "string" ? new Date(dateTime) : dateTime;
        const dateString = format(date, "yyyy-MM-dd");
        return `${fileName}_${dateString}`;
    };

    let fileName = baseName;
    fileName = addDate(fileName, dateTimeFrom);
    fileName = addDate(fileName, dateTimeTo);
    return fileName;
};
