import { Button } from "components/common/Button";
import { ModalActions } from "components/common/ModalActions";
import { ModalContent } from "components/common/ModalContent";
import { ModalTitle } from "components/common/ModalTitle";
import { DateInput } from "components/form/DateInput";
import { DropDownInput } from "components/form/DropDownInput";
import { ErrorPanel } from "components/form/ErrorPanel";
import { CheckIcon } from "components/icons/Icons";
import { Form, Formik } from "formik";
import React from "react";
import {
    DeviceInstallationCreateRequest,
    DeviceInstallationForCustomerPayload,
} from "store/services/device-installation-service";
import { DevicePayload } from "store/services/device-service";
import styled from "styled-components";
import * as Yup from "yup";

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const DeviceIdStyled = styled.span`
    font-weight: 700;
`;

const DropDownWrapper = styled.div<{ $hideInput: boolean }>`
    display: ${(props) => (props.$hideInput ? "none" : "block")};
`;

export type FormData = Pick<DeviceInstallationCreateRequest, "from"> & { deviceId: string[] };
export type FormSubmitData = Pick<DeviceInstallationCreateRequest, "deviceId" | "from">;

interface AddDeviceInstallationFormProps {
    handleSubmit: (values: FormSubmitData) => void;
    editData: DeviceInstallationForCustomerPayload | null;
    dropDownData: DevicePayload[];
    error?: string;
}

const validationSchema = Yup.object().shape({
    deviceId: Yup.array().of(Yup.string()).min(1, "Field is required"),
    from: Yup.date().required("Field is required"),
});

const baseInitialValues: FormData = {
    deviceId: [],
    from: new Date(),
};

export const InstallDeviceForm: React.VFC<AddDeviceInstallationFormProps> = ({
    handleSubmit,
    editData,
    dropDownData,
    error,
}) => {
    let initialValues = baseInitialValues;

    if (editData) {
        initialValues = {
            ...baseInitialValues,
            deviceId: [editData.deviceId],
            from: editData.from ? new Date(editData.from) : null,
        };
    }

    const handleFormSubmit = (values: FormData) => {
        const transformedValues = {
            ...values,
            deviceId: values.deviceId?.[0],
        };
        handleSubmit(transformedValues);
    };

    const content = editData ? (
        <p>
            You are changing the installation date of the following device:
            <DeviceIdStyled>{editData.deviceId}</DeviceIdStyled>
        </p>
    ) : (
        <p>You are about to set the installation date of a new device</p>
    );

    return (
        <Formik<Omit<FormData, "deviceId"> & { deviceId: string[] }>
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => {
                return (
                    <FormStyled>
                        <ModalTitle>Machine installation</ModalTitle>
                        <ModalContent>{content}</ModalContent>
                        <DropDownWrapper $hideInput={!!editData}>
                            <DropDownInput
                                name={"deviceId"}
                                label={"Device"}
                                mode={"simpleSelect"}
                                dropDownData={dropDownData.map((device) => ({
                                    ...device,
                                    value: device.id,
                                    label: device.id,
                                }))}
                                required
                            />
                        </DropDownWrapper>
                        <DateInput name={"from"} showTime required />
                        <ErrorPanel>{error}</ErrorPanel>
                        <ModalActions>
                            <Button type={"submit"} disabled={isSubmitting} variant={"primary"} Icon={CheckIcon}>
                                Submit
                            </Button>
                        </ModalActions>
                    </FormStyled>
                );
            }}
        </Formik>
    );
};
