import { IconComponent } from "components/icons/Icons";
import styled from "styled-components";
import { theme } from "styles/theme";

interface Props {
    Icon: IconComponent;
    value: string;
}

const DashboardPickerLabelStyled = styled.label`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    color: ${theme.color.gray[700]};

    & > svg {
        margin-right: 0.5rem;
        color: ${theme.color.gray[500]};
        flex-shrink: 0;
    }
`;

export const DashboardPickerLabel = (props: Props) => {
    return (
        <DashboardPickerLabelStyled title={"Applied to all dashboard elements"}>
            <props.Icon size={"16px"} />
            {props.value}
        </DashboardPickerLabelStyled>
    );
};
