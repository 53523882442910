import { CustomerPayload } from "store/services/customer-service";
import { rootApi } from "store/services/root-api";
import { toQueryParams } from "store/services/utils";

export interface DeviceInstallationPayload {
    id: number;
    from: string | null;
    to: string | null;
    deviceId: string;
    customer: CustomerPayload;
}

export interface DeviceInstallationForCustomerPayload {
    id: number;
    from: string | null;
    to: string | null;
    deviceId: string;
}

export interface DeviceInstallationDates {
    from: string | null;
    to: string | null;
}

export interface DeviceInstallationRequest {
    from: Date | null;
    to: Date | null;
    deviceId: string;
}

export type DeviceInstallationCreateRequest = Omit<DeviceInstallationRequest, "to"> & { customerId: number };
export type DeviceInstallationUpdateRequest = { id: number; data: Partial<DeviceInstallationRequest> };

export interface GetOneDeviceInstallationForCustomerRequest {
    customerId: number;
    onlyActive?: boolean;
    onlyInactive?: boolean;
}

export interface UpdateDeviceInstallationDatesRequest {
    id: number;
    installationRequest: DeviceInstallationRequest;
}

const BASE_PATH = "device-installations";
const CACHE_TYPE = "DeviceInstallation";
const CACHE_ID = "LIST";

const apiWithTag = rootApi.enhanceEndpoints({ addTagTypes: [CACHE_TYPE] });
export const deviceInstallationApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getInstallationsForCustomer: builder.query<
            DeviceInstallationForCustomerPayload[],
            GetOneDeviceInstallationForCustomerRequest
        >({
            query: ({ customerId, ...params }) => ({
                url: `${BASE_PATH}/customer/${customerId}`,
                params: toQueryParams(params),
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: CACHE_TYPE, id }) as const),
                          { type: CACHE_TYPE, id: CACHE_ID },
                      ]
                    : [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        getInstallationsForDevice: builder.query<DeviceInstallationPayload[], string>({
            query: (id) => `${BASE_PATH}/device/${id}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: CACHE_TYPE, id }) as const),
                          { type: CACHE_TYPE, id: CACHE_ID },
                      ]
                    : [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        createInstallation: builder.mutation<void, DeviceInstallationCreateRequest>({
            query: (body) => ({
                url: `${BASE_PATH}`,
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        updateInstallation: builder.mutation<void, DeviceInstallationUpdateRequest>({
            query: ({ id, data }) => ({
                url: `${BASE_PATH}/${id}`,
                method: "PATCH",

                body: data,
            }),
            invalidatesTags: [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        updateInstallationDates: builder.mutation<void, UpdateDeviceInstallationDatesRequest>({
            query: ({ installationRequest, id }) => ({
                url: `${BASE_PATH}/${id}`,
                method: "PATCH",
                body: installationRequest,
            }),
            invalidatesTags: [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        deleteInstallation: builder.mutation<void, number>({
            query: (id) => ({
                url: `${BASE_PATH}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
    }),
});

export const {
    useGetInstallationsForDeviceQuery,
    useGetInstallationsForCustomerQuery,
    useCreateInstallationMutation,
    useUpdateInstallationMutation,
    useUpdateInstallationDatesMutation,
    useDeleteInstallationMutation,
} = deviceInstallationApi;
