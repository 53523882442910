import { Button } from "components/common/Button";
import { ModalActions } from "components/common/ModalActions";
import { ModalTitle } from "components/common/ModalTitle";
import { DateInput } from "components/form/DateInput";
import { ErrorPanel } from "components/form/ErrorPanel";
import { CheckIcon } from "components/icons/Icons";
import { Form, Formik } from "formik";
import React from "react";
import { DeviceInstallationDates, DeviceInstallationRequest } from "store/services/device-installation-service";
import styled from "styled-components";
import * as Yup from "yup";

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

interface AddDeviceInstallationFormProps {
    handleSubmit: (values: FormData) => void;
    editData: DeviceInstallationDates | null;
    error?: string;
}

export type FormData = Omit<DeviceInstallationRequest, "deviceId">;

const baseInitialValues: FormData = {
    from: null,
    to: new Date(),
};

const validationSchema = Yup.object().shape({
    from: Yup.date().required("Date is required"),
    to: Yup.date().min(Yup.ref("from"), "Uninstallation date must come after installation date"),
});

export const AddDeviceInstallationForm: React.VFC<AddDeviceInstallationFormProps> = ({
    handleSubmit,
    editData,
    error,
}) => {
    let initialValues = baseInitialValues;

    if (editData) {
        const { from, to } = editData;
        const fromDate = from ? new Date(from) : null;
        const toDate = to ? new Date(to) : null;
        let initialToDate: Date | null = toDate ?? new Date();
        if (fromDate && fromDate > initialToDate) {
            initialToDate = fromDate;
        }

        initialValues = {
            ...baseInitialValues,
            from: fromDate,
            to: initialToDate,
        };
    }

    return (
        <Formik<FormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, values }) => {
                return (
                    <FormStyled>
                        <ModalTitle>
                            {editData ? "Edit machine installation" : "Create a new machine installation"}
                        </ModalTitle>
                        <DateInput name={"from"} showTime required />
                        <DateInput name={"to"} showTime minDate={values.from} />
                        <ErrorPanel>{error}</ErrorPanel>
                        <ModalActions>
                            <Button type={"submit"} disabled={isSubmitting} variant={"primary"} Icon={CheckIcon}>
                                Submit
                            </Button>
                        </ModalActions>
                    </FormStyled>
                );
            }}
        </Formik>
    );
};
