/**
 * Filters out empty query parameters
 *
 * @param {Record<string, any>} obj
 * @return {*}
 */
export const toQueryParams = (obj: Record<string, any>) => {
    const params: Record<string, any> = {};
    for (const [key, value] of Object.entries(obj)) {
        if (value === null || value === undefined) continue;
        if (Array.isArray(value) && value.length === 0) continue;
        params[key] = value;
    }
    return params;
};
