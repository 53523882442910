import React from "react";
import { useCurrentUserRole } from "store";
import { Role } from "types/authTypes";
import { Operation, resolveUserAccess } from "utils/roles-utils";

interface WithRoleProps {
    accessedRole: Role;
    operation: Operation;
    children: React.ReactNode;
}

export const WithAccessPermission: React.FC<WithRoleProps> = ({ children, accessedRole, operation }) => {
    const userRole = useCurrentUserRole();
    if (!resolveUserAccess(userRole, accessedRole, operation)) {
        return null;
    }
    return <>{children}</>;
};
