import { Content } from "components/layout/Content";
import { Header } from "components/layout/Header";
import { Sidebar } from "components/layout/Sidebar";
import { HEADER_HEIGHT } from "components/layout/layoutConstants";
import { useSidebarCollapse } from "components/layout/useSidebarCollapse";
import React from "react";
import styled from "styled-components";

interface Props {
    children: React.ReactNode;
}

const PageLayout = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ContentLayout = styled.div`
    display: flex;
    flex-grow: 1;
    max-height: calc(100% - ${HEADER_HEIGHT});
    overflow: hidden;
`;

export const Layout: React.FC<Props> = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useSidebarCollapse();

    return (
        <PageLayout>
            <Header />
            <ContentLayout>
                <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                <Content>{children}</Content>
            </ContentLayout>
        </PageLayout>
    );
};
