import { CustomerPayload } from "store/services/customer-service";
import { DeviceInstallationPayload } from "store/services/device-installation-service";

type WithKeyAccountType = CustomerPayload;
type WithCustomerType = DeviceInstallationPayload;
type WithNameType = { name: string };

export const withKeyAccount = (item: WithKeyAccountType | any): item is WithKeyAccountType => {
    return (item as WithKeyAccountType).keyAccount !== undefined;
};

export const withCustomer = (item: WithCustomerType | any): item is WithCustomerType => {
    return (item as WithCustomerType).customer !== undefined && (item as WithCustomerType).customer !== null;
};

export const withName = (item: WithNameType | any): item is WithNameType => {
    return (item as WithNameType).name !== undefined;
};
