import { Button } from "components/common/Button";
import { ModalActions } from "components/common/ModalActions";
import { ModalTitle } from "components/common/ModalTitle";
import { ErrorPanel } from "components/form/ErrorPanel";
import { TextInput } from "components/form/TextInput";
import { CheckIcon } from "components/icons/Icons";
import { Form, Formik } from "formik";
import React from "react";
import { KeyAccountRequest } from "store";
import styled from "styled-components";
import * as Yup from "yup";

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    keyAccountNumber: Yup.string().required(),
    city: Yup.string().nullable(),
    postcode: Yup.string().nullable(),
    street: Yup.string().nullable(),
    telephone: Yup.string().nullable(),
});

const baseInitialValues: KeyAccountRequest = {
    name: "",
    keyAccountNumber: "",
    city: "",
    postcode: "",
    street: "",
    telephone: "",
};

interface AddKeyAccountFormProps {
    handleSubmit: (values: KeyAccountRequest) => Promise<void>;
    editData: KeyAccountRequest | null;
    error?: string;
}

export const AddKeyAccountForm: React.VFC<AddKeyAccountFormProps> = ({ handleSubmit, editData, error }) => {
    let initialValues = baseInitialValues;

    if (editData) {
        const { name, keyAccountNumber, city, postcode, street, telephone } = editData;
        initialValues = { ...initialValues, name, keyAccountNumber, city, postcode, street, telephone };
    }

    return (
        <Formik<KeyAccountRequest>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => {
                return (
                    <FormStyled>
                        <ModalTitle>{editData ? "Edit key account" : "Create a new key account"}</ModalTitle>
                        <TextInput name={"name"} required />
                        <TextInput name={"keyAccountNumber"} required />
                        <TextInput name={"city"} />
                        <TextInput name={"postcode"} />
                        <TextInput name={"street"} />
                        <TextInput name={"telephone"} />

                        <ErrorPanel>{error}</ErrorPanel>
                        <ModalActions>
                            <Button type={"submit"} disabled={isSubmitting} variant={"primary"} Icon={CheckIcon}>
                                Submit
                            </Button>
                        </ModalActions>
                    </FormStyled>
                );
            }}
        </Formik>
    );
};
