import { Button } from "components/common/Button";
import { Modal } from "components/common/Modal";
import { AddIcon } from "components/icons/Icons";
import { CreateFormModalView } from "pages/ProvisioningPage/ProvisioningTokenGeneration/CreateFormModalView";
import { CreatedTokenModalView } from "pages/ProvisioningPage/ProvisioningTokenGeneration/CreatedTokenModalView";
import React, { useState } from "react";
import { ProvisioningTokenRequest, useCreateProvisioningTokenMutation } from "store/services/provisioning-service";

export const ProvisioningTokenGeneration: React.VFC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [provisioningTokenGenerationError, setProvisioningTokenGenerationError] = useState<undefined | string>(
        undefined
    );

    const [
        createProvisioningToken,
        {
            data: createdProvisioningToken,
            isLoading: isLoadingCreateProvisioningToken,
            reset: resetProvisioningTokenMutation,
        },
    ] = useCreateProvisioningTokenMutation();

    const handleCreateProvisioningToken = async (formData: ProvisioningTokenRequest) => {
        try {
            setProvisioningTokenGenerationError(undefined);
            await createProvisioningToken(formData).unwrap();
        } catch (err: any) {
            setProvisioningTokenGenerationError(
                err?.data?.errors
                    .map(
                        ({ propertyName, detail }: { propertyName: string; detail: string }) =>
                            `${propertyName} - ${detail}`
                    )
                    .join("\n")
            );
        }
    };

    return (
        <>
            <Button onClick={() => setIsModalOpen(true)} Icon={AddIcon}>
                Create Provisioning Token
            </Button>
            <Modal isOpen={isModalOpen} setOpen={setIsModalOpen}>
                {createdProvisioningToken ? (
                    <CreatedTokenModalView
                        createdProvisioningToken={createdProvisioningToken}
                        unsetProvisioningToken={resetProvisioningTokenMutation}
                    />
                ) : (
                    <CreateFormModalView
                        handleSubmit={handleCreateProvisioningToken}
                        error={provisioningTokenGenerationError}
                        isLoading={isLoadingCreateProvisioningToken}
                    />
                )}
            </Modal>
        </>
    );
};
