import { rootApi } from "store/services/root-api";

export enum ModuleStateType {
    Active = "active",
    Blocked = "blocked",
    Repair = "repair",
    Spare = "spare",
    Lost = "lost",
}

export enum ModuleType {
    DisplayModule = "display-module",
    WaterModule = "water-module",
    SteamModule = "steam-module",
    BrewLeftModule = "brew-left-module",
    BrewRightModule = "brew-right-module",
    MilkModule = "milk-module",
    BrewModule = "brew-module",
    FrameSolo = "frame-solo",
    WaterSupplyModule = "water-supply-module",
    GrinderModule = "grinder-module",
    MiracleFrame = "miracle-frame",
    "7DISPLAY" = "7-display",
    MiracleBase = "miracle-base",
    MiracleCore = "miracle-core",
}

export interface ModulePayload {
    serialNumber: string;
    type: ModuleType;
    currentState: ModuleStateType;
}

export interface ModuleWithFramePayload extends ModulePayload {
    id: string;
    frame: string;
}

export interface ModuleRequest {
    serialNumber: string;
    type: ModuleType;
}

export interface ModuleUpdateRequest {
    serialNumber: string;
    module: ModuleRequest;
}

const BASE_PATH = "modules";
const CACHE_TYPE = "Module";
const CACHE_ID = "LIST";

const apiWithTag = rootApi.enhanceEndpoints({ addTagTypes: [CACHE_TYPE] });
export const moduleApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getModules: builder.query<ModuleWithFramePayload[], void>({
            query: () => BASE_PATH,
            // FIXME: This is not ideal, DataTable requires records with "id", records with
            //  different primary keys are not supported, so currently this arbitrary transform is required
            transformResponse: (response: Omit<ModuleWithFramePayload, "id">[]): ModuleWithFramePayload[] => {
                return response.map((dataItem) => ({
                    ...dataItem,
                    id: dataItem.serialNumber,
                }));
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ serialNumber }) => ({ type: CACHE_TYPE, serialNumber }) as const),
                          { type: CACHE_TYPE, id: CACHE_ID },
                      ]
                    : [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        addModule: builder.mutation<ModulePayload, ModuleRequest>({
            query: (body) => ({
                url: BASE_PATH,
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        updateModule: builder.mutation<ModuleWithFramePayload, ModuleUpdateRequest>({
            query: ({ serialNumber, module }) => ({
                url: `${BASE_PATH}/${serialNumber}`,
                method: "PUT",
                body: module,
            }),
            invalidatesTags: [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
    }),
});

export const { useGetModulesQuery, useAddModuleMutation, useUpdateModuleMutation } = moduleApi;
