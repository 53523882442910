import { ErrorText } from "components/form/ErrorText";
import { FieldMetaProps } from "formik";
import { HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

const InputWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex-direction: column;

    ${({ disabled }) => disabled && `opacity: 0.75;`}
`;

const LabelText = styled.label`
    margin-bottom: 0.5rem;

    color: ${theme.color.gray[700]};

    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
    label: string;
    required?: boolean;
    disabled?: boolean;
    meta: FieldMetaProps<any>;
    children: ReactNode;
}

const MandatoryAsteriskWrapper = styled.span`
    margin-left: 0.25rem;
    color: ${({ theme }) => theme.color.error[500]};
`;

const MandatoryAsterisk = () => {
    return <MandatoryAsteriskWrapper>*</MandatoryAsteriskWrapper>;
};

export function FormInputWrapper({ label, required, children, meta, disabled, ...props }: Props) {
    return (
        <InputWrapper {...props} disabled={disabled}>
            <LabelText>
                {label}
                {required && <MandatoryAsterisk />}
            </LabelText>
            {children}
            <ErrorText {...meta} />
        </InputWrapper>
    );
}

export type { Props as FormInputWrapperProps };
