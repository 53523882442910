import { tableCellBaseCSS, tableCellInnerBaseCSS, TableCellOptions } from "components/common/table/Table/styles";
import { readCellValue } from "components/common/table/Table/utils";
import {
    AnyColumn,
    ColumnActionComponent,
    ColumnAlign,
    ColumnFontStyle,
    isColumnActions,
    isColumnData,
    Row,
} from "components/common/table/types";
import styled from "styled-components";

interface TableCellStyledProps extends TableCellOptions {
    fontStyle?: ColumnFontStyle;
    align?: ColumnAlign;
}

interface Props {
    column: AnyColumn;
    row: Row;
    onClick?: () => void;
}

const TableCellStyled = styled.td<TableCellStyledProps>`
    ${tableCellBaseCSS};

    font-family: ${({ fontStyle }) => (fontStyle === "mono" ? "monospace" : "inherit")};
    text-align: ${({ align }) => (align === "right" ? "right" : "left")};
`;

const TableActionsWrapper = styled.span`
    display: flex;
    gap: 0.25rem;

    justify-content: center;
`;

const TableCellInnerStyled = styled.div`
    ${tableCellInnerBaseCSS};
`;

const TableCell = (props: Props) => {
    const { column, row } = props;
    const CellWrapper = column.CellWrapper;

    const fontStyle = isColumnData(column) ? column.fontStyle : undefined;
    const value = isColumnData(column) ? readCellValue(column, row[column.dataIndex]) : undefined;

    const columnElementMapping = {
        data: () => (CellWrapper ? <CellWrapper row={row} value={value} /> : <>{value}</>),
        actions: (column: AnyColumn) => {
            if (!isColumnActions(column)) return undefined;

            const ActionsWrapper = CellWrapper ?? TableActionsWrapper;

            return (
                <ActionsWrapper row={row} value={value}>
                    {column.components?.map((Component: ColumnActionComponent<Row>, index) => (
                        <Component row={row} key={index} />
                    ))}
                </ActionsWrapper>
            );
        },
    };

    return (
        <TableCellStyled
            align={column.align ?? "left"}
            fontStyle={fontStyle}
            title={value}
            width={column.width}
            minWidth={column.minWidth}
            onClick={props.onClick}
        >
            <TableCellInnerStyled>{columnElementMapping[column.type](column)}</TableCellInnerStyled>
        </TableCellStyled>
    );
};

export { TableCell };
