import { format } from "date-fns";

/**
 * Converts date to ISO string with normalized timezone (UTC)
 *
 * @param {Date} date
 * @return {*}  {string}
 */
export const dateToISOStringUTC = (date: Date): string => format(date, "yyyy-MM-dd'T'HH:mm:ss");

export const DATE_FORMAT = "dd/MM/yyyy";
export const DATE_WITH_TIME_FORMAT = "dd/MM/yyyy HH:mm:ss";
