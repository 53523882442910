import { LogoutButton } from "components/layout/LogoutButton";
import { HEADER_HEIGHT } from "components/layout/layoutConstants";
import { Logo } from "components/logo/Logo";
import React from "react";
import styled from "styled-components";
import { MEDIA_QUERY } from "styles/styleUtils";
import { theme } from "styles/theme";

const HeaderWrap = styled.header`
    flex: 0 0 ${HEADER_HEIGHT};
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0 2rem 0 1.5rem;

    background-color: ${theme.color.cupcinoBlack};
`;

const LogoWrapper = styled.div`
    flex: 1 0 auto;
`;

const LogoStyled = styled(Logo)`
    height: 3.25rem;

    ${MEDIA_QUERY.tabletAndSmaller} {
        height: 3.25rem;
    }
`;

const ActionsWrapper = styled.div`
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-left: auto;
`;

export const Header: React.VFC = () => {
    return (
        <HeaderWrap>
            <LogoWrapper>
                <LogoStyled />
            </LogoWrapper>
            <ActionsWrapper>
                <LogoutButton />
            </ActionsWrapper>
        </HeaderWrap>
    );
};
