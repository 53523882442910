import { Button } from "components/common/Button";
import { ButtonGroup } from "components/common/ButtonGroup";
import { ModalActions } from "components/common/ModalActions";
import { ModalTitle } from "components/common/ModalTitle";
import { ErrorPanel } from "components/form/ErrorPanel";
import { FormInfoText } from "components/form/FormInfoText";
import { TextInput } from "components/form/TextInput";
import { AddIcon } from "components/icons/Icons";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { ProvisioningTokenRequest } from "store";
import styled from "styled-components";
import * as Yup from "yup";

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

interface CreateProvisioningTokenFormProps {
    handleSubmit: (values: FormData) => void;
    error?: string;
}

export type FormData = ProvisioningTokenRequest;

const limitedLifetimeInitialValues: FormData = {
    type: "limitedLifetime",
    lifetime: "",
};

const infiniteLifetimeInitialValues: FormData = {
    type: "infiniteLifetime",
};

const validationSchemaLimitedLifetime = Yup.object().shape({
    lifetime: Yup.string().required("Lifetime is required"),
});

const validationSchemaInfiniteLifetime = Yup.object().shape({});

type FormConfiguration = {
    initialValues: FormData;
    validationSchema: Yup.AnyObjectSchema;
};

type LifetimeType = Pick<ProvisioningTokenRequest, "type">["type"];

const lifetimeOptions: Record<LifetimeType, FormConfiguration> = {
    limitedLifetime: {
        initialValues: limitedLifetimeInitialValues,
        validationSchema: validationSchemaLimitedLifetime,
    },
    infiniteLifetime: {
        initialValues: infiniteLifetimeInitialValues,
        validationSchema: validationSchemaInfiniteLifetime,
    },
};

export const CreateProvisioningTokenForm: React.VFC<CreateProvisioningTokenFormProps> = ({ handleSubmit, error }) => {
    const [selectedTokenLifetimeOption, setSelectedTokenLifetimeOption] = useState<LifetimeType>("limitedLifetime");

    const isLifetimeLimited = selectedTokenLifetimeOption === "limitedLifetime";

    return (
        <Formik<FormData>
            initialValues={lifetimeOptions[selectedTokenLifetimeOption].initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={lifetimeOptions[selectedTokenLifetimeOption].validationSchema}
        >
            {({ isSubmitting }) => {
                const getButtonVariantInGroup = (option: LifetimeType) => {
                    return selectedTokenLifetimeOption === option ? "primary" : undefined;
                };

                return (
                    <FormStyled>
                        <ModalTitle>{"Create a new provisioning token"}</ModalTitle>
                        <ButtonGroup>
                            <Button
                                onClick={() => setSelectedTokenLifetimeOption("limitedLifetime")}
                                variant={getButtonVariantInGroup("limitedLifetime")}
                            >
                                Limited lifetime
                            </Button>
                            <Button
                                onClick={() => setSelectedTokenLifetimeOption("infiniteLifetime")}
                                variant={getButtonVariantInGroup("infiniteLifetime")}
                            >
                                Infinite lifetime
                            </Button>
                        </ButtonGroup>
                        {isLifetimeLimited && (
                            <>
                                <TextInput name="lifetime" required />
                                <FormInfoText>
                                    Lifetime is specified like <code>DD.HH:mm:ss</code>, e.g. <code>7.00:00:00</code>{" "}
                                    for one week or <code>1:00:00</code> for one hour.
                                </FormInfoText>
                            </>
                        )}
                        <FormInfoText>
                            Lifetime applies to registration tokens created with the given provisioning token, not the
                            provisioning token itself. The lifetime defines the value for how long the device can be
                            offline in order to be able to send the data to the Data Platform again.
                        </FormInfoText>
                        <ErrorPanel>{error}</ErrorPanel>
                        <ModalActions>
                            <Button type={"submit"} disabled={isSubmitting} variant={"primary"} Icon={AddIcon}>
                                Create
                            </Button>
                        </ModalActions>
                    </FormStyled>
                );
            }}
        </Formik>
    );
};
