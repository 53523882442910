import styled from "styled-components";
import { theme } from "styles/theme";

export const TimePickerRangeStyled = styled.div`
    display: flex;
    width: 20rem;

    box-shadow: ${theme.boxShadow.sm};

    & > div input {
        box-shadow: none;
    }

    & > div:first-child input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    & > div:last-child input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

export const TimePickerRangeSeparator = styled.div`
    width: 3rem;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${theme.color.gray[500]};
    background-color: ${theme.color.gray[100]};
    border-top: 1px solid ${theme.color.gray[300]};
    border-bottom: 1px solid ${theme.color.gray[300]};
`;
