import React from "react";
import styled, { css } from "styled-components";
import { theme } from "styles/theme";

export type IconButtonVariant = "primary" | "danger";

export interface ButtonProps {
    onClick?: () => void;
    type?: "button" | "submit" | "reset";
    className?: string;
    title?: string;
    isLoading?: boolean;
    disabled?: boolean;
    variant?: IconButtonVariant;
    Icon?: any;
}

export type StyledButtonProps = Pick<ButtonProps, "variant">;

const ButtonVariantMixin = (variant?: IconButtonVariant) => {
    switch (variant) {
        case "danger":
            return css`
                border: 1px solid ${theme.color.error[300]};
                background-color: ${theme.color.error[50]};

                :hover:not([disabled]) {
                    background-color: ${theme.color.error[100]};
                }

                :disabled {
                    color: ${theme.color.error[500]};
                }

                svg {
                    color: ${theme.color.error[700]};
                }
            `;
        default:
            return css`
                border: 1px solid ${theme.color.gray[300]};
                background-color: ${theme.color.white};

                :hover:not([disabled]) {
                    background-color: ${theme.color.gray[100]};
                }

                :disabled {
                    color: ${theme.color.gray[500]};
                }

                svg {
                    color: ${theme.color.gray[700]};
                }
            `;
    }
};

const IconButtonStyled = styled.button<StyledButtonProps>`
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;

    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.025rem;

    box-shadow: ${theme.boxShadow.sm};
    border-radius: ${theme.borderRadius.md};

    ${(props) => {
        return ButtonVariantMixin(props.variant);
    }}

    transition: background-color 0.15s ease-out;

    :disabled {
        cursor: not-allowed;
    }
`;

export const IconButton: React.VFC<ButtonProps> = (props) => {
    const { onClick, disabled, title, variant, Icon } = props;

    return (
        <IconButtonStyled type={"button"} onClick={onClick} disabled={disabled} title={title} variant={variant}>
            <Icon size={"16px"} />
        </IconButtonStyled>
    );
};
