import React from "react";
import { Navigate } from "react-router-dom";
import { routes } from "routing/routes";
import { useCurrentUserRole } from "store";
import { Role } from "types/authTypes";
import { hasRole } from "utils/auth-utils";

interface RoleGuardProps {
    allowedRoles: Role[];
    children: React.ReactNode;
}

export const RoleGuard: React.FC<RoleGuardProps> = ({ children, allowedRoles }) => {
    const role = useCurrentUserRole();
    if (!hasRole(role, allowedRoles)) {
        return <Navigate to={routes.notFound.path} />;
    }
    return <>{children}</>;
};
