import { tableCellBaseCSS, tableCellInnerBaseCSS, TableCellOptions } from "components/common/DashboardTable/styles";
import { Column, ColumnDataType, ColumnFontStyle } from "components/common/DashboardTable/types";
import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

interface TableCellStyledProps extends TableCellOptions {
    dataType: ColumnDataType;
    fontStyle?: ColumnFontStyle;
}

const TableCellStyled = styled.td<TableCellStyledProps>`
    ${tableCellBaseCSS};

    border-bottom: 1px solid ${theme.color.gray[200]};

    font-family: ${({ fontStyle }) => (fontStyle === "mono" ? "monospace" : "inherit")};
    text-align: ${({ dataType }) => (dataType === "number" ? "right" : "left")};
`;

const TableCellInnerStyled = styled.div`
    ${tableCellInnerBaseCSS}
`;

interface Props {
    column: Column<any, any>;
    row: Record<string, any>;
}

const parseCellValue = (column: Column<any, any>, value: any): string => {
    if (value === null || value === undefined || value === "") return "";

    // Naive parsing, implement more robust one if needed
    let parsedValue: any = value;
    if (column.dataType === "date") {
        parsedValue = new Date(value);
    }

    let parsedValueString = parsedValue.toLocaleString();
    if (column.valueTransform) parsedValueString = column.valueTransform(value);

    return parsedValueString;
};

const TableCell: React.FC<Props> = (props) => {
    const { column, row } = props;
    const { dataIndex, CellWrapper } = column;

    const value = parseCellValue(column, row[dataIndex]);

    return (
        <TableCellStyled
            dataType={column.dataType}
            fontStyle={column.fontStyle}
            title={value}
            width={column.width}
            minWidth={column.minWidth}
        >
            <TableCellInnerStyled>{CellWrapper ? <CellWrapper value={value} row={row} /> : value}</TableCellInnerStyled>
        </TableCellStyled>
    );
};

TableCell.displayName = "TableCell";

export { TableCell };
