import { Loader } from "../Loader";

import { CaretContent } from "./CaretContent";
import { CaretContentValue } from "./CaretContentValue";
import { CaretContentValueSeparator } from "./CaretContentValueSeparator";
import { CaretIcon } from "./CaretIcon";
import { CaretIndicator } from "./CaretIndicator";
import type { CaretIconProps, Variant } from "./types";

import React from "react";
import styled from "styled-components";
import { dashboardCardCSS, DashboardCardOptions } from "styles/dashboard";

const CaretStyled = styled.div<DashboardCardOptions>`
    ${dashboardCardCSS}

    position: relative;
    display: flex;
    padding-left: 0.5rem;
`;

const LoaderStyled = styled.div`
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
`;

interface Props extends DashboardCardOptions {
    children: React.ReactNode;
    title?: string;
    Icon?: React.ComponentType<CaretIconProps>;
    variant?: Variant;
    isLoading?: boolean;
    tooltip?: string;
}

const Caret: React.FC<Props> = (props) => {
    const { Icon, variant, isLoading } = props;

    return (
        <CaretStyled colSpan={props.colSpan} rowSpan={props.rowSpan} title={props.tooltip}>
            <CaretIndicator variant={variant} />
            {isLoading && (
                <LoaderStyled>
                    <Loader size={"3rem"} />
                </LoaderStyled>
            )}
            {!isLoading && Icon && <CaretIcon Icon={Icon} variant={variant} />}
            <CaretContent title={props.title}>{props.isLoading ? "..." : props.children}</CaretContent>
        </CaretStyled>
    );
};

type _Caret = typeof Caret & {
    Value: typeof CaretContentValue;
    ValueSeparator: typeof CaretContentValueSeparator;
};
const _Caret = Caret as _Caret;

_Caret.Value = CaretContentValue;
_Caret.ValueSeparator = CaretContentValueSeparator;

export { _Caret as Caret, Props as CaretProps, Variant as CaretVariant };
