import { CompareFn } from "components/common/DashboardTable/types";

export const compareString: CompareFn =
    (key: string) =>
    <T extends Record<string, string>>(a: T, b: T): number => {
        const aValue = a[key] ?? "";
        const bValue = b[key] ?? "";
        return aValue.localeCompare(bValue);
    };

export const compareNumber: CompareFn =
    (key: string) =>
    <T extends Record<string, number>>(a: T, b: T): number => {
        return a[key] - b[key];
    };

export const compareDate: CompareFn =
    (key: string) =>
    <T extends Record<string, string>>(a: T, b: T): number => {
        const aValue = new Date(a[key]).getTime();
        const bValue = new Date(b[key]).getTime();
        return aValue - bValue;
    };

export const compareBoolean: CompareFn =
    (key: string) =>
    <T extends Record<string, boolean>>(a: T, b: T): number => {
        const aValue = Number(a[key]);
        const bValue = Number(b[key]);
        return aValue - bValue;
    };
