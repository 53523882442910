import { Button } from "components/common/Button";
import { Loader } from "components/common/Loader";
import { ModalActions } from "components/common/ModalActions";
import { ModalTitle } from "components/common/ModalTitle";
import { DropDownInput } from "components/form/DropDownInput";
import { ErrorPanel } from "components/form/ErrorPanel";
import { TextInput } from "components/form/TextInput";
import { CheckIcon } from "components/icons/Icons";
import { LoaderOverlap } from "components/styled/LoaderOverlap";
import { Form, Formik } from "formik";
import React from "react";
import { KeyAccountPayload, useGetKeyAccountsQuery } from "store";
import { CustomerPayload, CustomerRequest } from "store/services/customer-service";
import styled from "styled-components";
import * as Yup from "yup";

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    customerNumber: Yup.string().required("Number is required"),
    city: Yup.string().required("City is required"),
    // postcode: Yup.string().required("Postcode is required"),
    // street: Yup.string().required("Street is required"),
});

export interface FormData extends Omit<CustomerRequest, "keyAccount"> {
    keyAccount: string[];
}

const baseInitialValues: FormData = {
    name: "",
    customerNumber: "",
    city: "",
    postcode: "",
    street: "",
    telephone: "",
    keyAccount: [],
};

const toKeyAccountData = (dropDownData?: KeyAccountPayload[]) =>
    dropDownData?.map((x) => ({
        id: x.id.toString(),
        value: x.id.toString(),
        label: x.name,
    })) ?? [];

interface AddCustomerFormProps {
    handleSubmit: (values: CustomerRequest) => Promise<void>;
    isSubmitQueryLoading: boolean;
    editData: CustomerPayload | null;
    error?: string;
}

export const AddCustomerForm: React.VFC<AddCustomerFormProps> = ({
    handleSubmit,
    isSubmitQueryLoading,
    editData,
    error,
}) => {
    let initialValues = baseInitialValues;

    // Fetch keyAccounts for dropdown data
    const {
        data: keyAccounts,
        isLoading: isKeyAccountsQueryLoading,
        isFetching: isKeyAccountsQueryFetching,
    } = useGetKeyAccountsQuery();
    const isLoading = isKeyAccountsQueryLoading || isKeyAccountsQueryFetching || isSubmitQueryLoading;

    if (editData) {
        const { name, customerNumber, city, postcode, street, telephone, keyAccount: keyAccountRelation } = editData;
        const { id: keyAccount } = keyAccountRelation;
        initialValues = {
            ...initialValues,
            name,
            customerNumber,
            city,
            postcode,
            street,
            telephone,
            keyAccount: [keyAccount.toString()],
        };
    }

    const validate = (value: any) => {
        let error;
        if (value === 0 || value < 0 || value === null || (Array.isArray(value) && value.length === 0)) {
            error = "You must choose Key Account";
        }
        return error;
    };

    const handleSubmitForm = async (values: FormData) => {
        // The selected key account is stored in an array of max length of 1
        // Extract it if present and convert in into an object attribute needed by the API
        const { keyAccount, ...otherValues } = values;
        const keyAccountId = keyAccount?.[0] ? parseInt(keyAccount[0]) : null;
        const permissions = { keyAccount: keyAccountId ? { id: keyAccountId } : null };

        await handleSubmit({ ...otherValues, ...permissions });
    };

    return (
        <>
            <Formik<FormData>
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmitForm}
            >
                {({ isSubmitting }) => {
                    return (
                        <FormStyled>
                            <ModalTitle>{editData ? "Edit customer" : "Create a new customer"}</ModalTitle>
                            <TextInput name={"name"} required />
                            <TextInput name={"customerNumber"} required />
                            <TextInput name={"city"} required />
                            <TextInput name={"postcode"} />
                            <TextInput name={"street"} />
                            <TextInput name={"telephone"} />

                            <DropDownInput
                                name={"keyAccount"}
                                mode={"simpleSelect"}
                                validate={validate}
                                dropDownData={toKeyAccountData(keyAccounts)}
                                required
                            />

                            <ErrorPanel>{error}</ErrorPanel>
                            <ModalActions>
                                <Button type={"submit"} disabled={isSubmitting} variant={"primary"} Icon={CheckIcon}>
                                    Submit
                                </Button>
                            </ModalActions>
                        </FormStyled>
                    );
                }}
            </Formik>
            {isLoading && (
                <LoaderOverlap>
                    <Loader size={"4rem"} />
                </LoaderOverlap>
            )}
        </>
    );
};
