import { Button } from "components/common/Button";
import { ModalActions } from "components/common/ModalActions";
import { ModalContent } from "components/common/ModalContent";
import { ModalTitle } from "components/common/ModalTitle";
import { ResetIcon } from "components/icons/Icons";
import { Fragment } from "react";
import { ProvisioningTokenPayload } from "store/services/provisioning-service";
import styled from "styled-components";

const ProvisioningTokenDescriptionList = styled.dl`
    dt {
        font-weight: bold;
    }
    dd {
        margin-bottom: 0.5rem;
        margin-left: 1.5rem;
        overflow-wrap: anywhere;
    }
`;

type Props = {
    createdProvisioningToken: ProvisioningTokenPayload;
    unsetProvisioningToken: () => void;
};

export function CreatedTokenModalView({ createdProvisioningToken, unsetProvisioningToken }: Props) {
    return (
        <>
            <ModalTitle>Provisioning Token</ModalTitle>
            <ModalContent>
                <ProvisioningTokenDescriptionList>
                    {Object.entries(createdProvisioningToken).map(([key, value]) => (
                        <Fragment key={key}>
                            <dt>{key}:</dt>
                            <dd>{value.toString()}</dd>
                        </Fragment>
                    ))}
                </ProvisioningTokenDescriptionList>
            </ModalContent>
            <ModalActions>
                <Button onClick={() => unsetProvisioningToken()} Icon={ResetIcon}>
                    Generate new token
                </Button>
            </ModalActions>
        </>
    );
}
