import { css } from "styled-components";

export interface TableCellOptions {
    width?: number;
    minWidth?: number;
}

export const tableCellMinWidth = 64;
export const tableCellBaseCSS = css<TableCellOptions>`
    padding: 0.5rem 1rem;
    min-width: ${({ minWidth }) => `${minWidth ?? tableCellMinWidth}px`};
    width: ${({ width }) => (width ? `${width}px` : undefined)};

    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

    &:first-child {
        padding-left: 0.25rem;
    }

    &:last-child {
        padding-right: 0.25rem;
    }
`;

export const tableCellInnerBaseCSS = css`
    line-height: 1.2;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
