import { Chart, ChartConfiguration, ChartData } from "chart.js";
import { GraphData } from "components/common/Graph/types";
import { theme } from "styles/theme";

export const toBarStackedData = (data: GraphData): ChartData<"bar"> => ({
    ...data,
    datasets: data.datasets.map((dataset, index) => ({
        ...dataset,
        backgroundColor: theme.graphPalette[index][500], // Only one color for each dataset
    })),
});

export const toBarStackedConfig = (data: GraphData): ChartConfiguration<"bar"> => ({
    type: "bar",
    data: toBarStackedData(data),
    options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: { duration: 500 },
        scales: {
            x: { stacked: true, grid: { color: "rgba(0, 0, 0, 0)" } },
            y: { stacked: true },
        },
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    font: {
                        family: "Inter",
                    },
                    boxWidth: 16,
                    boxHeight: 16,
                    generateLabels: (chart: Chart<"bar">) => {
                        const datasets = chart.data.datasets;
                        return datasets.map((dataset, index) => ({
                            text: dataset.label ?? "Error",
                            fillStyle: dataset.backgroundColor as string,
                            lineWidth: 0,
                            borderRadius: 2,
                            hidden: chart.getDatasetMeta(index).hidden,
                            datasetIndex: index,
                        }));
                    },
                },
                onClick(_, legendItem, legend) {
                    if (!legendItem.datasetIndex) return;
                    const hidden = legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden;
                    legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden = !hidden;
                    legend.chart.update();
                },
            },
        },
    },
});

export const toDoughnutData = (data: GraphData): ChartData<"doughnut"> => ({
    ...data,
    labels: data.datasets.map((dataset) => dataset.label),
    datasets: [
        {
            // Expand datasets, provide a zero value if dataset empty
            data: data.datasets.flatMap((dataset) => (dataset.data.length === 0 ? [0] : dataset.data)),
            backgroundColor: theme.graphPalette.map((c) => c[500]),
        },
    ],
});

export const toDoughnutConfig = (data: GraphData): ChartConfiguration => ({
    type: "doughnut",
    data: toDoughnutData(data),
    options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: { duration: 500 },
        plugins: {
            legend: {
                position: "right",
                labels: {
                    font: {
                        family: "Inter",
                    },
                    boxWidth: 16,
                    boxHeight: 16,
                    generateLabels: (chart: Chart) => {
                        const dataset = chart.data.datasets[0];
                        const labels = chart.data.labels as string[];

                        return dataset.data.map((value, index) => ({
                            text: `${labels[index]}: ${value?.toLocaleString()}`,
                            fillStyle: (dataset.backgroundColor as string[])[index],
                            lineWidth: 0,
                            borderRadius: 2,
                            hidden: !chart.getDataVisibility(index),
                            index,
                        }));
                    },
                },
                onClick(_, legendItem, legend) {
                    if (!legendItem.index) return;
                    legend.chart.toggleDataVisibility(legendItem.index);
                    legend.chart.update();
                },
            },
        },
    },
});
