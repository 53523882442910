import { TableCell } from "components/common/DashboardTable/TableCell";
import { TableCellHeader } from "components/common/DashboardTable/TableCellHeader";
import { TableTitle } from "components/common/DashboardTable/TableTitle";
import { useSort } from "components/common/DashboardTable/hooks";
import { Column, Row, SortData } from "components/common/DashboardTable/types";
import { Loader } from "components/common/Loader";
import { TableVirtuoso } from "react-virtuoso";
import styled from "styled-components";
import { dashboardCardCSS, DashboardCardOptions } from "styles/dashboard";
import { theme } from "styles/theme";
import { downloadCSV } from "utils/download";

const DashboardTableStyled = styled.div<DashboardCardOptions>`
    ${dashboardCardCSS};

    display: grid;
    grid-template-rows: max-content 1fr;
`;

const TableNoDataStyled = styled.div`
    margin: auto;
    color: ${theme.color.gray[500]};
    font-weight: 500;
    font-size: 0.875rem;
`;

const TableContainerStyled = styled.div`
    font-size: 0.875rem;
`;

const Table = styled.table`
    width: 100%;
    table-layout: fixed;
`;

export interface TableProps<T extends Row> extends DashboardCardOptions {
    title: string;
    exportFileName?: string;
    columns: Column<T, string>[];
    data?: T[];
    isLoading?: boolean;
    initialSortData?: SortData<T>;
    className?: string;
}

const DashboardTable = <T extends Row>(props: TableProps<T>) => {
    const { title, columns, data, initialSortData } = props;
    const { sortData, handleApplySort, sortedData } = useSort({ columns, data, initialSortData });

    const handleDownload = () => {
        const headers = columns.map((column) => column.label);
        const content = sortedData.map((row) =>
            columns.map((column) => {
                const value = row[column.dataIndex];
                return column.valueTransform?.(value) ?? value;
            })
        );
        // FIXME Provide a time period after filtering implemented.
        downloadCSV(props.exportFileName ?? title, [headers, ...content]);
    };

    return (
        <DashboardTableStyled colSpan={props.colSpan} rowSpan={props.rowSpan} className={props.className}>
            <TableTitle onDownloadClick={handleDownload}>{title}</TableTitle>
            {props.isLoading ? (
                <Loader size={"2rem"} />
            ) : props.data === undefined || props.data.length === 0 ? (
                <TableNoDataStyled>No data...</TableNoDataStyled>
            ) : (
                <TableContainerStyled>
                    <TableVirtuoso
                        data={sortedData}
                        components={{
                            Table: ({ ...props }) => <Table {...props} />,
                        }}
                        fixedHeaderContent={() => (
                            <tr>
                                {columns.map((column, colIndex) => (
                                    <TableCellHeader
                                        key={colIndex}
                                        column={column}
                                        sortData={sortData}
                                        onClick={() => handleApplySort(column.dataIndex)}
                                    >
                                        {column.label}
                                    </TableCellHeader>
                                ))}
                            </tr>
                        )}
                        itemContent={(rowIndex, row) =>
                            columns.map((column) => (
                                <TableCell key={`cell-${rowIndex}-${column.dataIndex}`} row={row} column={column} />
                            ))
                        }
                    />
                </TableContainerStyled>
            )}
        </DashboardTableStyled>
    );
};

export { DashboardTable };
