import { ColumnData, CompareFn, Row } from "components/common/table/types";
import { format } from "date-fns";
import { DATE_WITH_TIME_FORMAT } from "utils/date";

export const readCellValue = (column: ColumnData<any>, value: any): string => {
    let parsedValue = value;

    // Parse value if column value parser provided
    if (column.valueTransform) parsedValue = column.valueTransform(parsedValue);

    // Transform nil values into string
    if (parsedValue === null || parsedValue === undefined || parsedValue === "") return "";

    if (column.dataType === "date") {
        parsedValue = format(new Date(value), DATE_WITH_TIME_FORMAT);
    }

    const stringValue = parsedValue.toLocaleString();
    return stringValue;
};

export const compareString: CompareFn = <T extends Row, TKey extends keyof T = any>(a: T[TKey], b: T[TKey]): number => {
    const aValue: string = a ?? "";
    const bValue: string = b ?? "";
    return aValue.localeCompare(bValue);
};

export const compareNumber: CompareFn = <T extends Row, TKey extends keyof T = any>(a: T[TKey], b: T[TKey]): number => {
    return a - b;
};

export const compareDate: CompareFn = <T extends Row, TKey extends keyof T = any>(a: T[TKey], b: T[TKey]): number => {
    const aValue = new Date(a).getTime();
    const bValue = new Date(b).getTime();
    return aValue - bValue;
};

export const compareBoolean: CompareFn = <T extends Row, TKey extends keyof T = any>(
    a: T[TKey],
    b: T[TKey]
): number => {
    const aValue = Number(a);
    const bValue = Number(b);
    return aValue - bValue;
};
