import { TreeSelect, TreeSelectData } from "components/common/TreeSelect";
import { FormInputWrapper } from "components/form/FormInputWrapper";
import { FieldValidator, useField, useFormikContext } from "formik";
import React from "react";
import { Mode } from "react-dropdown-tree-select";
import { splitAndCapitalize } from "utils/string";

interface DropDownInputProps {
    name: string;
    label?: string;
    required?: boolean;

    mode?: Extract<Mode, "simpleSelect" | "multiSelect">;
    validate?: FieldValidator;

    dropDownData: TreeSelectData[];
}

export const DropDownInput: React.VFC<DropDownInputProps> = (props) => {
    const { label: _label, dropDownData, required } = props;
    const [field, meta] = useField({ name: props.name, validate: props.validate });
    const label = _label ?? splitAndCapitalize(props.name);

    const { setFieldValue, setFieldTouched } = useFormikContext<any>();

    return (
        <FormInputWrapper label={label} required={required} meta={meta}>
            {dropDownData && (
                <TreeSelect
                    mode={props.mode}
                    contentPosition={"relative"}
                    data={dropDownData}
                    selected={field.value}
                    onChange={(values) => {
                        setFieldValue(
                            field.name,
                            values.map((x) => x.value)
                        );
                    }}
                    onBlur={() => setFieldTouched(props.name)}
                />
            )}
        </FormInputWrapper>
    );
};
