import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserPayload } from "store/services/user-service";

type AuthState = AuthorizedState | UnauthorizedState;

interface AuthorizedState {
    accessToken: string;
    user: UserPayload;
}

interface UnauthorizedState {
    accessToken: null;
    user: null;
}

const initialState: AuthState = {
    accessToken: null,
    user: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState as AuthState,
    reducers: {
        setUser: (state, { payload: user }: PayloadAction<UserPayload>) => {
            state.user = user;
        },
        setNewAccessToken: (state, { payload }: PayloadAction<string>) => {
            state.accessToken = payload;
        },
        clearAuth: () => {
            return initialState;
        },
    },
});

export const { setUser, setNewAccessToken, clearAuth } = authSlice.actions;

export const authReducer = authSlice.reducer;
