import styled, { css } from "styled-components";
import { theme } from "styles/theme";

const RadioButtonMixin = (columnsEnabled: boolean) => {
    if (columnsEnabled) {
        return css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 3rem;
            grid-row-gap: 0.5rem;
        `;
    }

    return css`
        display: flex;
        gap: 1rem;
        margin: 1rem 0;
    `;
};

export const RadioButtonGroup = styled.div<{ $columnsEnabled: boolean }>`
    ${(props) => {
        return RadioButtonMixin(props.$columnsEnabled);
    }}
    div {
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }

    label {
        font-weight: 500;
    }

    input[type="radio"] {
        appearance: none;
        display: grid;
        place-content: center;
        width: 1.25rem;
        height: 1.25rem;
        margin: 0;

        background-color: #fff;
        border: 1px solid ${theme.color.gray[500]};
        border-radius: 50%;
        box-shadow: ${theme.boxShadow.sm};
    }

    input[type="radio"]::before {
        content: "";
        width: 0.75em;
        height: 0.75em;

        border-radius: 50%;
        box-shadow: inset 1rem 1rem ${theme.color.primary[500]};

        transform: scale(0);
        transition: 150ms transform ease-in-out;
    }

    input[type="radio"]:checked::before {
        transform: scale(1);
    }
`;
