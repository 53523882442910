import { Button } from "components/common/Button";
import { ConfirmModal } from "components/common/ConfirmModal";
import { IconButton } from "components/common/IconButton";
import { Loader } from "components/common/Loader";
import { Modal } from "components/common/Modal";
import { PageContainer } from "components/common/PageContainer";
import { PageHeader } from "components/common/PageHeader";
import { WidgetFrame } from "components/common/WidgetFrame";
import { DataTable } from "components/common/table/DataTable";
import { useSortConfig } from "components/common/table/DataTable/hooks";
import { Column, ColumnActionComponent } from "components/common/table/types";
import { AddKeyAccountForm } from "components/form/forms/AddKeyAccountForm";
import { AddIcon, DeleteIcon, KeyAccountIcon, EditIcon } from "components/icons/Icons";
import { LoaderOverlap } from "components/styled/LoaderOverlap";
import React, { useState } from "react";
import {
    KeyAccountPayload,
    KeyAccountRequest,
    useAddKeyAccountMutation,
    useGetKeyAccountsQuery,
    useUpdateKeyAccountMutation,
    useDeleteKeyAccountMutation,
} from "store";
import { useInputValue } from "utils/hooks/useInput";

export const KeyAccountsPage: React.VFC = () => {
    const [showForm, setShowForm] = useState(false);
    const [editData, setEditData] = useState<KeyAccountPayload | null>(null);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [modalError, setModalError] = useState<string | undefined>();

    const [createKeyAccount, { isLoading: isCreateLoading }] = useAddKeyAccountMutation();
    const [updateKeyAccount, { isLoading: isUpdateLoading }] = useUpdateKeyAccountMutation();
    const [deleteKeyAccount, { isLoading: isDeleteLoading }] = useDeleteKeyAccountMutation();
    const { data, isLoading: isQueryLoading, isFetching } = useGetKeyAccountsQuery();

    const isLoading = isQueryLoading || isDeleteLoading || isFetching;

    const handleCreateKeyAccount = async (values: KeyAccountRequest) => {
        try {
            await createKeyAccount(values).unwrap();
            setShowForm(false);
        } catch (err: any) {
            setModalError(err?.data?.error);
        }
    };

    const handleUpdateKeyAccount = async (values: KeyAccountRequest) => {
        if (!editData) return;

        try {
            await updateKeyAccount({ keyAccount: values, id: editData.id }).unwrap();
        } catch (err: any) {
            setModalError(err?.data?.error);
            return;
        }

        setShowForm(false);
        setEditData(null);
    };

    const handleDelete = async (id: number) => {
        try {
            await deleteKeyAccount(id).unwrap();
            setDeleteId(null);
        } catch (err: any) {
            setModalError(err?.data?.error);
        }
    };

    // const handleOpenDetailPage = (keyAccount: KeyAccountRow) => {
    //     navigate(`${routes.keyAccountDetail.getPath({ keyAccountId: keyAccount.id.toString() })}`);
    // };

    const openEditModal = (isOpen: boolean, keyAccount?: KeyAccountPayload) => {
        setShowForm(isOpen);
        setModalError(undefined);
        setEditData(keyAccount ? keyAccount : null);
    };

    const openDeleteModal = (id: number) => {
        setDeleteId(id);
        setModalError(undefined);
    };

    const dataTableSearchInput = useInputValue();
    const dataTableSortConfig = useSortConfig<KeyAccountPayload>({ dataIndex: "name", descending: false });

    const OpenEditModalButton: ColumnActionComponent<KeyAccountPayload> = ({ row }) => (
        <IconButton onClick={() => openEditModal(true, row)} title={"Edit"} Icon={EditIcon} />
    );
    const OpenDeleteModalButton: ColumnActionComponent<KeyAccountPayload> = ({ row }) => (
        <IconButton onClick={() => openDeleteModal(row.id)} title={"Delete"} Icon={DeleteIcon} />
    );

    const columns: Column<KeyAccountPayload>[] = [
        { type: "data", dataIndex: "name", dataType: "string", sortable: true },
        { type: "data", dataIndex: "keyAccountNumber", dataType: "string", sortable: true },
        {
            type: "actions",
            width: 400,
            components: [OpenEditModalButton, OpenDeleteModalButton],
        },
    ];

    return (
        <PageContainer>
            <PageHeader title={"Key Account Administration"}>
                <Button onClick={() => openEditModal(true)} Icon={AddIcon}>
                    Add Key Account
                </Button>
            </PageHeader>
            <WidgetFrame isLoading={isLoading} height={"100%"} title={"Key Accounts"} Icon={KeyAccountIcon}>
                <DataTable<KeyAccountPayload>
                    data={data}
                    columns={columns}
                    configuration={{ disableDownload: true }}
                    sortConfig={dataTableSortConfig}
                    searchInput={dataTableSearchInput}
                />
            </WidgetFrame>

            <Modal isOpen={showForm} setOpen={openEditModal}>
                <AddKeyAccountForm
                    editData={editData}
                    handleSubmit={editData ? handleUpdateKeyAccount : handleCreateKeyAccount}
                    error={modalError}
                />
                {isCreateLoading ||
                    (isUpdateLoading && (
                        <LoaderOverlap>
                            <Loader size={"4rem"} />
                        </LoaderOverlap>
                    ))}
            </Modal>
            {deleteId && (
                <ConfirmModal
                    open={true}
                    setOpen={() => setDeleteId(null)}
                    onConfirm={() => handleDelete(deleteId)}
                    title={"Delete key account?"}
                    description={`Key account ${data?.find((x) => x.id === deleteId)?.name} will be deleted.`}
                    confirmText={"Yes, delete"}
                    error={modalError}
                />
            )}
        </PageContainer>
    );
};
