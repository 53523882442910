import { rootApi } from "store/services/root-api";
import { Role } from "types/authTypes";

export interface BasicUserPayload {
    id: string;
    name: string;
    username: string;
    azure_oid?: string;
    email?: string;
    role: Role;
}

export interface UserPayload extends BasicUserPayload {
    userConfig?: string;
    customerPermissions: any[];
    keyAccountPermissions: any[];
}

export interface UserRequestAddPermissions {
    customerPermissions: { customer: { id: number } }[];
    keyAccountPermissions: { keyAccount: { id: number } }[];
}
export interface UserRequest extends UserRequestAddPermissions {
    name: string;
    username: string;
    email?: string;
    role: Role;
}

export interface UserRequestUpdatePermissions {
    customerPermissions: Record<number, { customer: { id: number } }>;
    keyAccountPermissions: Record<number, { keyAccount: { id: number } }>;
}

export type UserUpdateRequestData = Omit<UserRequestAddPermissions, "customerPermissions" | "keyAccountPermissions"> &
    UserRequestUpdatePermissions;

export interface UserUpdateRequest {
    user: UserUpdateRequestData;
    id: string;
}

const USER_BASE_PATH = "users";
const USER_CACHE_TYPE = "User";
const USER_CACHE_ID = "LIST";

const apiWithTag = rootApi.enhanceEndpoints({ addTagTypes: ["User"] });
export const userApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getMe: builder.query<UserPayload, void>({
            query: () => `${USER_BASE_PATH}/me`,
        }),
        getUsers: builder.query<UserPayload[], void>({
            query: () => USER_BASE_PATH,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: USER_CACHE_TYPE, id }) as const),
                          { type: USER_CACHE_TYPE, id: USER_CACHE_ID },
                      ]
                    : [{ type: USER_CACHE_TYPE, id: USER_CACHE_ID }],
        }),
        inviteUser: builder.query<void, string>({
            query: (id) => ({
                url: `${USER_BASE_PATH}/${id}/invite`,
                method: "GET",
            }),
        }),
        addUser: builder.mutation<UserPayload, UserRequest>({
            query: (body) => ({
                url: USER_BASE_PATH,
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: USER_CACHE_TYPE, id: USER_CACHE_ID }],
        }),
        updateUser: builder.mutation<void, UserUpdateRequest>({
            query: ({ user, id }) => ({
                url: `${USER_BASE_PATH}/${id}`,
                method: "PUT",
                body: user,
            }),
            invalidatesTags: [{ type: USER_CACHE_TYPE, id: USER_CACHE_ID }],
        }),
        deleteUser: builder.mutation<void, string>({
            query: (id) => ({
                url: `${USER_BASE_PATH}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: USER_CACHE_TYPE, id: USER_CACHE_ID }],
        }),
    }),
});

export const {
    useGetMeQuery,
    useGetUsersQuery,
    useLazyInviteUserQuery,
    useAddUserMutation,
    useDeleteUserMutation,
    useUpdateUserMutation,
} = userApi;
