import {
    CustomerDetailPage,
    CustomersPage,
    DashboardPage,
    KeyAccountDetailPage,
    KeyAccountsPage,
    MachineDetailPage,
    MachinesPage,
    ModuleDetailPage,
    ModulesPage,
    NotFoundPage,
    UserDetailPage,
    UsersPage,
    ProvisioningPage,
    NotAllowedPage,
} from "pages";
import { Role } from "types/authTypes";
import {
    AuthRoute,
    CustomerParam,
    KeyAccountParam,
    MachineParam,
    ModuleParam,
    Route,
    RouteWithParams,
    UserParam,
} from "types/routingTypes";
import { ADMIN_ROLES, ALL_ROLES } from "utils/auth-utils";

interface Routes {
    dashboard: AuthRoute;
    machines: AuthRoute;
    machineDetail: RouteWithParams<MachineParam>;
    modules: AuthRoute;
    moduleDetail: RouteWithParams<ModuleParam>;
    keyAccounts: AuthRoute;
    keyAccountDetail: RouteWithParams<KeyAccountParam>;
    customers: AuthRoute;
    customerDetail: RouteWithParams<CustomerParam>;
    users: AuthRoute;
    userDetail: RouteWithParams<UserParam>;
    notFound: AuthRoute;
    provisioning: AuthRoute;
    notAllowed: AuthRoute;
}

export const routes: Routes = {
    dashboard: {
        path: "/",
        Page: DashboardPage,
        allowedRoles: ALL_ROLES,
    },
    machines: {
        path: "/machines",
        Page: MachinesPage,
        allowedRoles: [...ADMIN_ROLES, Role.KeyAccount],
    },
    machineDetail: {
        path: "/machines/:machineId",
        Page: MachineDetailPage,
        allowedRoles: ADMIN_ROLES,
        getPath({ machineId }) {
            return this.path.replace(":machineId", machineId);
        },
    },
    modules: {
        path: "/modules",
        Page: ModulesPage,
        allowedRoles: ADMIN_ROLES,
    },
    moduleDetail: {
        path: "/modules/:moduleSerialNumber",
        Page: ModuleDetailPage,
        allowedRoles: ADMIN_ROLES,
        getPath({ moduleSerialNumber }) {
            return this.path.replace(":moduleSerialNumber", moduleSerialNumber);
        },
    },
    keyAccounts: {
        path: "/key-accounts",
        Page: KeyAccountsPage,
        allowedRoles: [Role.Superadmin],
    },
    keyAccountDetail: {
        path: "/key-accounts/:keyAccountId",
        Page: KeyAccountDetailPage,
        allowedRoles: [Role.Superadmin],
        getPath({ keyAccountId }) {
            return this.path.replace(":keyAccountId", keyAccountId);
        },
    },
    customers: {
        path: "/customers",
        Page: CustomersPage,
        allowedRoles: [Role.Superadmin, Role.KeyAccount, Role.Operator],
    },
    customerDetail: {
        path: "/customers/:customerId",
        Page: CustomerDetailPage,
        allowedRoles: [Role.Superadmin, Role.KeyAccount, Role.Operator],
        getPath({ customerId }) {
            return this.path.replace(":customerId", customerId);
        },
    },
    users: {
        path: "/users",
        Page: UsersPage,
        allowedRoles: [Role.Superadmin, Role.KeyAccount, Role.Customer],
    },
    userDetail: {
        path: "/users/:userId",
        Page: UserDetailPage,
        allowedRoles: [Role.Superadmin, Role.KeyAccount],
        getPath({ userId }) {
            return this.path.replace(":userId", userId);
        },
    },
    notFound: {
        path: "/not-found",
        Page: NotFoundPage,
        allowedRoles: ALL_ROLES,
    },
    provisioning: {
        path: "/provisioning",
        Page: ProvisioningPage,
        allowedRoles: [Role.Superadmin, Role.Operator],
    },
    notAllowed: {
        path: "/not-allowed",
        Page: NotAllowedPage,
        allowedRoles: ALL_ROLES,
        skipAuth: true,
    },
};

const routesList: Route[] = Object.values(routes);

// AUTHORIZED routes list
export const authRoutesList: AuthRoute[] = routesList.filter(({ skipAuth }: Route) => !skipAuth) as AuthRoute[];

// UNAUTHORIZED routes list
export const unguardedRoutesList: Route[] = routesList.filter(({ skipAuth }) => !!skipAuth);
