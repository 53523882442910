import React from "react";
import styled from "styled-components";

interface Props {
    children: React.ReactNode;
}

const ContentWrap = styled.main`
    flex-grow: 1;
    padding: 2rem;
    overflow: auto;
`;

export const Content: React.FC<Props> = ({ children }) => {
    return <ContentWrap>{children}</ContentWrap>;
};
