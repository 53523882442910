import { Graph } from "components/common/Graph";
import React from "react";
import { useDashboardDeviceTypes } from "store/hooks/useDashboardDeviceTypes";
import { useDashboardDevices } from "store/hooks/useDashboardDevices";
import { useDashboardTimeRange } from "store/hooks/useDashboardTimeRange";
import { useGetDispensesAverageByTypeAndHourQuery } from "store/services/dispense-service";

const AverageDispenses: React.FC = () => {
    const timeRange = useDashboardTimeRange();
    const devices = useDashboardDevices();
    const deviceTypes = useDashboardDeviceTypes();
    const { data, isLoading, isFetching } = useGetDispensesAverageByTypeAndHourQuery({
        ...timeRange,
        devices,
        deviceTypes,
    });

    return (
        <Graph
            variant={"barStacked"}
            title={"Average drink dispenses by hour"}
            data={data}
            isLoading={isLoading || isFetching}
            colSpan={6}
            rowSpan={3}
        />
    );
};

export { AverageDispenses };
