import { z } from "zod";

const stringSchema = z.string({ required_error: "Not defined" });

const configSchema = z.object({
    MODE: z.enum(["production", "acceptance", "test", "development"]).optional(),
    API_BASE_URL: stringSchema.url().refine((val) => val.at(-1) === "/", {
        message: "url should end with the character '/'",
    }),
    SENTRY_DSN: stringSchema.optional(),
    AD_AUTH_CLIENT_ID: stringSchema,
    AD_AUTH_AUTHORITY: stringSchema,
    AD_AUTH_REDIRECT_URI: stringSchema,
    AD_AUTH_LOGOUT_REDIRECT_URI: stringSchema,
    AD_AUTH_SCOPE: stringSchema,
});

let config: z.infer<typeof configSchema>;
const result = configSchema.safeParse({
    MODE: import.meta.env.MODE,
    API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
    SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    AD_AUTH_CLIENT_ID: import.meta.env.VITE_AD_AUTH_CLIENT_ID,
    AD_AUTH_AUTHORITY: import.meta.env.VITE_AD_AUTH_AUTHORITY,
    AD_AUTH_REDIRECT_URI: import.meta.env.VITE_AD_AUTH_REDIRECT_URI,
    AD_AUTH_LOGOUT_REDIRECT_URI: import.meta.env.VITE_AD_AUTH_LOGOUT_REDIRECT_URI,
    AD_AUTH_SCOPE: import.meta.env.VITE_AD_AUTH_SCOPE,
});

class EnvironmentVariablesError extends Error {
    name = "EnvironmentVariablesError";

    constructor(errors: Record<string, string[]>) {
        const message = Object.entries(errors)
            .map(([key, errors]) => `${key}: ${errors.join(", ")}`)
            .join("\n");

        super(`\n\nErrors while validating environment variables:\n\n${message}\n\n`);
    }
}

if (result.success) {
    config = result.data;
} else {
    const errors = result.error.flatten();
    throw new EnvironmentVariablesError(errors.fieldErrors);
}

export { config };
