import { ColorShade, DefaultTheme } from "styled-components";

const colorGray: Record<ColorShade, string> = {
    50: "hsl(210, 20%, 98%)",
    100: "hsl(220, 14%, 96%)",
    200: "hsl(220, 13%, 91%)",
    300: "hsl(216, 12%, 84%)",
    400: "hsl(218, 11%, 65%)",
    500: "hsl(220, 9%, 46%)",
    600: "hsl(215, 14%, 34%)",
    700: "hsl(217, 19%, 27%)",
    800: "hsl(215, 28%, 17%)",
    900: "hsl(221, 39%, 11%)",
};
const colorPrimary: Record<ColorShade, string> = {
    50: "hsl(30, 33%, 95%)",
    100: "hsl(31, 38%, 88%)",
    200: "hsl(30, 37%, 77%)",
    300: "hsl(27, 38%, 64%)",
    400: "hsl(25, 37%, 52%)",
    500: "hsl(22, 36%, 47%)",
    600: "hsl(18, 37%, 40%)",
    700: "hsl(12, 36%, 33%)",
    800: "hsl(9, 32%, 29%)",
    900: "hsl(6, 28%, 26%)",
};
const colorError: Record<ColorShade, string> = {
    50: "hsl(0, 86%, 97%)",
    100: "hsl(0, 93%, 94%)",
    200: "hsl(0, 96%, 89%)",
    300: "hsl(0, 94%, 82%)",
    400: "hsl(0, 91%, 71%)",
    500: "hsl(0, 84%, 60%)",
    600: "hsl(0, 72%, 51%)",
    700: "hsl(0, 74%, 42%)",
    800: "hsl(0, 70%, 35%)",
    900: "hsl(0, 62%, 30%)",
};

export const theme: DefaultTheme = {
    borderRadius: {
        sm: "0.375rem",
        md: "0.5rem",
        lg: "1rem",
    },
    boxShadow: {
        sm: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
        md: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
        lg: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
        xl: "0 10px 15px -3px rgb(0 0 0 / 0.05), 0 4px 6px -4px rgb(0 0 0 / 0.05)",
    },
    color: {
        white: "hsl(0, 0%, 100%)",
        cupcinoBlack: "hsl(285, 8%, 10%)",
        transparentBlack50: "hsla(0, 0%, 0%, 0.5)",
        gray: colorGray,
        primary: colorPrimary,
        error: colorError,
        warning: {
            200: "hsl(32, 98%, 83%)",
            300: "hsl(31, 97%, 72%)",
            500: "hsl(25, 95%, 53%)",
            700: "hsl(17, 88%, 40%)",
            900: "hsl(15, 75%, 28%)",
        },
        success: {
            200: "hsl(141, 79%, 85%)",
            300: "hsl(142, 69%, 58%)",
            500: "hsl(142, 71%, 45%)",
            700: "hsl(142, 72%, 29%)",
            900: "hsl(144, 61%, 20%)",
        },
        info: {
            200: "hsl(213, 96%, 87%)",
            300: "hsl(212, 96%, 78%)",
            500: "hsl(217, 91%, 60%)",
            700: "hsl(224, 76%, 48%)",
            900: "hsl(224, 64%, 33%)",
        },
    },
    graphPalette: [
        {
            500: colorPrimary[500],
            900: colorPrimary[900],
        },
        {
            500: "hsl(82, 67%, 63%)",
            900: "hsl(82, 67%, 13%)",
        },
        {
            500: "hsl(206, 51%, 67%)",
            900: "hsl(206, 51%, 17%)",
        },
    ],
};
