import { tableCellBaseCSS, tableCellInnerBaseCSS, TableCellOptions } from "components/common/table/Table/styles";
import { SortConfig, AnyColumn, isColumnData } from "components/common/table/types";
import { SortAscendingIcon, SortDescendingIcon, SortUndefinedIcon } from "components/icons/Icons";
import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { splitAndCapitalize } from "utils/string";

const tableCellHeaderClickableCSS = css`
    cursor: pointer;
    :hover {
        background-color: ${({ theme }) => theme.color.white};
    }
`;

const TableCellHeaderStyled = styled.th<TableCellOptions & { isSortable: boolean }>`
    ${tableCellBaseCSS};

    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.cupcinoBlack};
    font-weight: 600;
    letter-spacing: 0.025rem;
    text-align: left;

    border-bottom: 2px solid ${({ theme }) => theme.color.primary[500]};

    ${({ isSortable }) => (isSortable ? tableCellHeaderClickableCSS : "")};
`;

const TableCellHeaderContainerStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TableCellHeaderInnerStyled = styled.div`
    ${tableCellInnerBaseCSS};
`;

const TableCellHeaderIconStyled = styled.div`
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
    flex-shrink: 0;
`;

interface Props {
    children: ReactNode;
    column: AnyColumn;
    sortConfig?: SortConfig<any> | null;
    onClick?: () => void;
}

const TableCellHeader = (props: Props) => {
    const { column, sortConfig } = props;
    const dataIndex = isColumnData(column) ? column.dataIndex : undefined;
    const label = isColumnData(column) ? column.label ?? splitAndCapitalize(String(dataIndex)) : undefined;
    const tooltip = isColumnData(column) ? column.tooltip ?? label : undefined;

    const isSortable = isColumnData(column) ? column.sortable : false;
    const isSortColumn = sortConfig?.dataIndex === dataIndex;
    const SortIcon = isSortColumn
        ? sortConfig?.descending
            ? SortDescendingIcon
            : SortAscendingIcon
        : SortUndefinedIcon;

    return (
        <TableCellHeaderStyled onClick={props.onClick} title={tooltip} width={column.width} isSortable={isSortable}>
            <TableCellHeaderContainerStyled>
                <TableCellHeaderInnerStyled>{label}</TableCellHeaderInnerStyled>
                {isSortable && (
                    <TableCellHeaderIconStyled>
                        <SortIcon size={"16px"} />
                    </TableCellHeaderIconStyled>
                )}
            </TableCellHeaderContainerStyled>
        </TableCellHeaderStyled>
    );
};

export { TableCellHeader };
