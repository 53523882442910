import { css } from "styled-components";
import { theme } from "styles/theme";

export interface DashboardCardOptions {
    colSpan?: number;
    rowSpan?: number;
}

export const dashboardCardCSS = css<DashboardCardOptions>`
    grid-row: span ${({ rowSpan }) => rowSpan ?? 1};
    grid-column: span ${({ colSpan }) => colSpan ?? 1};

    overflow: hidden;

    background: ${theme.color.white};
    box-shadow: ${theme.boxShadow.xl};
    border: 1px solid ${theme.color.gray[200]};
    border-radius: 0.5rem;
`;
