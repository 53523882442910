import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    endOfDay,
    endOfMonth,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfYear,
    subDays,
    subMonths,
    subYears,
} from "date-fns";
import { dateToISOStringUTC } from "utils/date";

// All dates in the redux store are saved as strings, this is because of
// https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions

// Predefined dates used in dashboard queries
const today = new Date();

const days30Start = dateToISOStringUTC(startOfDay(subDays(today, 30)));
const days30End = dateToISOStringUTC(endOfDay(today));
const prevMonthStart = dateToISOStringUTC(startOfMonth(subMonths(today, 1)));
const prevMonthEnd = dateToISOStringUTC(endOfMonth(subMonths(today, 1)));
const months12Start = dateToISOStringUTC(startOfDay(subMonths(today, 12)));
const months12End = dateToISOStringUTC(endOfDay(today));
const prevYearStart = dateToISOStringUTC(startOfYear(subYears(today, 1)));
const prevYearEnd = dateToISOStringUTC(endOfYear(subYears(today, 1)));
const allTimeStart = dateToISOStringUTC(startOfDay(new Date("2019-01-01")));
const allTimeEnd = dateToISOStringUTC(endOfDay(today));

export type TimeRangePresetType = "days30" | "prevMonth" | "months12" | "prevYear" | "allTime";

export type TimeRangeValue = {
    dateTimeFrom: string;
    dateTimeTo: string;
};

export type TimeRange =
    | ({
          type: "custom";
      } & TimeRangeValue)
    | { type: TimeRangePresetType };

export const timeRangeMap: Record<TimeRangePresetType, TimeRangeValue> = {
    days30: { dateTimeFrom: days30Start, dateTimeTo: days30End },
    prevMonth: { dateTimeFrom: prevMonthStart, dateTimeTo: prevMonthEnd },
    months12: { dateTimeFrom: months12Start, dateTimeTo: months12End },
    prevYear: { dateTimeFrom: prevYearStart, dateTimeTo: prevYearEnd },
    allTime: { dateTimeFrom: allTimeStart, dateTimeTo: allTimeEnd },
};

export interface DashboardState {
    devices: string[];
    deviceTypes: string[];
    timeRange: TimeRange;
}

const initialState: DashboardState = {
    timeRange: { type: "days30" },
    devices: [],
    deviceTypes: [],
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        setTimeRangeDateTimeFrom: (state, { payload: dateTimeFrom }: PayloadAction<string>) => {
            const dateTimeTo =
                state.timeRange.type === "custom"
                    ? state.timeRange.dateTimeTo
                    : timeRangeMap[state.timeRange.type].dateTimeTo;

            state.timeRange = {
                type: "custom",
                dateTimeFrom,
                dateTimeTo,
            };
        },
        setTimeRangeDateTimeTo: (state, { payload: dateTimeTo }: PayloadAction<string>) => {
            const dateTimeFrom =
                state.timeRange.type === "custom"
                    ? state.timeRange.dateTimeFrom
                    : timeRangeMap[state.timeRange.type].dateTimeFrom;

            state.timeRange = {
                type: "custom",
                dateTimeFrom,
                dateTimeTo,
            };
        },
        setTimeRange: (state, { payload: timeRange }: PayloadAction<TimeRange>) => {
            state.timeRange = timeRange;
        },
        setDevices: (state, { payload: devices }: PayloadAction<string[]>) => {
            state.devices = devices;
        },
        setDeviceTypes: (state, { payload: deviceTypes }: PayloadAction<string[]>) => {
            state.deviceTypes = deviceTypes;
        },
    },
});

export const { setTimeRangeDateTimeFrom, setTimeRangeDateTimeTo, setTimeRange, setDevices, setDeviceTypes } =
    dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;
