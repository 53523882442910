import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { config } from "config";
import { LoadingPage } from "pages/LoadingPage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "routing/routes";
import { setNewAccessToken, setUser, useGetMeQuery } from "store";
import { useCurrentAuth } from "store/hooks";
import { useAADAuthentication } from "utils/hooks/useAADAuthentication";

/**
 * Set preferred interaction type for token acquisition
 * Choose Redirect or Popup
 */
const INTERACTION_TYPE = InteractionType.Redirect;

interface Props {
    children: JSX.Element;
}

export const AuthGuard = ({ children }: Props) => {
    const { inProgress, acquireAccessToken, triggerLogin } = useAADAuthentication({
        interactionType: INTERACTION_TYPE,
    });
    const { user: currentUser, accessToken: currentAccessToken } = useCurrentAuth();
    const { data: me, isLoading, isSuccess, isError } = useGetMeQuery(undefined, { skip: !currentAccessToken });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /**
     * After token acquisition, set the current user in the store
     */
    useEffect(() => {
        if (isSuccess && !currentUser) {
            dispatch(setUser(me));
        } else if (isError) {
            navigate(routes.notAllowed.path);
        }
    }, [dispatch, me, currentUser, isSuccess, isError, navigate]);

    useEffect(() => {
        if (currentAccessToken || inProgress !== InteractionStatus.None) return;
        acquireAccessToken({
            scopes: [config.AD_AUTH_SCOPE],
            persistTokenCallback: (accessToken) => dispatch(setNewAccessToken(accessToken)),
        });
    }, [inProgress, currentAccessToken, acquireAccessToken, dispatch]);

    /**
     * If user cancels or breaks the login process, init login again and
     * renders empty element meanwhile (could be improved by nice countdown
     * screen followed by redirect back to the login process
     */
    const RetryLoginComponent = () => {
        triggerLogin();
        return <></>;
    };

    const isAuthReady = currentUser && currentAccessToken && !isLoading;
    return (
        <MsalAuthenticationTemplate
            interactionType={INTERACTION_TYPE}
            errorComponent={RetryLoginComponent}
            loadingComponent={LoadingPage}
        >
            {isAuthReady ? children : <LoadingPage />}
        </MsalAuthenticationTemplate>
    );
};
