import { EntityPicker } from "pages/DashboardPage/EntityPicker";
import { MachineTypePicker } from "pages/DashboardPage/MachineTypePicker";
import { TimePicker } from "pages/DashboardPage/TimePicker/TimePicker";
import { Brew } from "pages/DashboardPage/carets/Brew";
import { ErrorWarning } from "pages/DashboardPage/carets/ErrorWarning";
import { MilkConsumption } from "pages/DashboardPage/carets/MilkConsumption";
import { OnlineDevices } from "pages/DashboardPage/carets/OnlineDevices";
import { TotalDispenses } from "pages/DashboardPage/carets/TotalDispenses";
import { WaterConsumption } from "pages/DashboardPage/carets/WaterConsumption";
import { AverageDispenses } from "pages/DashboardPage/graphs/AverageDispenses";
import { TotalDispensesByType } from "pages/DashboardPage/graphs/TotalDispenses";
import { TotalDispensesByPeriod } from "pages/DashboardPage/graphs/TotalDispensesByPeriod";
import { Devices } from "pages/DashboardPage/tables/Devices";
import { DrinkType } from "pages/DashboardPage/tables/DrinkType";
import React from "react";
import styled from "styled-components";

const ToolbarStyled = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 3rem;
`;

const PickerGroupStyled = styled.div`
    display: flex;
    gap: 1.5rem;
`;

const DashboardPageStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-auto-rows: 6rem;
    gap: 1.5rem;
`;

export const DashboardPage: React.VFC = () => {
    return (
        <div>
            <ToolbarStyled>
                <PickerGroupStyled>
                    <EntityPicker />
                    <MachineTypePicker />
                </PickerGroupStyled>
                <TimePicker />
            </ToolbarStyled>
            <DashboardPageStyled>
                <OnlineDevices />
                <Brew />
                <TotalDispensesByType />
                <AverageDispenses />
                <TotalDispenses />
                <MilkConsumption />
                <ErrorWarning />
                <WaterConsumption />
                <TotalDispensesByPeriod />
                <DrinkType />
                <Devices />
            </DashboardPageStyled>
        </div>
    );
};
