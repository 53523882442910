import { SearchIcon } from "components/icons/Icons";
import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { inputCSS } from "styles/input";

interface Props extends Pick<InputHTMLAttributes<any>, "disabled" | "placeholder" | "value" | "onBlur" | "onChange"> {
    error?: boolean;
    success?: boolean;
}

const SearchStyled = styled.div`
    width: 100%;
    position: relative;
`;

const SearchInput = styled.input`
    ${inputCSS}
`;

const SearchIconStyled = styled(SearchIcon)`
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    width: fit-content;

    font-size: 1.25rem;
`;

const Search = ({ placeholder = "Type to search...", ...props }: Props) => {
    return (
        <SearchStyled>
            <SearchInput placeholder={placeholder} {...props} />
            <SearchIconStyled size={"24px"} />
        </SearchStyled>
    );
};

export { Search };
export type { Props as SearchProps };
