import { TableDownload } from "components/common/DashboardTable/TableDownload";
import { TableIcon } from "components/icons/Icons";
import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

const TableTitleStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    overflow: hidden;
`;

const TableTitleContentStyled = styled.div`
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.0125rem;
    color: ${theme.color.gray[900]};
`;

const TableTitleContentInnerStyled = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const TableIconStyled = styled.div`
    display: flex;
    justify-content: center;
    color: ${theme.color.gray[500]};
    margin-right: 0.75rem;
    flex-shrink: 0;
`;

interface Props {
    children: React.ReactNode;
    onDownloadClick: () => void;
}

const TableTitle: React.FC<Props> = ({ onDownloadClick, children }) => {
    return (
        <TableTitleStyled title={typeof children === "string" ? children : undefined}>
            <TableTitleContentStyled>
                <TableIconStyled>
                    <TableIcon size={"16px"} />
                </TableIconStyled>
                <TableTitleContentInnerStyled>{children}</TableTitleContentInnerStyled>
            </TableTitleContentStyled>
            <TableDownload onClick={onDownloadClick} />
        </TableTitleStyled>
    );
};

export { TableTitle };
