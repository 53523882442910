import { Button } from "components/common/Button";
import { ModalActions } from "components/common/ModalActions";
import { ModalTitle } from "components/common/ModalTitle";
import { ErrorPanel } from "components/form/ErrorPanel";
import { FormInputWrapper } from "components/form/FormInputWrapper";
import { TextInput } from "components/form/TextInput";
import { CheckIcon } from "components/icons/Icons";
import { Select } from "components/styled/Select";
import { Field, Form, Formik } from "formik";
import React from "react";
import { ModuleRequest, ModuleType } from "store/services/module-service";
import styled from "styled-components";
import { kebabToTitleCase } from "utils/string";
import * as Yup from "yup";

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const SubmitButton = styled(Button)`
    margin-top: 1rem;
`;

const validationSchema = Yup.object().shape({
    serialNumber: Yup.string().required("Serial number is required"),
    type: Yup.string().required("Type is required"),
});

const baseInitialValues: ModuleRequest = { serialNumber: "", type: ModuleType.BrewModule };

interface AddModuleFormProps {
    handleSubmit: (values: ModuleRequest) => void;
    editData: ModuleRequest | null;
    error?: string;
}

export const AddModuleForm: React.VFC<AddModuleFormProps> = ({ handleSubmit, error, editData }) => {
    let initialValues = baseInitialValues;

    if (editData) {
        const { serialNumber, type } = editData;
        initialValues = { ...baseInitialValues, serialNumber, type };
    }
    return (
        <Formik<ModuleRequest>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, getFieldMeta }) => {
                return (
                    <FormStyled>
                        <ModalTitle>{editData ? "Edit module" : "Create  a new module"}</ModalTitle>
                        <TextInput name={"serialNumber"} label={"Module Serial Number"} required />
                        <div>
                            <FormInputWrapper label={"Module Type"} required meta={getFieldMeta("type")}>
                                <Field as={Select} name={"type"}>
                                    {Object.values(ModuleType)
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((value: string) => (
                                            <option key={value} value={value}>
                                                {kebabToTitleCase(value)}
                                            </option>
                                        ))}
                                </Field>
                            </FormInputWrapper>
                        </div>
                        {error && <ErrorPanel>{error}</ErrorPanel>}
                        <ModalActions>
                            <SubmitButton type={"submit"} disabled={isSubmitting} variant={"primary"} Icon={CheckIcon}>
                                Submit
                            </SubmitButton>
                        </ModalActions>
                    </FormStyled>
                );
            }}
        </Formik>
    );
};
