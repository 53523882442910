import { Loader } from "components/common/Loader";
import styled from "styled-components";

const LoadingPageStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;

const LoadingPageContent = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    font-weight: 500;
`;

export const LoadingPage = () => {
    return (
        <LoadingPageStyled>
            <LoadingPageContent>
                <Loader size={"1.5rem"} />
                Loading...
            </LoadingPageContent>
        </LoadingPageStyled>
    );
};
