import { Chart, ChartConfiguration, registerables } from "chart.js";
import { GraphData, Variant } from "components/common/Graph/types";
import { toBarStackedConfig, toDoughnutConfig } from "components/common/Graph/utils";
import React, { useEffect, useRef } from "react";

Chart.register(...registerables);

const configMap: Record<Variant, (data: GraphData) => ChartConfiguration> = {
    barStacked: toBarStackedConfig,
    doughnut: toDoughnutConfig,
};

interface Props {
    data: GraphData;
    variant: Variant;
}

const GraphContent: React.FC<Props> = ({ data, variant }) => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    const toConfig = configMap[variant];

    useEffect(() => {
        if (!chartRef.current) return;
        const ch = new Chart(chartRef.current, toConfig(data));

        return () => {
            ch.destroy();
        };
    }, [data, toConfig]);

    return <canvas ref={chartRef} />;
};

export { GraphContent };
