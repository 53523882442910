import { Badge } from "components/common/Badge";
import { Button } from "components/common/Button";
import { withCustomer, withKeyAccount, withName } from "components/common/RecordDetailBox/record-detail-box.helper";
import { EditIcon } from "components/icons/Icons";
import { ReactNode } from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { splitAndCapitalize } from "utils/string";

const PropertyValue = styled.div`
    padding: 0.25rem 0;
`;

const PropertyName = styled.div`
    color: ${theme.color.gray[700]};

    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.0125rem;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const RecordProperty = styled.div`
    display: flex;
    flex-direction: column;

    border-bottom: 1px solid ${theme.color.gray[300]};
`;

const RecordDetailBoxStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 12rem);
    grid-gap: 1.5rem;
    justify-content: space-between;
`;

const ButtonWrapper = styled.div`
    margin-top: 2rem;
`;

export function resolveDataTypes<Type extends { [key: string]: any }>(dataItem: Type) {
    const dataObject: { [key: string]: string | ReactNode } = {};

    Object.keys(dataItem).map((objectKey: string) => {
        if (typeof dataItem[objectKey] === "string") {
            dataObject[objectKey] = dataItem[objectKey];
        } else if (typeof dataItem[objectKey] === "object") {
            if (dataItem[objectKey] === null) {
                dataObject[objectKey] = "";
            } else {
                if (withKeyAccount(dataItem)) {
                    if (dataItem.keyAccount !== null) {
                        dataObject[objectKey] = dataItem.keyAccount.name;
                    }
                } else if (withCustomer(dataItem)) {
                    if (dataItem.customer !== null) {
                        dataObject[objectKey] = <Badge>{dataItem.customer.name}</Badge>;
                    }
                } else if (withName(dataItem[objectKey])) {
                    dataObject[objectKey] = dataItem[objectKey].name ?? "";
                }
            }
        }
    });
    return dataObject;
}

interface RecordDetailBoxProps<Type> {
    item: Type;
    buttonTitle?: string;
    clickHandler?: () => void;
}

export const RecordDetailBox = <Type extends { [key: string]: any }>({
    item,
    buttonTitle,
    clickHandler,
}: RecordDetailBoxProps<Type>) => {
    const detailDataObject = resolveDataTypes(item);

    return (
        <>
            <RecordDetailBoxStyled>
                {Object.keys(detailDataObject).map((objectKey: string) => (
                    <RecordProperty key={objectKey}>
                        <PropertyName>{splitAndCapitalize(objectKey)}</PropertyName>
                        <PropertyValue>{detailDataObject[objectKey]}</PropertyValue>
                    </RecordProperty>
                ))}
            </RecordDetailBoxStyled>

            {buttonTitle && (
                <ButtonWrapper>
                    <Button onClick={clickHandler} Icon={EditIcon}>
                        {buttonTitle}
                    </Button>
                </ButtonWrapper>
            )}
        </>
    );
};
