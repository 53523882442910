import { Modal } from "components/common/Modal";
import { RecordDetailBox } from "components/common/RecordDetailBox";
import { WidgetFrame } from "components/common/WidgetFrame";
import { AddCustomerForm } from "components/form/forms/AddCustomerForm";
import React, { useState } from "react";
import { useCurrentUserRole } from "store";
import { CustomerPayload, CustomerRequest, useUpdateCustomerMutation } from "store/services/customer-service";
import { Role } from "types/authTypes";

interface CustomerAttributesProps {
    customer: CustomerPayload;
}

export const CustomerAttributes: React.VFC<CustomerAttributesProps> = ({ customer }) => {
    const [showForm, setShowForm] = useState(false);
    const [editData, setEditData] = useState<any | null>(null);
    const [modalError, setModalError] = useState<string | undefined>();

    const role = useCurrentUserRole();
    const [updateCustomer, { isLoading: isUpdateLoading }] = useUpdateCustomerMutation();

    const handleUpdate = async (values: CustomerRequest) => {
        if (!editData) return;

        try {
            await updateCustomer({ customer: values, id: editData.id }).unwrap();
            setShowForm(false);
        } catch (err: any) {
            setModalError(err?.data?.error);
        }
    };

    const openModal = (isOpen: boolean) => {
        setShowForm(isOpen);
        setEditData(customer);
    };

    return (
        <>
            <WidgetFrame isLoading={isUpdateLoading}>
                {customer && (
                    <RecordDetailBox<CustomerPayload>
                        item={customer}
                        buttonTitle={
                            [Role.Superadmin, Role.KeyAccount].includes(role as Role) ? "Edit Customer" : undefined
                        }
                        clickHandler={() => openModal(true)}
                    />
                )}
            </WidgetFrame>
            <Modal isOpen={showForm} setOpen={openModal}>
                <AddCustomerForm
                    isSubmitQueryLoading={isUpdateLoading}
                    editData={editData}
                    handleSubmit={handleUpdate}
                    error={modalError}
                />
            </Modal>
        </>
    );
};
