import { tableCellBaseCSS, tableCellInnerBaseCSS } from "components/common/DashboardTable/styles";
import { Column, SortData } from "components/common/DashboardTable/types";
import { ArrowDownLongIcon, ArrowUpLongIcon } from "components/icons/Icons";
import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

const TableCellHeaderStyled = styled.th`
    ${tableCellBaseCSS}

    background-color: ${theme.color.gray[100]};
    cursor: pointer;

    border-top: 1px solid ${theme.color.gray[200]};
    border-bottom: 1px solid ${theme.color.gray[200]};

    color: ${theme.color.gray[700]};
    font-weight: 500;
    letter-spacing: 0.025rem;
    text-align: left;

    :hover {
        color: ${theme.color.gray[900]};
    }
`;

const TableCellHeaderContainerStyled = styled.div`
    display: flex;
    align-items: center;
`;

const TableCellHeaderInnerStyled = styled.div`
    ${tableCellInnerBaseCSS}
`;

const TableCellHeaderIconStyled = styled.div`
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
    flex-shrink: 0;
`;

interface Props {
    children?: React.ReactNode;
    column: Column<any, any>;
    sortData?: SortData<any>;
    onClick?: () => void;
}

const TableCellHeader: React.FC<Props> = (props) => {
    const { column, sortData } = props;
    const { label, dataIndex } = column;

    const isSortColumn = sortData && sortData.dataIndex === dataIndex;
    const SortIcon = sortData?.descending ? ArrowDownLongIcon : ArrowUpLongIcon;

    return (
        <TableCellHeaderStyled onClick={props.onClick} title={label} width={column.width}>
            <TableCellHeaderContainerStyled>
                <TableCellHeaderInnerStyled>{label}</TableCellHeaderInnerStyled>
                {isSortColumn && (
                    <TableCellHeaderIconStyled>
                        <SortIcon size={"12px"} />
                    </TableCellHeaderIconStyled>
                )}
            </TableCellHeaderContainerStyled>
        </TableCellHeaderStyled>
    );
};

export { TableCellHeader };
