import { CaretContentTitle } from "./CaretContentTitle";

import React from "react";
import styled from "styled-components";

const CaretContentStyled = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0.5rem 1rem;
    overflow: hidden;
`;

const CaretContentValueContainerStyled = styled.div`
    display: flex;
    font-size: 1.25rem;
    font-weight: 700;
    overflow: hidden;
`;

interface Props {
    children: React.ReactNode;
    title?: string;
}

const CaretContent: React.FC<Props> = ({ title, children }) => {
    return (
        <CaretContentStyled>
            {title && <CaretContentTitle>{title}</CaretContentTitle>}
            <CaretContentValueContainerStyled>{children}</CaretContentValueContainerStyled>
        </CaretContentStyled>
    );
};

export { CaretContent };
