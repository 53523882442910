import { GraphData } from "components/common/Graph";
import { rootApi } from "store/services/root-api";
import { DashboardQueryParams } from "store/services/types";
import { toQueryParams } from "store/services/utils";

export interface DispensesTotalByTypePayload {
    drinkType: string;
    drinkId: string;
    drinkName: string;
    count: number;
}

const BASE_PATH = "dispense";
const CACHE_TYPE = "Dispense";

const apiWithTag = rootApi.enhanceEndpoints({ addTagTypes: [CACHE_TYPE] });
export const dispenseApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getDispensesTotal: builder.query<number, DashboardQueryParams>({
            query: (params) => ({
                url: `${BASE_PATH}/total`,
                method: "GET",
                params: toQueryParams(params),
            }),
            providesTags: () => [{ type: CACHE_TYPE, id: "TOTAL" }],
        }),
        getDispensesTotalByType: builder.query<GraphData, DashboardQueryParams>({
            query: (params) => ({
                url: `${BASE_PATH}/total-by-type`,
                method: "GET",
                params: toQueryParams(params),
            }),
            providesTags: () => [{ type: CACHE_TYPE, id: "TOTAL_BY_TYPE" }],
        }),
        getDispensesTotalByPeriod: builder.query<GraphData, DashboardQueryParams>({
            query: (params) => ({
                url: `${BASE_PATH}/total-by-period`,
                method: "GET",
                params: toQueryParams(params),
            }),
            providesTags: () => [{ type: CACHE_TYPE, id: "TOTAL_BY_TYPE" }],
        }),
        getDispensesAverageByTypeAndHour: builder.query<GraphData, DashboardQueryParams>({
            query: (params) => ({
                url: `${BASE_PATH}/average-by-type-hour`,
                method: "GET",
                params: toQueryParams(params),
            }),
            providesTags: () => [{ type: CACHE_TYPE, id: "AVERAGE_BY_TYPE_AND_HOUR" }],
        }),
        getDispensesByType: builder.query<DispensesTotalByTypePayload[], DashboardQueryParams>({
            query: (params) => ({
                url: `${BASE_PATH}/by-type`,
                method: "GET",
                params: toQueryParams(params),
            }),
            providesTags: () => [{ type: CACHE_TYPE, id: "BY_TYPE" }],
        }),
        getWaterConsumption: builder.query<number, DashboardQueryParams>({
            query: (params) => ({
                url: `${BASE_PATH}/water-consumption`,
                method: "GET",
                params: toQueryParams(params),
            }),
            providesTags: () => [{ type: CACHE_TYPE, id: "WATER_CONSUMPTION" }],
        }),
        getMilkConsumption: builder.query<number, DashboardQueryParams>({
            query: (params) => ({
                url: `${BASE_PATH}/milk-consumption`,
                method: "GET",
                params: toQueryParams(params),
            }),
            providesTags: () => [{ type: CACHE_TYPE, id: "MILK_CONSUMPTION" }],
        }),
        getBrew: builder.query<{ left: number; right: number }, DashboardQueryParams>({
            query: (params) => ({
                url: `${BASE_PATH}/brew`,
                method: "GET",
                params: toQueryParams(params),
            }),
            providesTags: () => [{ type: CACHE_TYPE, id: "BREW" }],
        }),
    }),
});

export const {
    useGetDispensesTotalQuery,
    useGetDispensesTotalByTypeQuery,
    useGetDispensesTotalByPeriodQuery,
    useGetDispensesAverageByTypeAndHourQuery,
    useGetDispensesByTypeQuery,
    useGetWaterConsumptionQuery,
    useGetMilkConsumptionQuery,
    useGetBrewQuery,
} = dispenseApi;
