import { TreeSelect } from "components/common/TreeSelect";
import { Data } from "components/common/TreeSelect/types";
import { FilterIcon } from "components/icons/Icons";
import { DashboardPickerLabel } from "pages/DashboardPage/DashboardPickerLabel";
import React from "react";
import { useDispatch } from "react-redux";
import { setDevices } from "store";
import { useDashboardDevices } from "store/hooks/useDashboardDevices";
import { useGetDevicesInKeyAccountCustomerQuery } from "store/services/device-service";

const CUSTOMER_TYPE = "customer";
const NO_CUSTOMER_LABEL = "No customer";

const EntityPicker: React.FC = () => {
    const selected = useDashboardDevices();
    const { data } = useGetDevicesInKeyAccountCustomerQuery();
    const dispatch = useDispatch();

    // Append id to the customer label
    const dataWithCustomerIds = data?.map((keyAccount: Data) => ({
        ...keyAccount,
        children: (keyAccount.children ?? []).map((customer: Data) => ({
            ...customer,
            label:
                customer.type === CUSTOMER_TYPE && customer.label !== NO_CUSTOMER_LABEL
                    ? `${customer.label} (${customer.customerNumber})`
                    : customer.label,
        })),
    }));

    return (
        <div>
            <DashboardPickerLabel Icon={FilterIcon} value={"Entity (key account, customer or machine)"} />
            <TreeSelect
                data={dataWithCustomerIds}
                selected={selected}
                onChange={(nodes) => {
                    dispatch(setDevices(nodes.map((n) => n.value)));
                }}
            />
        </div>
    );
};

export { EntityPicker };
