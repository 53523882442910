import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

interface Props {
    children: React.ReactNode;
}

const CaretContentTitleStyled = styled.div`
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.025rem;
    color: ${theme.color.gray[500]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const CaretContentTitle: React.FC<Props> = ({ children }) => {
    return (
        <CaretContentTitleStyled title={typeof children === "string" ? children : undefined}>
            {children}
        </CaretContentTitleStyled>
    );
};

export { CaretContentTitle };
