import { Badge } from "./Badge";

import React, { ButtonHTMLAttributes } from "react";
import { useNavigate } from "react-router";

interface NavigateBadgeProps {
    path: string;
}

export const NavigateBadge: React.FC<NavigateBadgeProps & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick">> = ({
    path,
    children,
    ...props
}) => {
    const navigate = useNavigate();
    return (
        <Badge {...props} onClick={() => navigate(path)}>
            {children}
        </Badge>
    );
};
