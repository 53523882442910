import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

interface PageHeaderProps {
    title: string;
    children?: React.ReactNode;
}

const PageHeaderStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const PageHeaderTitleStyled = styled.h1`
    color: ${theme.color.gray[900]};

    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.025rem;
`;

export const PageHeader: React.FC<PageHeaderProps> = ({ title, children }) => {
    return (
        <PageHeaderStyled>
            <PageHeaderTitleStyled>{title}</PageHeaderTitleStyled>
            {children}
        </PageHeaderStyled>
    );
};
