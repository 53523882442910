import { Caret } from "components/common/Caret";
import { WaterIcon } from "components/icons/Icons";
import React from "react";
import { useDashboardDeviceTypes } from "store/hooks/useDashboardDeviceTypes";
import { useDashboardDevices } from "store/hooks/useDashboardDevices";
import { useDashboardTimeRange } from "store/hooks/useDashboardTimeRange";
import { useGetWaterConsumptionQuery } from "store/services/dispense-service";

const WaterConsumption: React.FC = () => {
    const timeRange = useDashboardTimeRange();
    const devices = useDashboardDevices();
    const deviceTypes = useDashboardDeviceTypes();
    const { data, isLoading, isFetching } = useGetWaterConsumptionQuery({ ...timeRange, devices, deviceTypes });

    return (
        <Caret title={"Water use per day"} Icon={WaterIcon} isLoading={isLoading || isFetching} colSpan={3}>
            {data !== undefined && <Caret.Value unit={" l"}>{data}</Caret.Value>}
        </Caret>
    );
};

export { WaterConsumption };
