import { IconComponent } from "components/icons/Icons";
import React from "react";
import styled, { css } from "styled-components";
import { theme } from "styles/theme";

export type ButtonVariant = "primary" | "danger";

export interface ButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    type?: "button" | "submit" | "reset";
    className?: string;
    title?: string;
    isLoading?: boolean;
    disabled?: boolean;
    variant?: ButtonVariant;
    Icon?: IconComponent;
}

export type StyledButtonProps = Pick<ButtonProps, "variant">;

const ButtonVariantMixin = (variant?: ButtonVariant) => {
    switch (variant) {
        case "primary":
            return css`
                border: 1px solid ${theme.color.primary[600]};
                background-color: ${theme.color.primary[500]};
                color: ${theme.color.white};

                :hover:not([disabled]) {
                    background-color: ${theme.color.primary[600]};
                }

                :disabled {
                    background-color: ${theme.color.primary[300]};
                    border-color: ${theme.color.primary[300]};
                }
            `;
        default:
            return css`
                border: 1px solid ${theme.color.gray[300]};
                background-color: ${theme.color.white};
                color: ${theme.color.gray[900]};

                :hover:not([disabled]) {
                    background-color: ${theme.color.gray[100]};
                }

                :disabled {
                    color: ${theme.color.gray[500]};
                }
            `;
    }
};

const ButtonStyled = styled.button<StyledButtonProps>`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.75rem;

    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.025rem;

    box-shadow: ${theme.boxShadow.sm};
    border-radius: ${theme.borderRadius.md};

    ${(props) => {
        return ButtonVariantMixin(props.variant);
    }}

    transition: background-color 0.15s ease-out;

    :disabled {
        cursor: not-allowed;
    }

    svg {
        margin-left: -0.25rem;
        margin-right: 0.5rem;
    }
`;

export const Button: React.FC<ButtonProps> = (props) => {
    const { onClick, variant, type = "button", Icon, disabled, children, className } = props;

    return (
        <ButtonStyled type={type} onClick={onClick} disabled={disabled} className={className} variant={variant}>
            {Icon && <Icon size={"14px"} />}
            {children}
        </ButtonStyled>
    );
};
