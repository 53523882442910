import { Caret } from "components/common/Caret";
import { BrewIcon } from "components/icons/Icons";
import React from "react";
import { useDashboardDeviceTypes } from "store/hooks/useDashboardDeviceTypes";
import { useDashboardDevices } from "store/hooks/useDashboardDevices";
import { useDashboardTimeRange } from "store/hooks/useDashboardTimeRange";
import { useGetBrewQuery } from "store/services/dispense-service";

const Brew: React.FC = () => {
    const timeRange = useDashboardTimeRange();
    const devices = useDashboardDevices();
    const deviceTypes = useDashboardDeviceTypes();
    const { data, isLoading, isFetching } = useGetBrewQuery({ ...timeRange, devices, deviceTypes });

    return (
        <Caret title={"Brew"} Icon={BrewIcon} isLoading={isLoading || isFetching} colSpan={3}>
            {data !== undefined && data.left !== null && data.right !== null && (
                <React.Fragment>
                    <Caret.Value unit={"%"}>{data.left}</Caret.Value>
                    <Caret.ValueSeparator>-</Caret.ValueSeparator>
                    <Caret.Value unit={"%"}>{data.right}</Caret.Value>
                </React.Fragment>
            )}
        </Caret>
    );
};

export { Brew };
