import { Loader } from "components/common/Loader";
import { CreateProvisioningTokenForm } from "components/form/forms/CreateProvisioningTokenForm";
import { LoaderOverlap } from "components/styled/LoaderOverlap";
import { ProvisioningTokenRequest } from "store/services/provisioning-service";

type Props = {
    handleSubmit: (values: ProvisioningTokenRequest) => void;
    error?: string | undefined;
    isLoading?: boolean;
};

export function CreateFormModalView({ handleSubmit, error, isLoading }: Props) {
    return (
        <>
            <CreateProvisioningTokenForm handleSubmit={handleSubmit} error={error} />
            {isLoading && (
                <LoaderOverlap>
                    <Loader size={"4rem"} />
                </LoaderOverlap>
            )}
        </>
    );
}
