import { readCellValue } from "components/common/table/Table/utils";
import { Column, ColumnData, Row, isColumnData } from "components/common/table/types";
import { normalize } from "utils/string";

interface UseSearchOptions<T extends Row> {
    searchValue: string;
    columns: Column<T>[];
    data?: T[];
}

export const useSearch = <T extends Row>(options: UseSearchOptions<T>) => {
    const searchValue = normalize(options.searchValue);

    const searchedData = options.data?.filter((row) => {
        const columnStrings = (options.columns.filter((column) => isColumnData(column)) as ColumnData<T>[]).map(
            (column) => {
                const value = row[column.dataIndex];
                const valueString = readCellValue(column, value);
                const valueStringNormalized = normalize(valueString);
                return valueStringNormalized;
            }
        );
        const foundMatchInCell = columnStrings.reduce((acc, curr) => {
            if (acc === true) return true;
            return curr.includes(searchValue);
        }, false);
        return foundMatchInCell;
    });

    return searchedData;
};
