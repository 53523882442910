import { Button } from "components/common/Button";
import { WidgetFrame } from "components/common/WidgetFrame";
import { DataTable } from "components/common/table/DataTable";
import { CellWrapperComponentType, Column, ColumnActionComponent } from "components/common/table/types";
import { ApprovalIcon, CheckIcon, CloseIcon } from "components/icons/Icons";
import { PendingProvisioningRequestModals } from "pages/ProvisioningPage/PendingProvisioningRequestModals";
import React, { useMemo, useState } from "react";
import { ProvisioningOperationPayload, useGetProvisioningOperationsQuery } from "store/services/provisioning-service";
import styled from "styled-components";

const LineWrappableCell = styled.div`
    white-space: normal;
`;

const LineWrappableCellWrapper: CellWrapperComponentType<ProvisioningOperationPayload> = ({ value }) => (
    <LineWrappableCell>{value}</LineWrappableCell>
);

export const PendingProvisioningRequestsWidget: React.VFC = () => {
    const [requestIdForRejection, setRequestIdForRejection] = useState<string | null>(null);
    const [requestIdForApproval, setRequestIdForApproval] = useState<string | null>(null);
    const [deviceId, setDeviceId] = useState<string | null>(null);

    const { data: provisioningRequests, isFetching } = useGetProvisioningOperationsQuery({
        state: "Pending",
    });

    const activeProvisioningRequests = useMemo(
        () => provisioningRequests?.filter((request) => new Date(request.expirationTime) > new Date()),
        [provisioningRequests]
    );

    const ApproveRequestButton: ColumnActionComponent<ProvisioningOperationPayload> = ({ row }) => (
        <Button
            onClick={() => {
                setRequestIdForApproval(row.provisioningOperationId);
                setDeviceId(row.deviceId ?? "");
            }}
            Icon={CheckIcon}
        >
            Approve
        </Button>
    );
    const CancelRequestButton: ColumnActionComponent<ProvisioningOperationPayload> = ({ row }) => (
        <Button
            onClick={() => {
                setRequestIdForRejection(row.provisioningOperationId);
                setDeviceId(row.deviceId ?? "");
            }}
            Icon={CloseIcon}
        >
            Cancel
        </Button>
    );

    const columns: Column<ProvisioningOperationPayload>[] = [
        {
            type: "data",
            dataIndex: "createdTime",
            dataType: "date",
            label: "Created At",
            sortable: true,
            CellWrapper: LineWrappableCellWrapper,
            width: 135,
        },
        {
            type: "data",
            dataIndex: "expirationTime",
            dataType: "date",
            label: "Expires At",
            sortable: true,
            CellWrapper: LineWrappableCellWrapper,
            width: 135,
        },
        {
            type: "data",
            dataIndex: "provisioningOperationId",
            dataType: "string",
            label: "Operation Id",
            sortable: true,
            CellWrapper: LineWrappableCellWrapper,
        },
        {
            type: "data",
            dataIndex: "provisioningTokenId",
            dataType: "string",
            label: "Provisioning Token Id",
            sortable: true,
            CellWrapper: LineWrappableCellWrapper,
        },
        {
            type: "data",
            dataIndex: "deviceId",
            dataType: "string",
            label: "Machine Id",
            sortable: true,
            CellWrapper: LineWrappableCellWrapper,
        },
        {
            type: "data",
            dataIndex: "verificationCode",
            dataType: "string",
            label: "Verification Code",
            sortable: true,
            CellWrapper: LineWrappableCellWrapper,
        },
        { type: "actions", components: [ApproveRequestButton, CancelRequestButton], width: 220 },
    ];

    return (
        <>
            <WidgetFrame isLoading={isFetching} height={"100%"} Icon={ApprovalIcon} title={"Pending Requests"}>
                <DataTable<ProvisioningOperationPayload>
                    data={activeProvisioningRequests}
                    columns={columns}
                    configuration={{ disableDownload: true }}
                    fallbackText={{
                        noData: "No pending requests...",
                    }}
                    initialSortConfig={{ dataIndex: "createdTime", descending: true }}
                />
            </WidgetFrame>
            <PendingProvisioningRequestModals
                deviceId={deviceId}
                requestIdForApproval={requestIdForApproval}
                requestIdForRejection={requestIdForRejection}
                setRequestIdForApproval={setRequestIdForApproval}
                setRequestIdForRejection={setRequestIdForRejection}
            />
        </>
    );
};
