import { Button } from "components/common/Button";
import { ModalActions } from "components/common/ModalActions";
import { ModalTitle } from "components/common/ModalTitle";
import { DropDownInput } from "components/form/DropDownInput";
import { ErrorPanel } from "components/form/ErrorPanel";
import { TextInput } from "components/form/TextInput";
import { CheckIcon } from "components/icons/Icons";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { DevicePayload, DeviceRequest, DeviceTypePayload } from "store/services/device-service";
import styled from "styled-components";
import * as Yup from "yup";

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const validationSchema = Yup.object().shape({
    id: Yup.string().min(2, "Device id is too short!").max(50, "Too Long!").required("Device id is required"),
    type: Yup.array().length(1, "Type is required").required("Type is required"),
    password: Yup.string().required("Password is required"),
    vpnCertificate: Yup.string().required("VPN certificate is required"),
});

export type FormData = Omit<DeviceRequest, "type" | "keyAccount"> & {
    type: string[];
    keyAccount: string[];
};

export type FormSubmitData = DeviceRequest;

export const baseInitialValues: FormData = {
    id: "",
    type: [],
    vpnCertificate: "",
    certificate: "",
    milk: "",
    milkType: "",
    simCardNumber: "",
    modemNumber: "",
    connectionType: "",
    machineModuleSerialNumber: "",
    password: "",
    keyAccount: [],
};

interface DeviceFormProps {
    isCreate?: boolean;
    handleSubmit: (values: FormSubmitData, actions: FormikHelpers<FormData>) => void;
    initialData: DevicePayload | null;
    deviceTypes: DeviceTypePayload[] | undefined;
    error?: string;
}

const toDeviceTypeData = (deviceTypeData?: DeviceTypePayload[]) =>
    deviceTypeData
        ?.map((x) => ({
            id: x.id.toString(),
            value: x.id.toString(),
            label: x.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

export const DeviceForm: React.VFC<DeviceFormProps> = ({
    handleSubmit,
    initialData,
    deviceTypes,
    error,
    isCreate = false,
}) => {
    let initialValues = baseInitialValues;

    if (initialData) {
        const {
            id,
            vpnCertificate,
            certificate,
            milk,
            milkType,
            simCardNumber,
            modemNumber,
            connectionType,
            machineModuleSerialNumber,
            password,
        } = initialData;

        initialValues = {
            ...initialValues,
            id,
            type: [],
            vpnCertificate,
            certificate,
            milk,
            milkType,
            simCardNumber,
            modemNumber,
            connectionType,
            machineModuleSerialNumber,
            password,
        };
    }

    const handleFormSubmit = (values: FormData, actions: FormikHelpers<FormData>) => {
        const { type, keyAccount, ...baseValues } = values;
        handleSubmit(
            {
                ...baseValues,
                type: { id: Number(type[0]) },
                keyAccount: keyAccount?.[0] ? { id: Number(keyAccount[0]) } : null,
            },
            actions
        );
    };

    return (
        <Formik<FormData>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize={true}
        >
            {({ isSubmitting }) => {
                return (
                    <FormStyled>
                        <ModalTitle>{isCreate ? "Create a new machine" : "Edit machine"}</ModalTitle>
                        <TextInput name={"id"} label={"Device Id"} disabled={isCreate ? false : true} required />
                        <DropDownInput
                            name={"type"}
                            mode={"simpleSelect"}
                            dropDownData={toDeviceTypeData(deviceTypes)}
                            required
                        />
                        <TextInput name={"vpnCertificate"} label={"VPN Certificate"} required />
                        <TextInput name={"certificate"} />
                        <TextInput name={"simCardNumber"} label={"SIM Card Number"} />
                        <TextInput name={"modemNumber"} />
                        <TextInput name={"connectionType"} />
                        <TextInput name={"machineModuleSerialNumber"} />
                        <TextInput name={"password"} required />
                        <ErrorPanel>{error}</ErrorPanel>
                        <ModalActions>
                            <Button type={"submit"} disabled={isSubmitting} variant={"primary"} Icon={CheckIcon}>
                                Submit
                            </Button>
                        </ModalActions>
                    </FormStyled>
                );
            }}
        </Formik>
    );
};
