import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

export const ErrorTextStyled = styled.div`
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: ${theme.color.error[700]};
`;

interface ErrorTextProps {
    error?: string;
    touched?: boolean;
}

export const ErrorText: React.VFC<ErrorTextProps> = ({ error, touched }) => {
    const hasError = !!error && !!touched;
    if (!hasError) return null;

    return <ErrorTextStyled>{error}</ErrorTextStyled>;
};
