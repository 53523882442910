import { Button } from "components/common/Button";
import { DownloadIcon } from "components/icons/Icons";
import styled from "styled-components";

interface Props {
    onClick: () => void;
}

const ToolbarButton = styled(Button)`
    height: 100%;
`;

const DataTableDownload = ({ onClick }: Props) => {
    return (
        <ToolbarButton title={"Download this table in a CSV file"} onClick={onClick} Icon={DownloadIcon}>
            CSV
        </ToolbarButton>
    );
};

export { DataTableDownload };
