import { Variant } from "components/common/Graph/types";
import { GraphBarIcon, GraphPieIcon } from "components/icons/Icons";
import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

const GraphTitleStyled = styled.div`
    overflow: hidden;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.0125rem;
    color: ${theme.color.gray[900]};
`;

const GraphTitleInnerStyled = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const GraphTitleIconStyled = styled.div`
    display: flex;
    justify-content: center;
    color: ${theme.color.gray[500]};
    margin-right: 0.75rem;
    flex-shrink: 0;
`;

interface Props {
    children?: React.ReactNode;
    variant: Variant;
}

const GraphTitle: React.FC<Props> = ({ variant, children }) => {
    const Icon = variant === "barStacked" ? GraphBarIcon : GraphPieIcon;
    return (
        <GraphTitleStyled title={typeof children === "string" ? children : undefined}>
            <GraphTitleIconStyled>
                <Icon size={"16px"} />
            </GraphTitleIconStyled>
            <GraphTitleInnerStyled>{children}</GraphTitleInnerStyled>
        </GraphTitleStyled>
    );
};

export { GraphTitle };
