import { readCellValue } from "components/common/table/Table/utils";
import { Column, ColumnData, isColumnData, Row } from "components/common/table/types";
import { downloadCSV, toFileNameWithDates } from "utils/download";
import { splitAndCapitalize } from "utils/string";

interface UseDownloadOptions<T extends Row> {
    columns: Column<T>[];
    data?: T[];
    exportFileName?: string;
}

export const useDownload = <T extends Row>(options: UseDownloadOptions<T>) => {
    const { columns, data } = options;

    const handleDownload = () => {
        if (!data) return;

        const headers = (columns.filter((column) => isColumnData(column)) as ColumnData<T>[]).map(
            (column) => column.label ?? splitAndCapitalize(String(column.dataIndex))
        );
        const content = data.map((row) =>
            (columns.filter((column) => isColumnData(column)) as ColumnData<T>[]).map((column) => {
                const value = row[column.dataIndex];
                const transformedValue = column.valueTransform?.(value) ?? value;
                const stringifiedValue = readCellValue(column, transformedValue);
                return stringifiedValue;
            })
        );

        const fileName = toFileNameWithDates(options?.exportFileName ?? "export", { dateTimeFrom: new Date() });
        downloadCSV(fileName, [headers, ...content]);
    };

    return { handleDownload };
};
