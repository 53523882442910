import { KeyAccountId, KeyAccountPayload } from "store/services/key-account-service";
import { rootApi } from "store/services/root-api";

interface Customer {
    name: string;
    customerNumber: string;
    city: string;
    postcode: string;
    street: string;
    telephone: string | null;
}

export interface CustomerPayload extends Customer {
    id: number;
    keyAccount: KeyAccountPayload;
}

export interface CustomerRequest extends Customer {
    keyAccount: KeyAccountId | null;
}

export interface CustomerUpdateRequest {
    customer: CustomerRequest;
    id: number;
}

const BASE_PATH = "customers";
const CACHE_TYPE = "Customer";
const CACHE_ID = "LIST";

const apiWithTag = rootApi.enhanceEndpoints({ addTagTypes: [CACHE_TYPE] });
export const customerApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getCustomers: builder.query<CustomerPayload[], void>({
            query: () => BASE_PATH,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: CACHE_TYPE, id }) as const),
                          { type: CACHE_TYPE, id: CACHE_ID },
                      ]
                    : [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        getOneCustomer: builder.query<CustomerPayload, string>({
            query: (id) => ({
                url: `${BASE_PATH}/${id}`,
                method: "GET",
            }),
            providesTags: () => [{ type: CACHE_TYPE }],
        }),
        addCustomer: builder.mutation<CustomerPayload, CustomerRequest>({
            query: (body) => ({
                url: BASE_PATH,
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        updateCustomer: builder.mutation<void, CustomerUpdateRequest>({
            query: ({ customer, id }) => ({
                url: `${BASE_PATH}/${id}`,
                method: "PUT",
                body: customer,
            }),
            invalidatesTags: [{ type: CACHE_TYPE }, { type: CACHE_TYPE, id: CACHE_ID }],
        }),
        deleteCustomer: builder.mutation<void, number>({
            query: (id) => ({
                url: `${BASE_PATH}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
    }),
});

export const {
    useGetCustomersQuery,
    useGetOneCustomerQuery,
    useAddCustomerMutation,
    useDeleteCustomerMutation,
    useUpdateCustomerMutation,
} = customerApi;
