import { DashboardTable, TableProps } from "components/common/DashboardTable/DashboardTable";
import { Column } from "components/common/DashboardTable/types";
import { NavigateBadge } from "components/common/NavigateBadge";
import { OnlineIcon } from "components/icons/Icons";
import React from "react";
import { routes } from "routing/routes";
import { useDashboardDeviceTypes } from "store/hooks/useDashboardDeviceTypes";
import { useDashboardDevices } from "store/hooks/useDashboardDevices";
import { useDashboardTimeRange } from "store/hooks/useDashboardTimeRange";
import { DeviceWithConsumptionPayload, useGetDevicesWithConsumptionQuery } from "store/services/device-service";
import styled from "styled-components";
import { theme } from "styles/theme";
import { toFileNameWithDates } from "utils/download";

const OnlineChip = styled.div`
    text-align: center;
    color: ${theme.color.success[700]};
`;

const NavigateBadgeWithOverflow = styled(NavigateBadge)`
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

type Row = DeviceWithConsumptionPayload;
const columns: Column<Row, keyof Row>[] = [
    {
        dataIndex: "isOnline",
        dataType: "boolean",
        label: "",
        width: 40,
        CellWrapper: ({ value }) =>
            value === "true" ? (
                <OnlineChip title={"Machine is online"}>
                    <OnlineIcon size={"16px"} />
                </OnlineChip>
            ) : null,
    },
    {
        dataIndex: "lastTimeOnline",
        dataType: "date",
        label: "Last Online",
        width: 180,
    },
    {
        dataIndex: "type",
        dataType: "string",
        label: "Type",
    },
    {
        dataIndex: "id",
        dataType: "string",
        label: "Id",
        fontStyle: "mono",
        width: 125,
        CellWrapper: ({ value }) => (
            <NavigateBadgeWithOverflow path={routes.machineDetail.getPath({ machineId: value })}>
                {value}
            </NavigateBadgeWithOverflow>
        ),
    },
    {
        dataIndex: "customerName",
        dataType: "string",
        label: "Customer",
        CellWrapper: ({ value, row }) =>
            (row.customerId && (
                <NavigateBadgeWithOverflow path={routes.customerDetail.getPath({ customerId: row.customerId })}>
                    {value}
                </NavigateBadgeWithOverflow>
            )) || <>{value}</>,
    },
    {
        dataIndex: "keyAccountName",
        dataType: "string",
        label: "Key Account",
    },
    {
        dataIndex: "lastTimeOnlineIP",
        dataType: "string",
        label: "IP address",
        fontStyle: "mono",
        width: 150,
    },
    {
        dataIndex: "coffeeDrinksPerDay",
        dataType: "number",
        label: "Coffee #/day",
        width: 110,
    },
    {
        dataIndex: "coffeeDrinks",
        dataType: "number",
        label: "Coffee #",
        width: 80,
    },
    {
        dataIndex: "milkDrinksPerDay",
        dataType: "number",
        label: "Milk #/day",
        width: 110,
    },
    {
        dataIndex: "milkDrinks",
        dataType: "number",
        label: "Milk #",
        width: 80,
    },
    {
        dataIndex: "waterDrinksPerDay",
        dataType: "number",
        label: "Water #/day",
        width: 110,
    },
    {
        dataIndex: "waterDrinks",
        dataType: "number",
        label: "Water #",
        width: 80,
    },
];

const DashboardTableStyled: (props: TableProps<Row>) => JSX.Element = styled(DashboardTable)`
    & table {
        min-width: 1500px;
    }
`;

const Devices: React.FC = () => {
    const timeRange = useDashboardTimeRange();
    const devices = useDashboardDevices();
    const deviceTypes = useDashboardDeviceTypes();
    const { data, isLoading, isFetching } = useGetDevicesWithConsumptionQuery({ ...timeRange, devices, deviceTypes });

    const exportFileName = toFileNameWithDates("machines", timeRange);

    return (
        <DashboardTableStyled
            title={"Machines"}
            exportFileName={exportFileName}
            columns={columns}
            data={data}
            isLoading={isLoading || isFetching}
            rowSpan={4}
            colSpan={16}
        />
    );
};

export { Devices };
