import { rootApi } from "store/services/root-api";
import { DashboardQueryParams } from "store/services/types";
import { toQueryParams } from "store/services/utils";

export interface BasicDevicePayload {
    id: string;
    type: DeviceTypePayload;
    simCardNumber: string;
    machineModuleSerialNumber: string;
}

export interface DevicePayload extends BasicDevicePayload {
    vpnCertificate: string;
    certificate: string;
    milk: string;
    milkType: string;
    modemNumber: string;
    connectionType: string;
    password: string;
}

export interface LastSyncPayload {
    device: string;
    lastSyncTime: string;
}

export interface DeviceWithLastSyncPayload extends DevicePayload {
    ipAddress: string;
}

export interface DeviceRequest extends Omit<DevicePayload, "type"> {
    keyAccount: { id: number } | null;
    type: { id: number };
}

export interface DeviceUpdateRequest {
    device: DeviceRequest;
    id: string;
}

export interface DevicesTotalOnlinePayload {
    onlineTotal: number;
    total: number;
}

export interface DeviceWithConsumptionPayload extends Pick<DevicePayload, "id" | "type"> {
    isOnline: boolean;
    lastTimeOnline: Date | null;
    lastTimeOnlineIP: string | null;
    customerName: string | null;
    customerId: string | null;
    keyAccountName: string | null;
    coffeeDrinksPerDay: number;
    coffeeDrinks: number;
    milkDrinksPerDay: number;
    milkDrinks: number;
    waterDrinksPerDay: number;
    waterDrinks: number;
}

export interface DeviceTypePayload {
    id: number;
    name: string;
}

const BASE_PATH = "devices";
const CACHE_TYPE = "Device";
const CACHE_TYPE_CONSUMPTION = "DeviceWithConsumption";
const CACHE_TYPE_IN_KAC = "DeviceInKeyAccountCustomer";
const CACHE_ID = "LIST";

const DEVICE_TYPE_BASE_PATH = "device-types";
const DEVICE_TYPE_CACHE_TYPE = "DeviceTypes";
const DEVICE_TYPE_CACHE_ID = "DEVICE_TYPE_LIST";

const apiWithTag = rootApi.enhanceEndpoints({
    addTagTypes: [CACHE_TYPE, CACHE_TYPE_CONSUMPTION, CACHE_TYPE_IN_KAC, DEVICE_TYPE_CACHE_TYPE],
});
export const deviceApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getDevices: builder.query<DevicePayload[], void>({
            query: () => BASE_PATH,
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "Device" as const, id })), { type: CACHE_TYPE, id: CACHE_ID }]
                    : [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        getOneDevice: builder.query<DeviceWithLastSyncPayload, string>({
            query: (id) => ({
                url: `${BASE_PATH}/${id}`,
                method: "GET",
            }),
            providesTags: () => [{ type: "Device" }],
        }),
        addDevice: builder.mutation<BasicDevicePayload, DeviceRequest>({
            query: (body) => ({
                url: BASE_PATH,
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        updateDevice: builder.mutation<void, DeviceUpdateRequest>({
            query: ({ device, id }) => ({
                url: `${BASE_PATH}/${id}`,
                method: "PUT",
                body: device,
            }),
            invalidatesTags: [{ type: CACHE_TYPE }],
        }),
        deleteDevice: builder.mutation<void, string>({
            query: (id) => ({
                url: `${BASE_PATH}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: CACHE_TYPE, id: CACHE_ID }],
        }),
        getDevicesTotalOnline: builder.query<DevicesTotalOnlinePayload, DashboardQueryParams>({
            query: (params) => ({
                url: `${BASE_PATH}/total-online`,
                method: "GET",
                params: toQueryParams(params),
            }),
            providesTags: () => [{ type: CACHE_TYPE, id: "TOTAL_ONLINE" }],
        }),
        getDevicesWithConsumption: builder.query<DeviceWithConsumptionPayload[], DashboardQueryParams>({
            query: (params) => ({
                url: `${BASE_PATH}/with-consumption`,
                method: "GET",
                params: toQueryParams(params),
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: CACHE_TYPE_CONSUMPTION, id }) as const),
                          { type: CACHE_TYPE_CONSUMPTION, id: CACHE_ID },
                      ]
                    : [{ type: CACHE_TYPE_CONSUMPTION, id: CACHE_ID }],
        }),
        getDevicesInKeyAccountCustomer: builder.query<any[], void>({
            query: () => ({
                url: `${BASE_PATH}/in-key-account-customer`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: CACHE_TYPE_IN_KAC, id }) as const),
                          { type: CACHE_TYPE_IN_KAC, id: CACHE_ID },
                      ]
                    : [{ type: CACHE_TYPE_IN_KAC, id: CACHE_ID }],
        }),
        getDeviceTypes: builder.query<DeviceTypePayload[], void>({
            query: () => DEVICE_TYPE_BASE_PATH,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: DEVICE_TYPE_CACHE_TYPE, id }) as const),
                          { type: DEVICE_TYPE_CACHE_TYPE, id: DEVICE_TYPE_CACHE_ID },
                      ]
                    : [{ type: DEVICE_TYPE_CACHE_TYPE, id: DEVICE_TYPE_CACHE_ID }],
        }),
    }),
});

export const {
    useGetDevicesQuery,
    useAddDeviceMutation,
    useDeleteDeviceMutation,
    useUpdateDeviceMutation,
    useGetOneDeviceQuery,
    useGetDevicesTotalOnlineQuery,
    useGetDevicesWithConsumptionQuery,
    useGetDevicesInKeyAccountCustomerQuery,
    useGetDeviceTypesQuery,
} = deviceApi;
