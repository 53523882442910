import { Caret } from "components/common/Caret";
import { OnlineIcon } from "components/icons/Icons";
import React from "react";
import { useDashboardDeviceTypes } from "store/hooks/useDashboardDeviceTypes";
import { useDashboardDevices } from "store/hooks/useDashboardDevices";
import { useDashboardTimeRange } from "store/hooks/useDashboardTimeRange";
import { useGetDevicesTotalOnlineQuery } from "store/services/device-service";

const OnlineDevices: React.FC = () => {
    const timeRange = useDashboardTimeRange();
    const devices = useDashboardDevices();
    const deviceTypes = useDashboardDeviceTypes();
    const { data, isLoading, isFetching } = useGetDevicesTotalOnlineQuery({ ...timeRange, devices, deviceTypes });

    return (
        <Caret
            title={"Online"}
            tooltip={"Online devices - does not take time range filters into consideration."}
            variant={"success"}
            Icon={OnlineIcon}
            isLoading={isLoading || isFetching}
            colSpan={3}
        >
            {data !== undefined && (
                <React.Fragment>
                    <Caret.Value>{data.onlineTotal}</Caret.Value>
                    <Caret.ValueSeparator>/</Caret.ValueSeparator>
                    <Caret.Value>{data.total}</Caret.Value>
                </React.Fragment>
            )}
        </Caret>
    );
};

export { OnlineDevices };
