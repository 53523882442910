import { css } from "styled-components";
import { theme } from "styles/theme";

export interface Options {
    contentPosition?: "absolute" | "relative";
}

export const treeselectCSS = css<Options>`
    &.react-dropdown-tree-select .dropdown {
        width: 100%;
        min-width: 8rem;
        height: 100%;

        background-color: ${theme.color.white};
        border: 1px solid ${theme.color.gray[300]};
        border-radius: ${theme.borderRadius.md};
        box-shadow: ${theme.boxShadow.sm};

        font-size: 0.875rem;
        line-height: normal;

        :focus-within {
            border: 2px solid ${theme.color.primary[500]};
        }

        a.dropdown-trigger {
            width: 100%;
            height: unset;
            max-height: unset;
            padding: 0.25rem 0.75rem;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border: none;
            line-height: unset;
        }

        a.dropdown-trigger .tag-item {
            margin: 0.25rem 0.5rem 0.25rem 0;
        }

        a.dropdown-trigger .tag-item:last-child {
            margin-right: 0;
        }

        a.dropdown-trigger .tag {
            overflow: hidden;
            padding: 0.25rem 0.5rem;

            display: flex;
            align-items: center;

            background-color: ${theme.color.primary[500]};
            color: ${theme.color.white};
            border: 0;
            border-radius: ${theme.borderRadius.sm};
        }

        a.dropdown-trigger .tag .tag-remove {
            margin-left: -0.5rem;
            padding: 0;

            color: unset;
            font-size: unset;
            line-height: unset;
            background-color: unset;
            font-weight: 600;

            visibility: hidden;
        }

        a.dropdown-trigger .tag .tag-remove::after {
            visibility: visible;
            content: "x";

            margin: -0.25rem -0.5rem -0.25rem 0.5rem;
            padding: 0.25rem 0.5rem;

            font-weight: 600;
        }

        a.dropdown-trigger .tag .tag-remove:hover::after {
            color: ${theme.color.primary[50]};
            background-color: ${theme.color.primary[600]};
        }

        .dropdown-trigger input {
            padding: 0.25rem 0.25rem;
            width: 100%;
            height: unset;

            border: none;
            background-color: unset;
        }

        a.dropdown-trigger.arrow::after {
            margin: 0 0.25rem 0 0.75rem;
            vertical-align: middle;
            color: ${theme.color.gray[500]};
        }

        .dropdown-content {
            overflow: auto;
            position: ${({ contentPosition }) => contentPosition};
            z-index: 2;
            padding: 0.5rem;
            margin-top: 0.25rem;
            max-height: 20rem;

            background: ${theme.color.white};
            border: 1px solid ${theme.color.gray[200]};
            border-radius: ${theme.borderRadius.sm} !important;
            box-shadow: ${theme.boxShadow.md};
        }

        .dropdown-content .node {
            padding: 0.25rem 0;
            display: flex;
            align-items: center;
        }

        .dropdown-content .node.focused {
            background: ${theme.color.gray[100]};
            border-radius: 0.25rem;
        }

        .dropdown-content .node .toggle {
            width: 1.25rem;
            height: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
        }

        .dropdown-content .node .toggle:hover {
            background-color: ${theme.color.gray[200]};
            border-radius: ${theme.borderRadius.sm};
        }

        .dropdown-content .node label {
            display: flex;
            align-items: center;
        }

        .dropdown-content .node-label {
            padding: 0 0.5rem;
        }
    }
`;

export const treeselectSimpleCSS = css<Options>`
    &.react-dropdown-tree-select .dropdown {
        a.dropdown-trigger .tag {
            background-color: inherit;
            color: inherit;
            border: 0;
        }

        a.dropdown-trigger .tag .tag-remove {
            display: none;
        }

        .dropdown-trigger .tag-item:not(:first-child) input {
            display: none;
        }

        .dropdown-content .node {
            padding: 0;
        }

        .dropdown-content .node .toggle {
            display: none;
        }

        .dropdown-content .node label {
            width: 100%;
            border-radius: 0.25rem;
            margin: 0;
            padding: 0.5rem 0.25rem;
        }

        .dropdown-content .node label:hover {
            background: ${theme.color.gray[200]};
        }
    }
`;
