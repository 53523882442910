import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

const CaretContentValueStyled = styled.div`
    font-weight: 700;
    color: ${theme.color.gray[900]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const CaretContentValueUnitStyled = styled.span`
    color: ${theme.color.gray[700]};
`;

interface Props {
    children: React.ReactNode;
    unit?: string;
}

const CaretContentValue: React.FC<Props> = ({ unit, children }) => {
    if (children === null || children === undefined) return null;

    return (
        <CaretContentValueStyled title={typeof children === "string" ? children : undefined}>
            {children}
            {unit && <CaretContentValueUnitStyled>{unit}</CaretContentValueUnitStyled>}
        </CaretContentValueStyled>
    );
};

export { CaretContentValue };
