import { rootApi } from "store/services/root-api";

export interface BasicKeyAccountPayload {
    id: number;
    name: string;
    keyAccountNumber: string;
    city: string | null;
    postcode: string | null;
    street: string | null;
    telephone: string | null;
}
export interface KeyAccountPayload extends BasicKeyAccountPayload {
    customers?: any[];
    devices?: any[];
    userPermissions?: any[];
}

export interface KeyAccountRequest {
    city: string | null;
    postcode: string | null;
    street: string | null;
    name: string;
    telephone: string | null;
    keyAccountNumber: string;
}

export interface KeyAccountUpdateRequest {
    keyAccount: KeyAccountRequest;
    id: number;
}

export interface KeyAccountId {
    id: number;
}

const KEY_ACCOUNT_BASE_PATH = "key-accounts";
const KEY_ACCOUNT_CACHE_TYPE = "KeyAccount";
const KEY_ACCOUNT_CACHE_ID = "LIST";

const apiWithTag = rootApi.enhanceEndpoints({ addTagTypes: [KEY_ACCOUNT_CACHE_TYPE] });
export const keyAccountApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getKeyAccounts: builder.query<KeyAccountPayload[], void>({
            query: () => KEY_ACCOUNT_BASE_PATH,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: "KeyAccount" as const, id })),
                          { type: KEY_ACCOUNT_CACHE_TYPE, id: KEY_ACCOUNT_CACHE_ID },
                      ]
                    : [{ type: KEY_ACCOUNT_CACHE_TYPE, id: KEY_ACCOUNT_CACHE_ID }],
        }),
        addKeyAccount: builder.mutation<KeyAccountPayload, KeyAccountRequest>({
            query: (body) => ({
                url: KEY_ACCOUNT_BASE_PATH,
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: KEY_ACCOUNT_CACHE_TYPE, id: KEY_ACCOUNT_CACHE_ID }],
        }),
        updateKeyAccount: builder.mutation<void, KeyAccountUpdateRequest>({
            query: ({ keyAccount, id }) => ({
                url: `${KEY_ACCOUNT_BASE_PATH}/${id}`,
                method: "PUT",
                body: keyAccount,
            }),
            invalidatesTags: [{ type: KEY_ACCOUNT_CACHE_TYPE, id: KEY_ACCOUNT_CACHE_ID }],
        }),
        deleteKeyAccount: builder.mutation<void, number>({
            query: (id) => ({
                url: `${KEY_ACCOUNT_BASE_PATH}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: KEY_ACCOUNT_CACHE_TYPE, id: KEY_ACCOUNT_CACHE_ID }],
        }),
    }),
});

export const {
    useGetKeyAccountsQuery,
    useAddKeyAccountMutation,
    useUpdateKeyAccountMutation,
    useDeleteKeyAccountMutation,
} = keyAccountApi;
