import { Column, CompareFn, SortData } from "components/common/DashboardTable/types";
import { compareBoolean, compareDate, compareNumber, compareString } from "components/common/DashboardTable/utils";
import { useLayoutEffect, useRef, useState } from "react";

interface UseSortOptions<T extends Record<string, any>> {
    columns: Column<T, string>[];
    data?: T[];
    initialSortData?: SortData<T>;
}

export const useSort = <T extends Record<string, any>>(options: UseSortOptions<T>) => {
    const { columns, data } = options;
    const [sortData, setSortData] = useState<SortData<T> | undefined>(options.initialSortData);

    /**
     * If not sorted or sorted by different `dataIndex`, then
     * applies descending sort on selected `dataIndex`. If sorted
     * by the same `dataIndex`, then applies ascending sort (if previous
     * was descending), otherwise turns off sorting.
     *
     * @param {string} dataIndex
     */
    const handleApplySort = (dataIndex: string) => {
        if (!sortData || sortData.dataIndex !== dataIndex) {
            setSortData({ dataIndex, descending: false });
        } else if (!sortData.descending) {
            setSortData({ dataIndex, descending: true });
        } else {
            setSortData(undefined);
        }
    };

    let sortedData: T[] = data ?? [];
    // Implement more performant column lookup if needed
    const column = sortData ? columns.find((c) => c.dataIndex === sortData.dataIndex) : undefined;
    if (sortData && column) {
        let compareFunction: CompareFn | undefined;
        if (column.dataType === "string") {
            compareFunction = compareString;
        } else if (column.dataType === "number") {
            compareFunction = compareNumber;
        } else if (column.dataType === "date") {
            compareFunction = compareDate;
        } else if (column.dataType === "boolean") {
            compareFunction = compareBoolean;
        }
        const compareByKey = compareFunction?.(column.dataIndex);

        // Don't mutate the source array, create a new one
        sortedData = [...sortedData].sort((a: any, b: any) => {
            const res = compareByKey?.(a, b) ?? 0;
            return sortData.descending ? res * -1 : res;
        });
    }

    return { sortData, handleApplySort, sortedData };
};

export const useAutoColumnWidth = (data?: Record<string, any>) => {
    const columnsRef = useRef<(HTMLDivElement | null)[]>([]);
    const [columnWidths, setColWidths] = useState<number[]>([]);

    useLayoutEffect(() => {
        const storeWidths = () => {
            const ws = columnsRef.current.map((r) => r?.getBoundingClientRect().width ?? 0);
            setColWidths(ws);
        };
        storeWidths();

        window.addEventListener("resize", storeWidths);
        return () => {
            window.removeEventListener("resize", storeWidths);
        };
    }, [data]);

    return { columnsRef, columnWidths };
};
