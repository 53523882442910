import { Data } from "components/common/TreeSelect/types";

/**
 * Unpacks the tree structure into a record of nodes, using their id as a key
 *
 * @param {Data[]} [data]
 * @return {*}  {Record<string, Data>}
 */
export const getDataMap = (data?: Data[]): Record<string, Data> => {
    return (data ?? []).reduce<Record<string, Data>>(
        (acc, obj) => ({ ...acc, [obj.id]: obj, ...getDataMap(obj.children) }),
        {}
    );
};

/**
 * Returns only the leaf nodes in the tree structure
 *
 * @param {Data} obj
 * @return {*}  {Data[]}
 */
export const getDataLeafs = (obj: Data): Data[] => {
    return obj.children ? obj.children.flatMap(getDataLeafs) : [obj];
};

/**
 * Checks and expands the nodes in tree structure based on the provided array of selected values
 *
 * @param {Data} obj
 * @param {string[]} selected
 * @param {string[]} expanded
 * @return {*}  {Data}
 */
export const checkSelected = (obj: Data, selected: string[], expanded: string[]): Data => {
    // Sync previously expanded objects
    if (expanded.includes(obj.id)) {
        obj = { ...obj, expanded: true };
    }

    // Leaf node, set selected and return
    if (obj.children === undefined || obj.children === null || obj.children.length === 0) {
        const isSelected = selected.includes(obj.value);
        obj = { ...obj, checked: isSelected };
        return obj;
    }

    let children = obj.children;
    // Select/expand all the children first
    obj = { ...obj, children: children.map((child) => checkSelected(child, selected, expanded)) };
    children = obj.children as Data[];

    // Check if all children are selected - if so check the node also
    const allChildrenSelected = children.reduce((acc, curr) => acc === true && curr.checked === true, true);
    obj = { ...obj, checked: allChildrenSelected };
    if (allChildrenSelected === true) return obj;

    // If only some children are selected, display them, by expanding this node
    const anyChildSelected = children.reduce((acc, curr) => acc === true || curr.checked === true, false);
    obj = { ...obj, expanded: obj.expanded || anyChildSelected };

    return obj;
};
