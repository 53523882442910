import { Badge } from "components/common/Badge";
import { Button } from "components/common/Button";
import { ConfirmModal } from "components/common/ConfirmModal";
import { IconButton } from "components/common/IconButton";
import { Loader } from "components/common/Loader";
import { Modal } from "components/common/Modal";
import { PageContainer } from "components/common/PageContainer";
import { PageHeader } from "components/common/PageHeader";
import { WidgetFrame } from "components/common/WidgetFrame";
import { WithAccessPermission } from "components/common/WithAccessPermission";
import { DataTable } from "components/common/table/DataTable/DataTable";
import { useSortConfig } from "components/common/table/DataTable/hooks";
import { Column, ColumnActionComponent } from "components/common/table/types";
import { AddUserForm } from "components/form/forms/AddUserForm";
import { AddIcon, DeleteIcon, EditIcon, UsersIcon, PersonCirclePlusIcon, CheckIcon } from "components/icons/Icons";
import { LoaderOverlap } from "components/styled/LoaderOverlap";
import React, { useState } from "react";
import {
    KeyAccountPayload,
    useAddUserMutation,
    useDeleteUserMutation,
    useGetKeyAccountsQuery,
    useGetUsersQuery,
    UserPayload,
    UserRequest,
    UserUpdateRequestData,
    useUpdateUserMutation,
    useLazyInviteUserQuery,
} from "store";
import styled from "styled-components";
import { Role } from "types/authTypes";
import { useInputValue } from "utils/hooks/useInput";

const PermissionsWrapper = styled.div`
    white-space: pre-wrap;

    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const UsersPage: React.VFC = () => {
    const [showForm, setShowForm] = useState(false);
    const [editData, setEditData] = useState<UserPayload | null>(null);
    const [deleteId, setDeleteId] = useState<string | null>(null);
    const [modalError, setModalError] = useState<string | undefined>();
    const [invitedUsersIds, setInvitedUsersIds] = useState<string[]>([]);

    const [createUser, { isLoading: isCreateLoading }] = useAddUserMutation();
    const [updateUser, { isLoading: isUpdateLoading }] = useUpdateUserMutation();
    const [deleteUser, { isLoading: isDeleteLoading }] = useDeleteUserMutation();
    const [triggerInviteUser] = useLazyInviteUserQuery();
    const { data: users, isLoading: isUsersQueryLoading, isFetching: isUsersQueryFetching } = useGetUsersQuery();
    const { data: keyAccounts } = useGetKeyAccountsQuery();
    const isLoading = isUsersQueryLoading || isDeleteLoading || isUsersQueryFetching;

    const handleCreateUser = async (values: UserRequest) => {
        try {
            await createUser(values).unwrap();
            setShowForm(false);
        } catch (err: any) {
            setModalError(err?.data?.error);
        }
    };

    const handleUpdateUser = async (values: UserUpdateRequestData) => {
        if (!editData) return;

        try {
            await updateUser({ user: values, id: editData.id }).unwrap();
        } catch (err: any) {
            setModalError(err?.data?.error);
            return;
        }

        setShowForm(false);
        setEditData(null);
    };

    const handleDelete = async (id: string) => {
        try {
            await deleteUser(id).unwrap();
            setDeleteId(null);
        } catch (err: any) {
            setModalError(err?.data?.error);
        }
    };

    const openEditModal = (isOpen: boolean, user?: UserPayload) => {
        setShowForm(isOpen);
        setModalError(undefined);
        setEditData(user ? user : null);
    };

    const openDeleteModal = (id: string) => {
        setDeleteId(id);
        setModalError(undefined);
    };

    const dataTableSearchInput = useInputValue();
    const dataTableSortConfig = useSortConfig<UserPayload>({ dataIndex: "name", descending: false });

    const OpenEditModalButton: ColumnActionComponent<UserPayload> = ({ row }) => (
        <>
            <WithAccessPermission accessedRole={row.role} operation={"EDIT"}>
                <IconButton onClick={() => openEditModal(true, row)} title={"Edit"} Icon={EditIcon} />
            </WithAccessPermission>
        </>
    );
    const OpenDeleteModalButton: ColumnActionComponent<UserPayload> = ({ row }) => (
        <>
            <WithAccessPermission accessedRole={row.role} operation={"EDIT"}>
                <IconButton onClick={() => openDeleteModal(row.id)} title={"Delete"} Icon={DeleteIcon} />
            </WithAccessPermission>
        </>
    );

    const InviteUserButton: ColumnActionComponent<UserPayload> = ({ row }) => {
        if (!row.email || row.azure_oid) return;
        return (
            <>
                <WithAccessPermission accessedRole={row.role} operation={"EDIT"}>
                    <IconButton
                        onClick={async () => {
                            triggerInviteUser(row.id);
                            setInvitedUsersIds([...invitedUsersIds, row.id]);
                        }}
                        disabled={invitedUsersIds.includes(row.id)}
                        title={"Send invitation"}
                        Icon={invitedUsersIds.includes(row.id) ? CheckIcon : PersonCirclePlusIcon}
                    />
                </WithAccessPermission>
            </>
        );
    };

    const columns: Column<UserPayload>[] = [
        { type: "data", dataIndex: "name", dataType: "string", sortable: true },
        { type: "data", dataIndex: "username", dataType: "string", sortable: true },
        { type: "data", dataIndex: "email", dataType: "string", sortable: true },
        {
            type: "actions",
            width: 70,
            components: [InviteUserButton],
        },
        { type: "data", dataIndex: "role", dataType: "string", sortable: true },
        {
            type: "data",
            dataIndex: "keyAccountPermissions",
            label: "Permissions",
            dataType: "object",
            sortable: false,
            valueTransform: (permissions: { keyAccount: KeyAccountPayload }[]) => {
                return permissions.map(({ keyAccount }) => keyAccount.name);
            },
            CellWrapper: ({ row }) => {
                return (
                    <PermissionsWrapper>
                        {row.keyAccountPermissions.map(({ keyAccount }) => (
                            <Badge key={keyAccount.id} onClick={() => dataTableSearchInput.setValue(keyAccount.name)}>
                                {keyAccount.name}
                            </Badge>
                        ))}
                    </PermissionsWrapper>
                );
            },
        },
        {
            type: "actions",
            width: 135,
            components: [OpenEditModalButton, OpenDeleteModalButton],
        },
    ];

    return (
        <PageContainer>
            <PageHeader title={"User Administration"}>
                <WithAccessPermission accessedRole={Role.Customer} operation={"EDIT"}>
                    <Button onClick={() => openEditModal(true)} Icon={AddIcon}>
                        Add User
                    </Button>
                </WithAccessPermission>
            </PageHeader>
            <WidgetFrame isLoading={isLoading} height={"100%"} title={"Users"} Icon={UsersIcon}>
                <DataTable<UserPayload>
                    data={users}
                    columns={columns}
                    configuration={{ disableDownload: true }}
                    sortConfig={dataTableSortConfig}
                    searchInput={dataTableSearchInput}
                />
            </WidgetFrame>

            <Modal isOpen={showForm} setOpen={openEditModal}>
                {keyAccounts && (
                    <AddUserForm
                        editData={editData}
                        submitUser={editData ? handleUpdateUser : handleCreateUser}
                        dropDownData={keyAccounts}
                        error={modalError}
                    />
                )}
                {isCreateLoading ||
                    (isUpdateLoading && (
                        <LoaderOverlap>
                            <Loader size={"4rem"} />
                        </LoaderOverlap>
                    ))}
            </Modal>
            {deleteId && (
                <ConfirmModal
                    open={true}
                    setOpen={() => setDeleteId(null)}
                    onConfirm={() => handleDelete(deleteId)}
                    title={"Delete user?"}
                    description={`User ${users?.find((x) => x.id === deleteId)?.name} will be deleted.`}
                    confirmText={"Yes, delete"}
                    error={modalError}
                />
            )}
        </PageContainer>
    );
};
