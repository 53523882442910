import styled from "styled-components";

export const ButtonGroup = styled.div`
    display: flex;
    width: 100%;

    button {
        flex-grow: 1;
        flex-basis: 0;
        border-radius: 0;

        :first-child {
            border-radius: ${({ theme }) => theme.borderRadius.md} 0 0 ${({ theme }) => theme.borderRadius.md};
        }

        :last-child {
            border-radius: 0 ${({ theme }) => theme.borderRadius.md} ${({ theme }) => theme.borderRadius.md} 0;
        }
    }
`;
