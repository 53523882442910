import { PublicClientApplication } from "@azure/msal-browser";
import { config } from "config";

let api: PublicClientApplication;

/**
 * Use `useMsal` hook whenever possible instead of this method
 */
export const getMsalInstance = (): PublicClientApplication => {
    if (!api) {
        api = new PublicClientApplication({
            auth: {
                clientId: config.AD_AUTH_CLIENT_ID,
                authority: config.AD_AUTH_AUTHORITY,
                redirectUri: config.AD_AUTH_REDIRECT_URI,
                postLogoutRedirectUri: config.AD_AUTH_LOGOUT_REDIRECT_URI,
                knownAuthorities: [config.AD_AUTH_AUTHORITY],
            },
            cache: {
                cacheLocation: "sessionStorage",
            },
        });
    }
    return api;
};
