import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useCallback, useMemo } from "react";

type InteractionType = "popup" | "redirect";

const loginTypeMap: Record<InteractionType, "loginPopup" | "loginRedirect"> = {
    popup: "loginPopup",
    redirect: "loginRedirect",
};

const logoutTypeMap: Record<InteractionType, "logoutPopup" | "logoutRedirect"> = {
    popup: "logoutRedirect",
    redirect: "logoutRedirect",
};

const acquireTokenMap: Record<InteractionType, "acquireTokenPopup" | "acquireTokenRedirect"> = {
    popup: "acquireTokenPopup",
    redirect: "acquireTokenRedirect",
};

type AcquireTokenFunction = (options: {
    scopes: string[];
    persistTokenCallback: (accessToken: string) => void;
}) => void;

interface Props {
    interactionType?: InteractionType;
}

export function useAADAuthentication(props?: Props) {
    const { interactionType = "redirect" } = props ?? {};

    const { instance, accounts, inProgress } = useMsal();
    const user = useMemo(() => accounts?.at(0) ?? undefined, [accounts]);

    const acquireAccessToken = useCallback(
        async ({ scopes, persistTokenCallback }: Parameters<AcquireTokenFunction>[0]) => {
            const accessTokenRequest = {
                scopes,
                account: user,
            };
            try {
                const { accessToken } = await instance.acquireTokenSilent(accessTokenRequest);
                persistTokenCallback(accessToken);
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    const data = await instance[acquireTokenMap[interactionType]](accessTokenRequest);
                    if (data?.accessToken) persistTokenCallback(data.accessToken);
                }
            }
        },
        [instance, interactionType, user]
    );

    const triggerLogin = useCallback(() => instance[loginTypeMap[interactionType]](), [instance, interactionType]);
    const triggerLogout = useCallback(() => instance[logoutTypeMap[interactionType]](), [instance, interactionType]);

    return { instance, user, inProgress, triggerLogin, triggerLogout, acquireAccessToken };
}
