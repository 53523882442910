import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

const BadgeStyled = styled.button`
    width: fit-content;
    padding: 0 0.5rem;

    border: solid 1px ${theme.color.gray[500]};
    border-radius: ${theme.borderRadius.md};
    color: ${theme.color.gray[700]};

    font-size: 0.85rem;
    font-weight: 500;
`;

export const Badge: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => {
    return <BadgeStyled {...props}>{children}</BadgeStyled>;
};
