import { Loader } from "components/common/Loader";
import { PageContainer } from "components/common/PageContainer";
import { PageHeader } from "components/common/PageHeader";
import { ErrorPanel } from "components/form/ErrorPanel";
import { CustomerAttributes } from "pages/CustomerDetailPage/CustomerAttributes";
import { MachineInstallationHistory } from "pages/CustomerDetailPage/MachineInstallationHistory";
import { MachineInstallations } from "pages/CustomerDetailPage/MachineInstallations";
import React from "react";
import { useParams } from "react-router";
import { useGetOneCustomerQuery } from "store/services/customer-service";
import { QueryError } from "store/services/types";

export const CustomerDetailPage: React.VFC = () => {
    const { customerId } = useParams() as { customerId: string };
    const { data, isLoading, isFetching, error: customerQueryError } = useGetOneCustomerQuery(customerId);
    const error = customerQueryError as QueryError | undefined;

    let content;
    if (isLoading || isFetching) {
        return <Loader size={"3rem"} />;
    }

    if (error || !data) {
        if (error?.data?.statusCode === 401) {
            content = <ErrorPanel>{"You don't have the rights to access this customer."}</ErrorPanel>;
        } else {
            content = <ErrorPanel>{`${error?.data?.statusCode} - ${error?.data?.message}`}</ErrorPanel>;
        }
    } else {
        content = (
            <>
                <PageHeader title={"Customer Detail"} />
                <CustomerAttributes customer={data} />
                <MachineInstallations />
                <MachineInstallationHistory />
            </>
        );
    }

    return <PageContainer>{content}</PageContainer>;
};
