import { DialogContent, DialogOverlay } from "@reach/dialog";
import { CloseIcon } from "components/icons/Icons";
import React from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "styles/theme";

export interface ModalProps {
    isOpen: boolean;
    setOpen: (open: boolean) => void;
    children: React.ReactNode;
}

const openModal = keyframes`
  0% {
    opacity: 0;
    top: 35%

  }
  100% {
    opacity: 1;
    top: 40%
  }
`;

const CloseButton = styled.button`
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.25rem;

    color: ${theme.color.gray[700]};
    border-radius: ${theme.borderRadius.lg};

    :hover {
        color: ${theme.color.gray[900]};
        background-color: ${theme.color.gray[200]};
    }
`;

const DialogContentStyled = styled(DialogContent)`
    position: absolute;
    border-radius: ${theme.borderRadius.lg};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: ${openModal} 0.2s ease-out;
    width: 50vw;
    max-width: 1000px;
    max-height: 85vh;
    background-color: ${theme.color.white};
    padding: 2rem;
    outline: none;
    overflow: auto;
    box-shadow: ${theme.boxShadow.xl};
`;

const DialogOverlayStyled = styled(DialogOverlay)`
    background: hsla(0, 0%, 0%, 0.33);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
`;

export const Modal: React.FC<ModalProps> = ({ children, isOpen, setOpen }) => {
    return (
        <DialogOverlayStyled isOpen={isOpen} onDismiss={() => setOpen(false)} dangerouslyBypassScrollLock>
            <DialogContentStyled aria-label={"Modal window"}>
                <CloseButton onClick={() => setOpen(false)}>
                    <CloseIcon size={"24px"} />
                </CloseButton>
                {children}
            </DialogContentStyled>
        </DialogOverlayStyled>
    );
};
