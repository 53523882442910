import styled from "styled-components";

export const FormInfoText = styled.span`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.color.gray[600]};

    code {
        color: ${({ theme }) => theme.color.primary[600]};
    }
`;
