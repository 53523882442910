import { Button } from "components/common/Button";
import { ConfirmModal } from "components/common/ConfirmModal";
import { IconButton } from "components/common/IconButton";
import { Loader } from "components/common/Loader";
import { Modal } from "components/common/Modal";
import { NavigateBadge } from "components/common/NavigateBadge";
import { WidgetFrame } from "components/common/WidgetFrame";
import { DataTable } from "components/common/table/DataTable";
import { useSortConfig } from "components/common/table/DataTable/hooks";
import { Column, ColumnActionComponent } from "components/common/table/types";
import { InstallDeviceForm, FormSubmitData as InstallDeviceFormData } from "components/form/forms/InstallDeviceForm";
import { UninstallDeviceForm, FormData as UninstallDeviceFormData } from "components/form/forms/UninstallDeviceForm";
import { AddIcon, DeleteIcon, EditIcon, InstallationIcon, UninstallIcon } from "components/icons/Icons";
import { LoaderOverlap } from "components/styled/LoaderOverlap";
import React, { useState } from "react";
import { useParams } from "react-router";
import { routes } from "routing/routes";
import {
    DeviceInstallationForCustomerPayload,
    useCreateInstallationMutation,
    useDeleteInstallationMutation,
    useGetInstallationsForCustomerQuery,
    useUpdateInstallationMutation,
} from "store/services/device-installation-service";
import { useGetDevicesQuery } from "store/services/device-service";
import { useInputValue } from "utils/hooks/useInput";

export const MachineInstallations: React.VFC = () => {
    const { customerId } = useParams() as { customerId: string };

    const [showForm, setShowForm] = useState(false);
    const [editData, setEditData] = useState<DeviceInstallationForCustomerPayload | null>(null);

    const [uninstallId, setUninstallId] = useState<number | null>(null);
    const [deleteId, setDeleteId] = useState<number | null>(null);

    const [modalError, setModalError] = useState<string | undefined>();

    const {
        data,
        isLoading: isQueryLoading,
        isFetching: isQueryFetching,
    } = useGetInstallationsForCustomerQuery({ customerId: parseInt(customerId), onlyActive: true });

    const isLoading = isQueryLoading || isQueryFetching;

    const [createInstallation, { isLoading: isCreateLoading }] = useCreateInstallationMutation();
    const [updateInstallation, { isLoading: isUpdateLoading }] = useUpdateInstallationMutation();
    const [deleteInstallation] = useDeleteInstallationMutation();
    const { data: devices } = useGetDevicesQuery();

    const handleCreate = async (values: InstallDeviceFormData) => {
        try {
            await createInstallation({ ...values, customerId: parseInt(customerId) }).unwrap();
            setShowForm(false);
        } catch (err: any) {
            setModalError(err?.data?.error);
        }
    };

    const handleUpdate = async (values: InstallDeviceFormData) => {
        if (!editData) return;

        try {
            await updateInstallation({ data: values, id: editData.id }).unwrap();
            setShowForm(false);
        } catch (err: any) {
            setModalError(err?.data?.error);
        }
    };

    const handleUninstall = async (values: UninstallDeviceFormData) => {
        if (!uninstallId) return;

        await updateInstallation({ id: uninstallId, data: { to: values.to } });
        setUninstallId(null);
    };

    const handleDelete = async (id: number) => {
        await deleteInstallation(id);
        setDeleteId(null);
    };

    const openModal = (isOpen: boolean, data?: DeviceInstallationForCustomerPayload) => {
        setShowForm(isOpen);
        setModalError(undefined);
        setEditData(data ? data : null);
    };

    const dataTableSearchInput = useInputValue();
    const dataTableSortConfig = useSortConfig<DeviceInstallationForCustomerPayload>({
        dataIndex: "from",
        descending: false,
    });

    const OpenEditModalButton: ColumnActionComponent<DeviceInstallationForCustomerPayload> = ({ row }) => (
        <IconButton onClick={() => openModal(true, row)} title={"Edit"} Icon={EditIcon} />
    );
    const OpenUninstallModalButton: ColumnActionComponent<DeviceInstallationForCustomerPayload> = ({ row }) => (
        <IconButton
            onClick={() => {
                setUninstallId(row.id);
            }}
            title={"Uninstall"}
            Icon={UninstallIcon}
        />
    );
    const OpenDeleteModalButton: ColumnActionComponent<DeviceInstallationForCustomerPayload> = ({ row }) => (
        <IconButton onClick={() => setDeleteId(row.id)} title={"Delete"} Icon={DeleteIcon} />
    );

    const columns: Column<DeviceInstallationForCustomerPayload>[] = [
        { type: "data", dataIndex: "from", dataType: "date", sortable: true },
        {
            type: "data",
            dataIndex: "deviceId",
            dataType: "string",
            sortable: true,
            CellWrapper: ({ value }) => {
                if (value === undefined) return null;

                return <NavigateBadge path={routes.machineDetail.getPath({ machineId: value })}>{value}</NavigateBadge>;
            },
        },
        {
            type: "actions",
            width: 400,
            components: [OpenEditModalButton, OpenUninstallModalButton, OpenDeleteModalButton],
        },
    ];

    return (
        <WidgetFrame
            isLoading={isLoading}
            title={"Machine installations"}
            minHeight={"25rem"}
            Icon={InstallationIcon}
            titleToolbar={
                <div>
                    <Button Icon={AddIcon} onClick={() => openModal(true)}>
                        Add installation
                    </Button>
                </div>
            }
        >
            <DataTable<DeviceInstallationForCustomerPayload>
                data={data}
                columns={columns}
                configuration={{ disableDownload: true }}
                sortConfig={dataTableSortConfig}
                searchInput={dataTableSearchInput}
            />
            <Modal isOpen={showForm} setOpen={openModal}>
                <InstallDeviceForm
                    editData={editData}
                    handleSubmit={editData ? handleUpdate : handleCreate}
                    dropDownData={devices ?? []}
                    error={modalError}
                />
                {(isCreateLoading || isUpdateLoading) && (
                    <LoaderOverlap>
                        <Loader size={"4rem"} />
                    </LoaderOverlap>
                )}
            </Modal>
            {uninstallId && (
                <Modal isOpen={true} setOpen={() => setUninstallId(null)}>
                    <UninstallDeviceForm
                        deviceId={data?.find((x) => x.id === uninstallId)?.deviceId}
                        from={data?.find((x) => x.id === uninstallId)?.from ?? null}
                        handleSubmit={handleUninstall}
                    />
                </Modal>
            )}
            {deleteId && (
                <ConfirmModal
                    open={true}
                    setOpen={() => setDeleteId(null)}
                    onConfirm={() => handleDelete(deleteId)}
                    title={"Delete machine installation?"}
                    // TODO Implement better lookup if needed
                    description={`Installation record for the machine ${data?.find((x) => x.id === deleteId)
                        ?.deviceId} will be deleted.`}
                    confirmText={"Yes, delete"}
                />
            )}
        </WidgetFrame>
    );
};
