import { Button } from "components/common/Button";
import { ModalActions } from "components/common/ModalActions";
import { ModalContent } from "components/common/ModalContent";
import { ModalTitle } from "components/common/ModalTitle";
import { DateInput } from "components/form/DateInput";
import { CheckIcon } from "components/icons/Icons";
import { Form, Formik } from "formik";
import React from "react";
import { DeviceInstallationRequest } from "store/services/device-installation-service";
import styled from "styled-components";
import * as Yup from "yup";

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const DeviceIdStyled = styled.span`
    font-weight: 700;
`;

export type FormData = Pick<DeviceInstallationRequest, "to">;

interface DeviceUninstallationFormProps {
    handleSubmit: (values: FormData) => void;
    deviceId: string | undefined;
    from: string | null;
}

const validationSchema = (minimalDate: Date | null) => {
    return Yup.object().shape({
        to: Yup.date()
            .required("Field is required")
            .min(minimalDate, "Uninstallation date must come after installation date"),
    });
};

export const UninstallDeviceForm: React.VFC<DeviceUninstallationFormProps> = ({ handleSubmit, deviceId, from }) => {
    const minimalToDate = from ? new Date(from) : null;
    let initialToDate = new Date();
    if (minimalToDate && minimalToDate > initialToDate) {
        initialToDate = minimalToDate;
    }
    const initialValues = {
        to: initialToDate,
    };

    return (
        <Formik<FormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema(minimalToDate)}
        >
            {({ isSubmitting }) => {
                return (
                    <FormStyled>
                        <ModalTitle>Uninstall machine?</ModalTitle>
                        <ModalContent>
                            You are about to set the uninstallation date of the following device:{" "}
                            <DeviceIdStyled>{deviceId}</DeviceIdStyled>
                        </ModalContent>
                        <DateInput name={"to"} showTime minDate={minimalToDate} required />
                        <ModalActions>
                            <Button type={"submit"} disabled={isSubmitting} variant={"primary"} Icon={CheckIcon}>
                                Submit
                            </Button>
                        </ModalActions>
                    </FormStyled>
                );
            }}
        </Formik>
    );
};
