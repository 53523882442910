import { ChevronLeftIcon } from "components/icons/Icons";
import { SIDEBAR_ANIMATION_DURATION } from "components/layout/layoutConstants";
import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

const Button = styled.button`
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 0.5rem;

    color: ${theme.color.gray[500]};
`;

const CollapseSwitchIcon = styled(ChevronLeftIcon)<{ $isCollapsed: boolean }>`
    transition: transform ${SIDEBAR_ANIMATION_DURATION} ease-out;
    transform: ${({ $isCollapsed }) => ($isCollapsed ? "rotate(180deg)" : "")};
`;

interface SidebarCollapseSwitchProps {
    isCollapsed: boolean;
    setIsCollapsed: (isCollapsed: boolean) => void;
}

export const SidebarCollapseSwitch: React.VFC<SidebarCollapseSwitchProps> = ({ isCollapsed, setIsCollapsed }) => {
    return (
        <Button onClick={() => setIsCollapsed(!isCollapsed)}>
            <CollapseSwitchIcon $isCollapsed={isCollapsed} size={"40px"} />
        </Button>
    );
};
