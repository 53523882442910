import { Caret } from "components/common/Caret";
import { CoffeeIcon } from "components/icons/Icons";
import React from "react";
import { useDashboardDeviceTypes } from "store/hooks/useDashboardDeviceTypes";
import { useDashboardDevices } from "store/hooks/useDashboardDevices";
import { useDashboardTimeRange } from "store/hooks/useDashboardTimeRange";
import { useGetDispensesTotalQuery } from "store/services/dispense-service";

const TotalDispenses: React.FC = () => {
    const timeRange = useDashboardTimeRange();
    const devices = useDashboardDevices();
    const deviceTypes = useDashboardDeviceTypes();
    const { data, isLoading, isFetching } = useGetDispensesTotalQuery({ ...timeRange, devices, deviceTypes });

    return (
        <Caret title={"Dispenes"} Icon={CoffeeIcon} isLoading={isLoading || isFetching} colSpan={3}>
            {data !== undefined && <Caret.Value>{data.toLocaleString()}</Caret.Value>}
        </Caret>
    );
};

export { TotalDispenses };
