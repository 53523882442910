import { ChangeEvent, useState } from "react";

export type UseInputValueReturn = {
    value: string;
    setValue: (newValue: string) => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const useInputValue = (): UseInputValueReturn => {
    const [value, setValue] = useState("");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.currentTarget.value);
    };

    return { value, setValue, onChange: handleChange };
};
