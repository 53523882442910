import { PageContainer } from "components/common/PageContainer";
import { PageHeader } from "components/common/PageHeader";
import { PendingProvisioningRequestsWidget } from "pages/ProvisioningPage/PendingProvisioningRequestsWidget";
import { ProvisioningTokenGeneration } from "pages/ProvisioningPage/ProvisioningTokenGeneration/ProvisioningTokenGeneration";
import React from "react";

export const ProvisioningPage: React.VFC = () => {
    return (
        <PageContainer>
            <PageHeader title={"Provisioning"}>
                <ProvisioningTokenGeneration />
            </PageHeader>
            <PendingProvisioningRequestsWidget />
        </PageContainer>
    );
};
