import { timeRangeMap, useAppSelector } from "store";

export const useDashboardTimeRange = () => {
    const timeRange = useAppSelector((state) => state.dashboard.timeRange);

    const isCustomTimeRange = timeRange.type === "custom";

    const dateTimeFrom = isCustomTimeRange ? timeRange.dateTimeFrom : timeRangeMap[timeRange.type].dateTimeFrom;
    const dateTimeTo = isCustomTimeRange ? timeRange.dateTimeTo : timeRangeMap[timeRange.type].dateTimeTo;

    return { dateTimeFrom, dateTimeTo };
};
