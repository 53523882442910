import { Navigation } from "components/layout/Navigation";
import { SidebarCollapseSwitch } from "components/layout/SidebarCollapseSwitch";
import {
    SIDEBAR_ANIMATION_DURATION,
    SIDEBAR_EXPANDED_WIDTH,
    SIDEBAR_ITEM_WIDTH,
} from "components/layout/layoutConstants";
import React from "react";
import styled from "styled-components";
import { MEDIA_QUERY } from "styles/styleUtils";
import { theme } from "styles/theme";

const SidebarWrapper = styled.div<{ $isCollapsed: boolean }>`
    display: flex;
    overflow: hidden;
    flex-shrink: 0;
    flex-grow: 0;
    width: ${({ $isCollapsed }) => ($isCollapsed ? SIDEBAR_ITEM_WIDTH : SIDEBAR_EXPANDED_WIDTH)};
    flex-direction: column;
    background-color: ${theme.color.gray[200]};
    transition: width ${SIDEBAR_ANIMATION_DURATION} ease-out;

    ${MEDIA_QUERY.tabletAndSmaller} {
        width: ${SIDEBAR_ITEM_WIDTH};
    }
`;

const CollapseButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    width: 100%;

    ${MEDIA_QUERY.tabletAndSmaller} {
        display: none;
    }
`;

interface NavigationProps {
    isCollapsed: boolean;
    setIsCollapsed: (isCollapsed: boolean) => void;
}

export const Sidebar: React.VFC<NavigationProps> = ({ isCollapsed, setIsCollapsed }) => {
    return (
        <SidebarWrapper $isCollapsed={isCollapsed}>
            <Navigation isCollapsed={isCollapsed} />
            <CollapseButtonWrap>
                <SidebarCollapseSwitch isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
            </CollapseButtonWrap>
        </SidebarWrapper>
    );
};
