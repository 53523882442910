import { GraphContent } from "components/common/Graph/GraphContent";
import { GraphTitle } from "components/common/Graph/GraphTitle";
import { GraphData, Variant } from "components/common/Graph/types";
import { Loader } from "components/common/Loader";
import React from "react";
import styled from "styled-components";
import { dashboardCardCSS, DashboardCardOptions } from "styles/dashboard";
import { theme } from "styles/theme";

interface GraphStyledProps extends DashboardCardOptions {
    isLoading?: boolean;
}

const GraphStyled = styled.div<GraphStyledProps>`
    ${dashboardCardCSS}

    display: grid;
    grid-template-rows: max-content 1fr;
    padding: 1rem 1.5rem;
`;

const GraphNoDataStyled = styled.div<GraphStyledProps>`
    margin: auto;
    color: ${theme.color.gray[500]};
    font-weight: 500;
    font-size: 0.875rem;
`;

// Helper div for responsive chart size
const GraphContainerStyled = styled.div<GraphStyledProps>`
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-top: 1.5rem;
`;

interface Props extends GraphStyledProps {
    title: string;
    variant: Variant;
    data?: GraphData;
}

const Graph: React.FC<Props> = (props) => {
    return (
        <GraphStyled colSpan={props.colSpan} rowSpan={props.rowSpan} isLoading={props.isLoading}>
            <GraphTitle variant={props.variant}>{props.title}</GraphTitle>
            {props.isLoading ? (
                <Loader size={"2rem"} />
            ) : props.data === undefined || props.data.datasets.length === 0 ? (
                <GraphNoDataStyled>No data...</GraphNoDataStyled>
            ) : (
                <GraphContainerStyled>
                    <GraphContent variant={props.variant} data={props.data} />
                </GraphContainerStyled>
            )}
        </GraphStyled>
    );
};

export { Graph };
