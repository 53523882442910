import { Button } from "components/common/Button";
import { Modal } from "components/common/Modal";
import { ModalActions } from "components/common/ModalActions";
import { ModalContent } from "components/common/ModalContent";
import { ModalTitle } from "components/common/ModalTitle";
import { ErrorPanel } from "components/form/ErrorPanel";
import { CheckIcon, CloseIcon } from "components/icons/Icons";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    onConfirm: () => void;
    title: string;
    description?: string;
    confirmText?: string;
    cancelText?: string;
    error?: string;
    loading?: boolean;
}

export const ConfirmModal = (props: Props) => {
    const { setOpen, description, loading } = props;

    return (
        <Modal isOpen={props.open} setOpen={setOpen}>
            <ModalTitle>{props.title}</ModalTitle>
            {props.error ? (
                <ErrorPanel>{props.error}</ErrorPanel>
            ) : (
                <>
                    {description && <ModalContent>{description}</ModalContent>}
                    <ModalActions>
                        <Button disabled={loading} variant={"primary"} Icon={CheckIcon} onClick={props.onConfirm}>
                            {props.confirmText ?? "Yes"}
                        </Button>
                        <Button disabled={loading} Icon={CloseIcon} onClick={() => setOpen(false)}>
                            {props.cancelText ?? "No, cancel"}
                        </Button>
                    </ModalActions>
                </>
            )}
        </Modal>
    );
};
