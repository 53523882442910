import { Graph } from "components/common/Graph";
import React from "react";
import { useDashboardDeviceTypes } from "store/hooks/useDashboardDeviceTypes";
import { useDashboardDevices } from "store/hooks/useDashboardDevices";
import { useDashboardTimeRange } from "store/hooks/useDashboardTimeRange";
import { useGetDispensesTotalByTypeQuery } from "store/services/dispense-service";

const TotalDispensesByType: React.FC = () => {
    const timeRange = useDashboardTimeRange();
    const devices = useDashboardDevices();
    const deviceTypes = useDashboardDeviceTypes();
    const { data, isLoading, isFetching } = useGetDispensesTotalByTypeQuery({ ...timeRange, devices, deviceTypes });

    return (
        <Graph
            variant={"doughnut"}
            title={"Total drink dispenses"}
            colSpan={4}
            rowSpan={3}
            data={data}
            isLoading={isLoading || isFetching}
        />
    );
};

export { TotalDispensesByType };
