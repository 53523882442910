import {
    IconComponent,
    CustomersIcon,
    DashboardIcon,
    KeyAccountIcon,
    MachineIcon,
    ModuleIcon,
    UsersIcon,
    ApprovalIcon,
} from "components/icons/Icons";
import { SIDEBAR_ANIMATION_DURATION, SIDEBAR_ITEM_HEIGHT } from "components/layout/layoutConstants";
import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { routes } from "routing/routes";
import { useCurrentUserRole } from "store";
import styled, { css } from "styled-components";
import { MEDIA_QUERY, pxToREM } from "styles/styleUtils";
import { theme } from "styles/theme";
import { Role } from "types/authTypes";
import { hasRole } from "utils/auth-utils";

const FluidCornersMixinWidth = "0.5rem";
const FluidCornersMixin = () => css`
    &::before,
    &::after {
        content: "";
        position: absolute;

        right: 0;
        width: ${FluidCornersMixinWidth};
        height: ${pxToREM(40)};
        background-color: transparent;
    }

    &::before {
        top: ${pxToREM(-40)};
        box-shadow: 0 ${pxToREM(12)} 0 0 ${theme.color.gray[50]};
        border-bottom-right-radius: ${FluidCornersMixinWidth};
    }

    &::after {
        bottom: ${pxToREM(-40)};
        box-shadow: 0 ${pxToREM(-12)} 0 0 ${theme.color.gray[50]};
        border-top-right-radius: ${FluidCornersMixinWidth};
    }
`;

const Nav = styled.nav`
    display: flex;
    flex-direction: column;

    margin-top: 2rem;
`;

export const NavLink = styled(RouterNavLink)`
    position: relative;
    height: ${SIDEBAR_ITEM_HEIGHT};
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;

    color: ${theme.color.gray[700]};

    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.025rem;
    text-decoration: none;

    &:hover {
        background-color: ${theme.color.gray[50]};
        color: ${theme.color.gray[900]};

        text-decoration: none;

        ${FluidCornersMixin()}
    }

    &.active {
        background-color: ${theme.color.gray[50]};
        color: ${theme.color.gray[900]};

        font-weight: 600;
        ${FluidCornersMixin()}
    }
`;

const NavLinkIcon = styled.div<{ $isCollapsed: boolean }>`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.75rem;
    margin-left: ${({ $isCollapsed }) => ($isCollapsed ? "-0.5rem" : undefined)};

    transition: flex-basis ${SIDEBAR_ANIMATION_DURATION} ease-out;
    color: ${theme.color.gray[500]};

    ${MEDIA_QUERY.tabletAndSmaller} {
        margin-left: -0.5rem;
    }
`;

const NavLinkTitle = styled.div`
    flex: 1 0 12rem;
`;

interface NavItem {
    to: string;
    title: string;
    allowedRoles: Role[];
    Icon: IconComponent;
}

const PAGE_LIST: NavItem[] = [
    {
        to: routes.dashboard.path,
        title: "Dashboard",
        allowedRoles: routes.dashboard.allowedRoles,
        Icon: DashboardIcon,
    },
    {
        to: routes.machines.path,
        title: "Machines",
        allowedRoles: routes.machines.allowedRoles,
        Icon: MachineIcon,
    },
    {
        to: routes.modules.path,
        title: "Modules",
        allowedRoles: routes.modules.allowedRoles,
        Icon: ModuleIcon,
    },
    {
        to: routes.keyAccounts.path,
        title: "Key accounts",
        allowedRoles: routes.keyAccounts.allowedRoles,
        Icon: KeyAccountIcon,
    },
    {
        to: routes.customers.path,
        title: "Customers",
        allowedRoles: routes.customers.allowedRoles,
        Icon: CustomersIcon,
    },
    {
        to: routes.users.path,
        title: "Users",
        allowedRoles: routes.users.allowedRoles,
        Icon: UsersIcon,
    },
    {
        to: routes.provisioning.path,
        title: "Provisioning",
        allowedRoles: routes.provisioning.allowedRoles,
        Icon: ApprovalIcon,
    },
];

interface NavigationProps {
    isCollapsed: boolean;
}

export const Navigation: React.VFC<NavigationProps> = ({ isCollapsed }) => {
    const role = useCurrentUserRole();
    return (
        <Nav>
            {PAGE_LIST.filter(({ allowedRoles }) => hasRole(role, allowedRoles)).map(({ to, title, Icon }) => (
                <NavLink to={to} key={title}>
                    <NavLinkIcon $isCollapsed={isCollapsed}>
                        <Icon size={"20px"} />
                    </NavLinkIcon>
                    <NavLinkTitle>{title}</NavLinkTitle>
                </NavLink>
            ))}
        </Nav>
    );
};
