import { Data } from "components/common/TreeSelect/types";
import { checkSelected, getDataMap, getDataLeafs } from "components/common/TreeSelect/utils";
import React, { useState } from "react";
import DropdownTreeSelect, { Mode } from "react-dropdown-tree-select";
import styled from "styled-components";
import { Options, treeselectCSS, treeselectSimpleCSS } from "styles/treeselect";

const DropdownTreeSelectStyled = styled(DropdownTreeSelect)`
    ${treeselectCSS}

    ${({ mode }) => mode === "simpleSelect" && treeselectSimpleCSS}
`;

interface Props extends Options {
    mode?: Mode;
    data?: Data[];
    onChange: (nodes: Data[]) => void;
    onBlur?: () => void;
    selected?: string[];
}

const TreeSelect: React.FC<Props> = (props) => {
    const data = props.data ?? [];
    // Build a data map for quicker lookup on change
    const dataMap = getDataMap(data);

    const [expanded, setExpanded] = useState<string[]>([]);
    // Check and expand data based on external state
    const checkedData = data.map((obj) => checkSelected(obj, props.selected ?? [], expanded));

    return (
        <DropdownTreeSelectStyled
            mode={props.mode}
            contentPosition={props.contentPosition}
            data={checkedData}
            showPartiallySelected
            onBlur={props.onBlur}
            onChange={(_, selected) =>
                // Provide only the values of the leaf nodes (can be expanded in the future)
                props.onChange((selected as Data[]).flatMap((node) => getDataLeafs(dataMap[node.id])))
            }
            onNodeToggle={(node) => {
                // Remove/add node from expanded node list - saves state between closing/opening component
                setExpanded((values) =>
                    values.includes(node.id) ? values.filter((x) => x !== node.id) : [...values, node.id]
                );
            }}
        />
    );
};

export { TreeSelect };
