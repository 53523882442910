import { Button } from "components/common/Button";
import { ModalActions } from "components/common/ModalActions";
import { ModalTitle } from "components/common/ModalTitle";
import { DropDownInput } from "components/form/DropDownInput";
import { ErrorPanel } from "components/form/ErrorPanel";
import { TextInput } from "components/form/TextInput";
import { CheckIcon } from "components/icons/Icons";
import { RadioButtonGroup } from "components/styled/RadioButtonGroup";
import { Form, Formik, Field } from "formik";
import React from "react";
import { KeyAccountPayload, useCurrentUserRole, UserRequest, UserUpdateRequestData } from "store";
import styled from "styled-components";
import { Role } from "types/authTypes";
import * as Yup from "yup";

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export interface FormData extends Omit<UserRequest, "customerPermissions" | "keyAccountPermissions"> {
    customerPermissions: string[];
    keyAccountPermissions: string[];
}

type SubmitAddUser = (values: UserRequest) => Promise<void>;
type SubmitUpdateUser = (values: UserUpdateRequestData) => Promise<void>;

interface AddKeyAccountFormProps {
    submitUser: SubmitAddUser | SubmitUpdateUser;
    dropDownData?: KeyAccountPayload[];
    editData: UserRequest | null;
    error?: string;
}

const resolveAvailableRoles = (role: Role) => {
    switch (role) {
        case Role.Superadmin:
            return Object.values(Role);
        case Role.KeyAccount:
            return [Role.Customer];
        default:
            return [];
    }
};

const baseInitialValues: FormData = {
    name: "",
    username: "",
    email: "",
    role: Role.Superadmin,
    keyAccountPermissions: [],
    customerPermissions: [],
};

const toCustomerData = (dropDownData: NonNullable<KeyAccountPayload["customers"]>, prefix?: string) => {
    return dropDownData.map((x) => ({
        id: `${prefix}-${x.id.toString()}`,
        value: x.id.toString(),
        label: x.name,
    }));
};

interface ToKeyAccountDataOptions {
    withCustomer?: boolean;
}

const toKeyAccountData = (dropDownData?: KeyAccountPayload[], options?: ToKeyAccountDataOptions) =>
    dropDownData
        ?.filter((x) => options?.withCustomer !== true || (x.customers && x.customers.length > 0))
        ?.map((x) => ({
            id: x.id.toString(),
            value: x.id.toString(),
            label: x.name,
            children: options?.withCustomer === true ? toCustomerData(x.customers ?? [], x.id.toString()) : undefined,
        })) ?? [];

const validationSchema = () => {
    return Yup.object().shape({
        name: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Name is required"),
        username: Yup.string().required("Username is required"),
        email: Yup.string().email("Invalid email"),
        role: Yup.string().required("Role is required"),
        keyAccountPermissions: Yup.array().of(Yup.number()),
        customerPermissions: Yup.array().of(Yup.number()),
    });
};
export const AddUserForm: React.VFC<AddKeyAccountFormProps> = ({ submitUser, editData, dropDownData, error }) => {
    const role = useCurrentUserRole();

    let initialValues = { ...baseInitialValues };
    if (editData) {
        const { name, username, email, role, keyAccountPermissions, customerPermissions } = editData;
        initialValues = {
            ...initialValues,
            name,
            username,
            email,
            role,
            // Convert to an array of ids - needed by the tree select component
            keyAccountPermissions: keyAccountPermissions.map((x) => x.keyAccount.id.toString()),
            customerPermissions: customerPermissions.map((x) => x.customer.id.toString()),
        };
    }

    const handleSubmitForm = async (values: FormData) => {
        const { customerPermissions, keyAccountPermissions, ...otherValues } = values;

        // The selected key accounts and customers are stored in arrays of ids
        // Convert the ids into numbers as required by the API
        const customerPermissionsParsed = customerPermissions.map((id) => parseInt(id));
        const keyAccountPermissionsParsed = keyAccountPermissions.map((id) => parseInt(id));

        // If the data is edited, the permissions are consumed by the API in a map of permission records (keys are object ids)
        // If the data is created, the permissions are consumed by the API in an array of permission records
        if (editData) {
            const permissionMaps = {
                customerPermissions: customerPermissionsParsed.reduce(
                    (acc, curr) => ({ ...acc, [curr]: { customer: { id: curr } } }),
                    {}
                ),
                keyAccountPermissions: keyAccountPermissionsParsed.reduce(
                    (acc, curr) => ({ ...acc, [curr]: { keyAccount: { id: curr } } }),
                    {}
                ),
            };
            await (submitUser as SubmitUpdateUser)({ ...otherValues, ...permissionMaps });
        } else {
            const permissionArrays = {
                customerPermissions: customerPermissionsParsed.map((id) => ({ customer: { id } })),
                keyAccountPermissions: keyAccountPermissionsParsed.map((id) => ({ keyAccount: { id } })),
            };
            await (submitUser as SubmitAddUser)({ ...otherValues, ...permissionArrays });
        }
    };

    return (
        <Formik<FormData>
            initialValues={initialValues}
            validationSchema={validationSchema()}
            validateOnChange={true}
            onSubmit={handleSubmitForm}
        >
            {({ isSubmitting, values }) => {
                return (
                    <FormStyled>
                        <ModalTitle>{editData ? "Edit user" : "Create a new user"}</ModalTitle>
                        <TextInput name={"name"} required />
                        <TextInput name={"username"} required />
                        <TextInput name={"email"} />
                        <div role={"group"}>
                            <RadioButtonGroup $columnsEnabled={false}>
                                {role !== null &&
                                    resolveAvailableRoles(role).map((value: string) => (
                                        <div key={value}>
                                            <Field id={`${role}-${value}`} type={"radio"} name={"role"} value={value} />
                                            <label htmlFor={`${role}-${value}`}>
                                                {value.replace(/([A-Z])/g, " $1")}
                                            </label>
                                        </div>
                                    ))}
                            </RadioButtonGroup>
                        </div>

                        {values.role === Role.KeyAccount && (
                            <DropDownInput
                                name={"keyAccountPermissions"}
                                mode={"multiSelect"}
                                dropDownData={toKeyAccountData(dropDownData)}
                            />
                        )}

                        {[Role.Customer, Role.Operator].includes(values.role) && (
                            <DropDownInput
                                name={"customerPermissions"}
                                mode={"multiSelect"}
                                dropDownData={toKeyAccountData(dropDownData, { withCustomer: true })}
                            />
                        )}

                        <ErrorPanel>{error}</ErrorPanel>
                        <ModalActions>
                            <Button type={"submit"} disabled={isSubmitting} variant={"primary"} Icon={CheckIcon}>
                                Submit
                            </Button>
                        </ModalActions>
                    </FormStyled>
                );
            }}
        </Formik>
    );
};
