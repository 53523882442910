/**
 * Split the string by capitals and merge using a space, capitalize first letter
 *
 * @param {string} value
 * @return {*}  {string}
 */
export const splitAndCapitalize = (value: string): string =>
    value.charAt(0).toUpperCase() + value.slice(1).replace(/([A-Z])/g, " $1");

/**
 * Convert whitespace separated words to camel case
 *
 * @param {string} str
 * @return {*}  {string}
 */
export const toCamelCase = (str: string): string => {
    return str
        .replace(/^\w|[A-Z]|\b\w/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
};

/**
 * Convert kebab case string to title case string
 *
 * @param {string} str
 * @return {*}  {string}
 */
export const kebabToTitleCase = (str: string): string => {
    return str
        .split("-")
        .map((substr) => substr.slice(0, 1).toUpperCase() + substr.slice(1))
        .join(" ");
};

/**
 * Convert string lower case without diacritics
 *
 * @param {string} str
 * @return {*}  {string}
 */
export const normalize = (value: string): string =>
    value
        .toLowerCase()
        .replace(/\s/g, "")
        // Remove diacritics
        // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
