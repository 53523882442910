import { compareBoolean, compareDate, compareNumber, compareString } from "components/common/table/Table/utils";
import {
    Column,
    ColumnDataSortable,
    CompareFn,
    isColumnData,
    Row,
    RowIndex,
    SortConfig,
} from "components/common/table/types";
import { useState } from "react";

type UseSortOptions<T extends Row> = {
    columns: Column<T>[];
    data?: T[];
    sortConfig: UseSortConfigReturnType<T>;
};

export type UseSortConfigReturnType<T extends Row> = {
    sortConfig: SortConfig<T> | undefined;
    setSortConfig: (newValue: SortConfig<T> | undefined) => void;
};

export const useSortConfig = <T extends Row>(initialSortConfig?: SortConfig<T>): UseSortConfigReturnType<T> => {
    const [sortConfig, setSortConfig] = useState<SortConfig<T> | undefined>(initialSortConfig);

    return { sortConfig, setSortConfig };
};

export const useSort = <T extends Row>(options: UseSortOptions<T>) => {
    const { columns, data } = options;
    const { sortConfig, setSortConfig } = options.sortConfig;

    /**
     * If not sorted or sorted by different `dataIndex`, then
     * applies descending sort on selected `dataIndex`. If sorted
     * by the same `dataIndex`, then applies ascending sort (if previous
     * was descending), otherwise turns off sorting.
     *
     * @param {RowIndex} dataIndex
     */
    const handleApplySort = (dataIndex: RowIndex) => {
        if (!sortConfig || sortConfig.dataIndex !== dataIndex) {
            setSortConfig({ dataIndex, descending: false });
        } else if (!sortConfig.descending) {
            setSortConfig({ dataIndex, descending: true });
        } else {
            setSortConfig(undefined);
        }
    };

    let sortedData: T[] | undefined = data;
    // Implement more performant column lookup if needed
    const column = sortConfig
        ? (columns.find(
              (c) => isColumnData(c) && c.sortable && c.dataIndex === sortConfig.dataIndex
          ) as ColumnDataSortable<T>)
        : undefined;
    if (sortedData && sortConfig && column) {
        const dataType = column.dataType;

        let compareFunction: CompareFn | undefined;
        if (dataType === "string") {
            compareFunction = compareString;
        } else if (dataType === "number") {
            compareFunction = compareNumber;
        } else if (dataType === "date") {
            compareFunction = compareDate;
        } else if (dataType === "boolean") {
            compareFunction = compareBoolean;
        }

        const isEmptyCell = (value: any) => value === undefined || value === null || value === "";
        const emptyRows = sortedData.filter((row) => isEmptyCell(row[column.dataIndex]));
        let rowsWithValues = sortedData.filter((row) => !isEmptyCell(row[column.dataIndex]));

        const transform =
            column.valueTransform ??
            function identity(value: any) {
                return value;
            };

        rowsWithValues = rowsWithValues.sort((a, b) => {
            const aValue = transform(a[column.dataIndex]);
            const bValue = transform(b[column.dataIndex]);

            const res = compareFunction?.(aValue, bValue) ?? 0;
            return sortConfig.descending ? res * -1 : res;
        });

        // Push rows with empty cells to the end
        sortedData = [...rowsWithValues, ...emptyRows];
    }

    return { sortConfig, handleApplySort, sortedData };
};
