import { DashboardTable } from "components/common/DashboardTable/DashboardTable";
import { TableChip } from "components/common/DashboardTable/TableChip";
import { Column, SortData } from "components/common/DashboardTable/types";
import React from "react";
import { useDashboardDeviceTypes } from "store/hooks/useDashboardDeviceTypes";
import { useDashboardDevices } from "store/hooks/useDashboardDevices";
import { useDashboardTimeRange } from "store/hooks/useDashboardTimeRange";
import { DispensesTotalByTypePayload, useGetDispensesByTypeQuery } from "store/services/dispense-service";
import { theme } from "styles/theme";
import { toFileNameWithDates } from "utils/download";
import { splitAndCapitalize } from "utils/string";

const graphColorMap: Record<string, { 500: string; 900: string }> = {
    Coffee: theme.graphPalette[0],
    Milk: theme.graphPalette[1],
    Water: theme.graphPalette[2],
};

type Row = DispensesTotalByTypePayload;
const columns: Column<Row, keyof Row>[] = [
    {
        dataIndex: "drinkType",
        dataType: "string",
        label: "Type",
        width: 110,
        valueTransform: (value) => splitAndCapitalize(value),
        CellWrapper: ({ value }) => <TableChip customColor={graphColorMap[value]}>{value}</TableChip>,
    } as Column<Row, "drinkType">,
    {
        dataIndex: "drinkId",
        dataType: "string",
        label: "Id",
        fontStyle: "mono",
        width: 70,
    },
    {
        dataIndex: "drinkName",
        dataType: "string",
        label: "Name",
    },
    {
        dataIndex: "count",
        dataType: "number",
        label: "Count",
        width: 100,
    },
];

const defaultSortData: SortData<Row> = { dataIndex: "drinkId", descending: false };
const DrinkType: React.FC = () => {
    const timeRange = useDashboardTimeRange();
    const devices = useDashboardDevices();
    const deviceTypes = useDashboardDeviceTypes();
    const { data, isLoading, isFetching } = useGetDispensesByTypeQuery({ ...timeRange, devices, deviceTypes });

    const exportFileName = toFileNameWithDates("drinks", timeRange);

    return (
        <DashboardTable
            title={"Total drinks"}
            exportFileName={exportFileName}
            columns={columns}
            data={data}
            isLoading={isLoading || isFetching}
            rowSpan={3}
            colSpan={6}
            initialSortData={defaultSortData}
        />
    );
};

export { DrinkType };
