import { Role } from "types/authTypes";

export type Operation = "VIEW" | "EDIT";

const getAccessPermissions = (role: Role) => {
    switch (role) {
        case Role.Superadmin:
            return {
                [Role.Customer]: ["EDIT"],
                [Role.KeyAccount]: ["EDIT"],
                [Role.Operator]: ["EDIT"],
                [Role.Superadmin]: ["EDIT"],
            };
        case Role.Operator:
            return {};
        case Role.KeyAccount:
            return {
                [Role.Customer]: ["EDIT"],
            };
        case Role.Customer:
            return {
                [Role.Customer]: ["VIEW"],
            };
    }
};

export const resolveUserAccess = (userRole: Role, accessRole: Role, operation: Operation) => {
    const permissions = getAccessPermissions(userRole);
    if (accessRole in permissions) {
        if (permissions[accessRole]?.includes(operation)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};
