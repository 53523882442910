import { LogoutIcon } from "components/icons/Icons";
import React from "react";
import { useDispatch } from "react-redux";
import { clearAuth } from "store";
import styled from "styled-components";
import { MEDIA_QUERY } from "styles/styleUtils";
import { theme } from "styles/theme";
import { useAADAuthentication } from "utils/hooks/useAADAuthentication";

const Button = styled.button<Props>`
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem;
    gap: 0.75rem;

    border: 2px solid ${theme.color.gray[500]};
    border-radius: 0.5rem;
    color: ${({ variant }) => (variant === "light" ? theme.color.white : theme.color.cupcinoBlack)};

    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.025rem;
    line-height: 1;

    transition: background-color 0.15s ease-out;

    :hover {
        border-color: ${({ variant }) => (variant === "light" ? theme.color.white : theme.color.cupcinoBlack)};
    }
`;

const TextWrapper = styled.div`
    ${MEDIA_QUERY.tabletAndSmaller} {
        display: none;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;

    margin-right: -0.25rem;

    ${MEDIA_QUERY.tabletAndSmaller} {
        margin-right: 0;
    }
`;

type Props = {
    variant?: "light" | "dark";
};

export const LogoutButton: React.VFC<Props> = ({ variant = "light" }) => {
    const dispatch = useDispatch();
    const { triggerLogout } = useAADAuthentication();

    const handleLogout = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        dispatch(clearAuth());
        triggerLogout();
    };

    return (
        <Button variant={variant} onClick={handleLogout}>
            <TextWrapper>Log out</TextWrapper>
            <IconWrapper>
                <LogoutIcon size={"16px"} />
            </IconWrapper>
        </Button>
    );
};
