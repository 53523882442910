import { Caret } from "components/common/Caret";
import { WarningIcon } from "components/icons/Icons";
import React from "react";

const ErrorWarning: React.FC = () => {
    return (
        <Caret title={"Error / Warning"} variant={"warning"} Icon={WarningIcon} colSpan={3}>
            <Caret.Value>0</Caret.Value>
            <Caret.ValueSeparator>/</Caret.ValueSeparator>
            <Caret.Value>0</Caret.Value>
        </Caret>
    );
};

export { ErrorWarning };
