import { DataTableDownload } from "components/common/table/DataTable/DataTableDownload";
import { Search } from "components/common/table/DataTable/Search";
import styled from "styled-components";
import { UseInputValueReturn } from "utils/hooks/useInput";

const DataTableToolbarStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 1rem;
`;

interface Props {
    searchInput: UseInputValueReturn;
    onDownloadClick?: () => void;
    fallbackTextSearch?: string;
}

const DataTableToolbar = (props: Props) => {
    return (
        <DataTableToolbarStyled>
            <Search {...props.searchInput} placeholder={props.fallbackTextSearch} />
            {props.onDownloadClick ? <DataTableDownload onClick={props.onDownloadClick} /> : null}
        </DataTableToolbarStyled>
    );
};

export { DataTableToolbar };
